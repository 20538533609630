import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import {
  cardGallery1,
  cardGallery2,
  cardGallery3,
  cardGallery4,
  couponGallery1,
  couponGallery2,
  couponGallery3,
  memb_ph,
  hero_img
} from "./../../assets/images";

import { Col, Container, Row } from "../../components/Grid";
import ProductCard from "../../components/ProductCard/ProductCard";

import {
  getProductsListAction,
  getMembershipCodesAction
} from "../../actions/products.actions";
import { addItemsToCartAction } from "../../actions/cart.actions";
import { goTo } from "../../actions/app.actions";
import {
  getAge,
  isMobileDevice,
  scrollToTop,
  scrollToId,
  refreshPage
} from "../../libs/utils";

import "./productsList.styles.scss";
import Loading from "../../components/Loading/Loading";
import Localize, { getCurrentLocale } from "../../libs/localization";
import MetaTags from "../../components/MetaTags/MetaTags";
import SimpleCard from "../../components/SimpleCard/SimpleCard";

import TableInfo from "../../components/TableInfo/TableInfo";
import {
  PageView,
  ProductImpressions,
  ProductClick,
  ProductAdd
} from "../../libs/helpers/gtm";

import Carousel from "../../components/Carousel/Carousel";
import Config from "../../Config";

class ProductsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentCarouselValue: 0,
      productList: []
    };
  }

  componentDidMount() {
    const {
      getProductsListAction,
      getMembershipCodesAction,
      goTo
    } = this.props;
    this.forceUpdate();
    this.setState(
      {
        loading: true,
        photos: [
          {
            label: Localize("HOME_GALLERY.1.TEXT"),
            image: cardGallery1
          },
          {
            label: Localize("HOME_GALLERY.2.TEXT"),
            image: cardGallery2
          },
          {
            label: Localize("HOME_GALLERY.3.TEXT"),
            image: cardGallery3
          },
          {
            label: Localize("HOME_GALLERY.4.TEXT"),
            image: cardGallery4
          }
        ],
        coupon_photos: [
          {
            label: Localize("COUPON.CAROUSEL.1"),
            image: couponGallery1
          },
          {
            label: Localize("COUPON.CAROUSEL.2"),
            image: couponGallery2
          },
          {
            label: Localize("COUPON.CAROUSEL.3"),
            image: couponGallery3,
            button: Localize("COUPON.CAROUSEL.3_BUTTON"),
            onClick: () => goTo("/coupon")
          }
        ]
      },
      () => {
        PageView();
        getProductsListAction({ limit: 100, page: 1 });
        getMembershipCodesAction();
        // scrollToTop();
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { codes, products, location } = this.props;
    const { loading } = this.state;
    const { search } = location;

    const URLparams = new URLSearchParams(search);
    const show = URLparams.get("show");

    if (!_.isEmpty(products) && !_.isEmpty(codes) && loading) {
      this.setState({ loading: false });
    }
    if (!_.isEqual(products, prevProps.products)) {
      this.setState(this._buildProductRows(products), () =>
        show ? scrollToId(show) : scrollToTop()
      );
    }
  }

  _buildProductRows = products => {
    const membList = _.filter(products || [], p => p.membershipCode);

    const adult = _.orderBy(
      _.filter(membList, p => !p.maxHolderAge || p.maxHolderAge > 16),
      ["membershipCode.priority", "price", "membershipCode.id"],
      ["asc", "desc", "asc"]
    );

    const young = _.orderBy(
      _.filter(membList, p => p.maxHolderAge && p.maxHolderAge <= 16),
      ["membershipCode.priority", "price", "membershipCode.id"],
      ["asc", "desc", "asc"]
    );

    const productList = _.orderBy(
      (membList || []).map(m => {
        const countryRestricted = _.get(
          m,
          "rules.countryRestrictions.not_available[0]",
          ""
        );
        return {
          ...m,
          membershipCodePriority:
            countryRestricted.toString().toLowerCase() === getCurrentLocale()
              ? 1
              : 0
        };
      }),
      [
        "membershipCode.priority",
        "membershipCodePriority"
        // "membershipCode.id",
        // "id",
      ],
      [
        "asc",
        "asc"
        // "asc",
        // "asc",
      ]
    );

    if (_.size(products) > 0) ProductImpressions([...adult, ...young]);
    return { adult, young, productList };
  };

  _onClickBuy = code => {
    const { goTo, products = [] } = this.props;
    const mainProducts = _.filter(products, p => !p.specialOf);
    let id =
      _.find(mainProducts, p => _.get(p, "membershipCode.code") === code) ||
      _.find(products, p => _.get(p, "membershipCode.code") === code);
    id = _.get(id, "id");
    if (id) {
      goTo(`/products/${id}/configure/buy`);
    } else if (code) {
      this._onClickSeeMore(code);
    }
  };

  _onClickGift = code => {
    const { goTo, products = [] } = this.props;
    const mainProducts = _.filter(products, p => !p.specialOf);
    let id =
      _.find(mainProducts, p => _.get(p, "membershipCode.code") === code) ||
      _.find(products, p => _.get(p, "membershipCode.code") === code);
    id = _.get(id, "id");
    if (id) {
      goTo(`/products/${id}/configure/gift`);
    } else if (code) {
      this._onClickSeeMore(code);
    }
  };

  _onClickSeeMore = id => {
    scrollToId(id);
  };

  _onClickDetail = code => {
    const { goTo, products = [] } = this.props;
    const codes = code.split(",");
    let product = _.find(
      products,
      p => {
        const sku = _.toLower(_.get(p, "sku", ""));
        let found = false;
        for (const c in codes) {
          const code = codes[c];
          if (code.indexOf("!") === 0) {
            const notCode = code.replace(/!/g, "");
            if (sku.indexOf(_.toLower(notCode)) >= 0) {
              found = false;
            }
          } else {
            if (sku.indexOf(_.toLower(code)) >= 0) {
              found = true;
            } else {
              found = false;
            }
          }
        }
        return found;
      },
      {}
    );
    const id = _.get(product, "id");
    if (id) {
      goTo(`/products/${id}`);
    } else if (code) {
      refreshPage();
    }
  };

  _getDiscountPrice = el => {
    const now = new Date();
    if (el.promos && el.promos.length > 0) {
      const found = _.find(el.promos, function(promo) {
        return now >= new Date(promo.from) && now < new Date(promo.to);
      });
      return found ? found.price : null;
    } else {
      return null;
    }
  };

  _drawProductSection = (products = [], type, user, goTo) =>
    !_.isEmpty(products) && (
      <Row
        className={`product-rows ${type}`}
        // style={{ minHeight: "100vh" }}
      >
        <Col
          size={{ xl: 20, lg: 22, md: 22, sm: 22, xs: 22 }}
          offset={{ xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
          style={{ textAlign: "center" }}
          className={"px-md-0"}
        >
          <Row className="product-row">
            {products &&
              _.map(products, (el, i) => (
                <Fragment key={i}>
                  <Col
                    key={i}
                    // size={{ xl: 8, lg: 8, md: 12, sm: 12, xs: 24 }}
                    size={24}
                    style={{ textAlign: "center" }}
                    className={
                      "product-card animation px-sm-0 my-5 px-sm-3 px-xs-0"
                    }
                  >
                    <ProductCard
                      className={`animation fadein-up ${type}`}
                      id={_.get(el, "membershipCode.code", Math.random() * 50)}
                      key={i}
                      itemCard={el}
                      soldOut={
                        el.availability === "not_available" || el.soldOut
                      }
                      priceDiscount={_.get(el, "promoPrice")}
                      // priceDiscount={this._getDiscountPrice(el)}
                      userAge={getAge(user && user.dob)}
                      onClickBuy={() => {
                        ProductAdd(el, false);
                        // goTo(`/products/${el.id}/configure/buy`);
                      }}
                      buyLink={`/products/${el.id}/configure/buy`}
                      onClickGift={() => {
                        ProductAdd(el, true);
                        // goTo(`/products/${el.id}/configure/gift`);
                      }}
                      giftLink={`/products/${el.id}/configure/gift`}
                      couponLink={`/products/${el.id}/configure/coupon`}
                      onClickDetail={() => {
                        ProductClick(el);
                        // goTo(`/products/${el.id}`);
                      }}
                      detailLink={`/products/${el.id}`}
                    />
                  </Col>
                </Fragment>
              ))}
          </Row>
        </Col>
      </Row>
    );

  render() {
    const { goTo, user } = this.props;
    const {
      loading,
      currentCarouselValue,
      photos = [],
      coupon_photos = [],
      productList
    } = this.state;

    const {
      premium: premiumSection,
      default: defaultSection,
      junior: juniorSection
    } = _.groupBy(productList, "membershipCode.section") || {};

    const isOnMobile = isMobileDevice();

    return (
      <Container
        className={[
          "mem-prod-page",
          // moment().isBetween("2021-11-16", "2021-11-22", undefined, "[]") ||
          moment().isBetween(
            Config.christmas_from,
            Config.christmas_to,
            undefined,
            "[]"
          )
            ? "christmas"
            : "",
          moment().isBetween("2022-11-21", "2022-11-29", undefined, "[]")
            ? "black-friday"
            : ""
        ].join(" ")}
      >
        <MetaTags
          title={`Juventus Membership - ${Localize("PRODUCTS.TITLE_SAFE")}`}
          desc={Localize("PRODUCTS.DESC_SAFE")}
          img={memb_ph}
          additionalRobotsContent="noarchive,nocache"
        />
        {loading && <Loading coverBack={true} />}

        <div className={["hero", "animation"].join(" ")}>
          <div className="animation fadein-blur" id="premium" name="premium">
            {/* <h1>{Localize("PRODUCTS.TITLE", null, true)}</h1> */}
            {moment().isBetween(
              Config.christmas_from,
              Config.christmas_to,
              undefined,
              "[]"
            ) ? (
              <hero_img.christmas.claim
                className="hero-claim"
                alt={Localize("PRODUCTS.TITLE_SAFE") || "Focus on your passion"}
              />
            ) : (
              <hero_img.claim
                className="hero-claim"
                alt={Localize("PRODUCTS.TITLE_SAFE") || "Focus on your passion"}
              />
            )}
          </div>

          {_.isEmpty(productList) && (
            <div className="hero animation my-5">
              <div className="animation fadein-blur long-delay">
                <h3 className={"is-alert"}>
                  <strong>{Localize("PRODUCTS.EMPTY_PRODUCTS")}</strong>
                </h3>
              </div>
            </div>
          )}

          {Localize("PRODUCTS.INFO") &&
            moment().isBetween("2020-12-01", "2020-12-29", undefined, "[]") && (
              <div className="animation fadein-blur long-delay mt-3">
                <h3 className={"is-alert"}>
                  {Localize("PRODUCTS.INFO", null, true)}
                </h3>
              </div>
            )}

          {_.size(premiumSection) > 0 && (
            <div className="animation fadein-blur long-delay mb-4">
              <h2>{Localize("PRODUCTS.PREMIUM_HEAD")}</h2>
            </div>
          )}
        </div>

        {this._drawProductSection(premiumSection, "premium", user, goTo)}

        {_.size(defaultSection) > 0 && (
          <div className="hero animation" id="default" name="default">
            <div className="animation fadein-blur long-delay">
              <h2>{Localize("PRODUCTS.DEFAULT_HEAD")}</h2>
            </div>
          </div>
        )}

        {this._drawProductSection(defaultSection, "default", user, goTo)}

        {_.size(juniorSection) > 0 && (
          <div className="hero animation" id="junior" name="junior">
            <div className="animation fadein-blur long-delay">
              <h2>{Localize("PRODUCTS.JUNIOR_HEAD")}</h2>
            </div>
          </div>
        )}

        {this._drawProductSection(juniorSection, "junior", user, goTo)}

        <Row className="gallery-row">
          <Col size={{ xs: 24, sm: 24, md: 6 }} className={"py-md-5"}>
            <div className={"label-text-pl"}>
              {Localize("PRODUCTS.LABEL_CAROUSEL")}
            </div>
            <div className={"title-text-pl pt-xs-3 pt-sm-3"}>
              {Localize("PRODUCTS.TITLE_CAROUSEL")}
            </div>
          </Col>
          <Col
            size={{ xs: 24, sm: 24, md: 18 }}
            className={"py-md-5 px-md-0 wrap-carousel pr-sm-0 pr-xs-0"}
          >
            <Carousel
              slidesPerView={
                isOnMobile ? 1.2 : window.screen.width < 1024 ? 1.8 : 2.2
              }
            >
              {photos.map((el, i) => (
                <SimpleCard
                  key={i}
                  fullImage={true}
                  textDark={false}
                  image={el.image}
                  title={el.label}
                />
              ))}
            </Carousel>
          </Col>
        </Row>

        <Row className="coupon-row">
          <Col size={{ xs: 24, sm: 24, md: 6 }} className={"py-md-5"}>
            <div className={"label-text-pl"}>
              {Localize("COUPON.CAROUSEL.LABEL")}
            </div>
            <div className={"title-text-pl pt-xs-3 pt-sm-3"}>
              {Localize("COUPON.CAROUSEL.TITLE")}
            </div>
          </Col>
          <Col
            size={{ xs: 24, sm: 24, md: 18 }}
            className={"py-md-5 px-md-0 wrap-carousel pr-sm-0 pr-xs-0"}
          >
            <Carousel
              slidesPerView={
                isOnMobile ? 1.2 : window.screen.width < 1024 ? 1.8 : 2.2
              }
              arrowFill="white"
            >
              {coupon_photos.map((el, i) => (
                <SimpleCard
                  key={i}
                  fullImage={true}
                  textDark={false}
                  image={el.image}
                  title={el.label}
                  button={el.button}
                  onClick={el.onClick}
                />
              ))}
            </Carousel>
          </Col>
        </Row>

        {!_.isEmpty(productList) && (
          <Row>
            {/* <Row className={"d-xs-none d-sm-none"}> */}
            <Col size={24} className={"px-0"}>
              <TableInfo
                onClickBuy={this._onClickBuy}
                onClickGift={this._onClickGift}
                onClickSeeMore={this._onClickSeeMore}
                onClickDetail={this._onClickDetail}
                isMobile={window.screen.width < 1024}
                mobileRatio={window.screen.width < 620 ? 1.2 : 1.6}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }

  addItemsToCartActionCallback = item => {
    const { addItemsToCartAction } = this.props;
    console.warn("item add: ", item);
    addItemsToCartAction(item);
  };
}

const mapStateToProps = ({ products, auth }) => ({
  products: products && products.data,
  codes: products && products.codes,
  user: auth.user
});

const mapDispatchToProps = {
  getProductsListAction,
  addItemsToCartAction,
  getMembershipCodesAction,
  goTo
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsListPage);
