//Sidebar menu icons
import { ReactComponent as membership } from "./membership.svg";
import { ReactComponent as users } from "./users.svg";
import { ReactComponent as jCard } from "./j-card.svg";
import { ReactComponent as cart } from "./cart.svg";
import { ReactComponent as cartFab } from "./cart_fab.svg";
import { ReactComponent as plane } from "./plane.svg";
import { ReactComponent as box } from "./box.svg";
import { ReactComponent as voucher } from "./voucher.svg";
import { ReactComponent as coupon } from "./coupon.svg";
import { ReactComponent as couponFab } from "./coupon_fab.svg";
import { ReactComponent as warehouse } from "./warehouse.svg";
import { ReactComponent as statistic } from "./statistic.svg";
import { ReactComponent as setting } from "./setting.svg";
import { ReactComponent as loading } from "./loading.svg";
import { ReactComponent as circleArrow } from "./circle-arrow.svg";
import { ReactComponent as circleArrowDisabled } from "./circle-arrow-disabled.svg";
import { ReactComponent as deliveryTime } from "./delivery-time.svg";
import { ReactComponent as greenCheck } from "./green-check.svg";
import { ReactComponent as info } from "./info.svg";

export default {
  membership,
  users,
  jCard,
  cart,
  cartFab,
  plane,
  box,
  voucher,
  coupon,
  couponFab,
  warehouse,
  statistic,
  setting,
  loading,
  circleArrow,
  circleArrowDisabled,
  deliveryTime,
  greenCheck,
  info
};
