import React from "react";
import PropTypes from "prop-types";

import "./RadioButton.scss";
import Localize from "../../libs/localization";
// import {  } from "../../assets/images";

// const _getLabelForType = (el, type) => {
//   // get the correct label if is not temp_address ( Preferisco usare un indirizzo temporaneo )
//   if (type === "ADDRESS" && el.key !== "temp_address") {
//     return el.address1 + ", " + el.country + ", (" + el.province + ")";
//   }

//   return el.label;
// };

const _getValueForType = (el, type) => {
  // get the correct value if is not temp_address ( Preferisco usare un indirizzo temporaneo )
  if (type === "ADDRESS" && el.key !== "temp_address") {
    return el.id;
  }
  return el.value;
};

const RadioButton = ({
  options = [],
  emptyMsg = Localize("COMMON.NO_DATA"),
  onChange,
  type = "",
  selected,
  name = "radiogroup",
  disabled = false
}) => {
  if (options.length === 0)
    return (
      <div className={"j-radio"}>
        <div className={"empty-message mb-4"}>{emptyMsg}</div>
      </div>
    );
  return (
    <div className={"j-radio"}>
      {options.map(el => (
        <div
          key={el.id}
          className={
            // ((disabled || el.disabled) && "disabled md-radio") || "md-radio"
            `md-radio${disabled || el.disabled ? " disabled" : ""}${
              el.id === selected || el.id === parseInt(selected)
                ? " selected"
                : ""
            }`
          }
        >
          <input
            id={el.id}
            // defaultChecked={el.id === selected || el.id === parseInt(selected)}
            checked={el.id === selected || el.id === parseInt(selected)}
            type="radio"
            name={name}
            disabled={disabled || el.disabled}
            value={_getValueForType(el, type)}
          />
          {type.toUpperCase() !== "CARD" && (
            <label
              htmlFor={el.key}
              onClick={() =>
                (!disabled &&
                  !el.disabled &&
                  typeof onChange === "function" &&
                  onChange(el.id, el)) ||
                null
              }
            >
              {el.label}
            </label>
            // <label htmlFor={el.key}>{_getLabelForType(el, type)}</label>
          )}
          {el.subLabel && <div className={"sub-label mt-3"}>{el.subLabel}</div>}
          {type.toUpperCase() === "CARD" && el.component && (
            <label
              className="card"
              htmlFor={el.key}
              onClick={() =>
                (!disabled &&
                  !el.disabled &&
                  typeof onChange === "function" &&
                  onChange(el.id, el)) ||
                null
              }
            >
              {el.component}
            </label>
          )}
          {typeof el.onDelete === "function" && (
            <span
              className="delete-btn"
              style={{ cursor: "pointer", color: "var(--btn-dark-text)" }}
              onClick={el.onDelete}
            >
              {Localize("COMMON.DELETE")}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  selected: PropTypes.string,
  type: PropTypes.oneOf(["ADDRESS", "CARD", "OTHER"])
};

export default RadioButton;
