import Config from "../Config";
import ErrorMessage from "../libs/helpers/Error";
import { PurchaseCompleted } from "../libs/helpers/gtm";
import { wait } from "../libs/utils";

/**
 * call checkout wrap ADYEN
 * @param params
 * @returns {Promise<*>}
 */
export const checkoutApi = async ({ data, headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.checkout.pay}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(data)
  });

  const response = await res.json();

  // return { response, ok: res.ok };

  if (res.ok) {
    const {
      redirect,
      resultCode,
      refusalReason,
      refusalReasonCode,
      merchantReference,
      orderId
    } = response;
    /*if (redirect && redirect.url) {
      PageView(`${redirect.url}`);
      // await wait(500);
      // window.location = `${redirect.url}`;
    } else*/
    if (!redirect && resultCode === "Authorised") {
      // PurchaseCompleted(order);
      await wait(500);
      window.location = `/thankyou?orderId=${orderId}`;
    } else if (!redirect && resultCode === "Refused") {
      throw new ErrorMessage(
        resultCode,
        refusalReason,
        refusalReasonCode,
        merchantReference,
        null
      );
    } else {
      return response;
    }
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const paymentApi = async ({ headers, query }) => {
  // var queryString = Object.keys(query)
  //   .map((key) => key + "=" + query[key])
  //   .join("&");
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.checkout.checkoutCallback}${query}`,
    {
      method: "GET",
      headers
    }
  );

  const response = await res.json();

  if (res.ok && response && response.order) {
    PurchaseCompleted(response.order);
  }

  return { response, ok: res.ok };

  // if (res.ok) {
  // } else {
  //   const { statusText, status } = res;
  //   const { id, message, label, code } = response;
  //   throw new ErrorMessage(statusText, label || message, status, code, id);
  // }
};
