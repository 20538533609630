import React, { useEffect, useState } from "react";

import Localize from "../../libs/localization";

import { ReactComponent as JLogo } from "../../assets/icons/j_logo.svg";

import "./system.style.scss";
import Loading from "../../components/Loading/Loading";
import { Col, Row } from "../../components/Grid";
import Button from "../../components/Button/Button";

const AuthPage = () => {
  const [hasProblems, setHasProblems] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setHasProblems(true), 10000);
    return () => {
      clearInterval(timer);
    };
  });
  return (
    <div className="system-page">
      <div className="system-box">
        <div className="logo">
          <JLogo />
        </div>
        <Loading fixed={false} />
        {hasProblems && (
          <Row className="animation fadein">
            <Col>
              {Localize("ERROR.SEEM_PROBLEM")}
              <br />
              <Button
                size="S"
                label={Localize("COMMON.CLICK_HERE")}
                style={{ marginTop: 10 }}
                onClick={() => (window.location.href = "/")}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
