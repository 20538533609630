import moment from "moment";
import { useState, useEffect } from "react";
import { side } from "../assets/images";
import _ from "lodash";
import Config from "../Config";

export const getAddress = () =>
  `${global.location.protocol}//${global.location.host}/`;

// export const capitalizeFirstLetter = (string) =>
//   string.charAt(0).toUpperCase() + string.slice(1);

export const wait = durationMs =>
  new Promise(fulfill => setTimeout(fulfill, durationMs));

export const refreshPage = () => {
  window.location.reload();
};

export const currencyMaxOneDecimal = value => {
  return value % 1 === 0
    ? Number.parseFloat(value)
        .toFixed(0)
        .toString()
        .concat("€")
    : Number.parseFloat(value)
        .toFixed(1)
        .toString()
        .concat("€");
};

export const currencyDecimal = value => {
  return Number.parseFloat(value)
    .toFixed(2)
    .toString()
    .concat("€");
};

export const currencyNoDecimal = (value, currencyConcat = true) => {
  if (currencyConcat) {
    return Number.parseFloat(value)
      .toFixed(0)
      .toString()
      .concat("€");
  } else {
    return "€".concat(
      Number.parseFloat(value)
        .toFixed(0)
        .toString()
    );
  }
};

export const isMobileDevice = () => window.screen.width < 767;

export const isTouchScreen = () =>
  !!("ontouchstart" in window || navigator.maxTouchPoints);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

export const scrollToId = id => {
  const element = document.getElementById(id);
  // if (element) element.scrollIntoView();
  const header = document.getElementById("d3-header");
  let offsetHeader = 80;
  if (
    header &&
    header.firstElementChild &&
    header.firstElementChild.clientHeight > offsetHeader
  ) {
    offsetHeader = header.firstElementChild.clientHeight;
  }
  if (element) {
    const top = element.offsetTop - offsetHeader;
    window.scrollTo({ top });
  }
};

export const getAge = birthdate => moment().diff(moment(birthdate), "years");

export const toBase64 = str => {
  return Buffer.from(str.toString(), "utf8").toString("base64");
};

export const fromBase64 = str => {
  return Buffer.from(str.toString(), "base64").toString("ascii");
};

export const toHex = str => {
  return Buffer.from(str.toString(), "utf8").toString("hex");
};

export const fromHex = str => {
  return Buffer.from(str.toString(), "hex").toString("ascii");
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const getSectionProductName = product => {
  return product.name
    .toLowerCase()
    .replace("membership", "")
    .trim()
    .replace(" ", "_")
    .toUpperCase();
};

export const getImageBackground = product => {
  const membCode = _.get(product, "membershipCode.code", null);
  const specialOf = _.get(product, "specialOf.sku", null);
  const codeName = _.get(product, "tags[0].code", null);
  const sku = product?.sku;

  let sideSet = side;
  if (
    // moment().isBetween("2021-11-16", "2021-11-22", undefined, "[]") ||
    moment().isBetween(
      Config.christmas_from,
      Config.christmas_to,
      undefined,
      "[]"
    )
  ) {
    sideSet = side.christmas;
  } else if (moment().isBetween("2022-11-21", "2022-11-29", undefined, "[]")) {
    sideSet = side.blackFriday;
  }

  if (codeName === "jcard") {
    return _.get(sideSet, "jcard") || side.alt;
  }
  let special = null;

  if ((sku || "").split("_").length > 3) {
    special = (sku || "").split("_")[1];
  }

  if (!special && specialOf) {
    special = (sku || "").split("_")[0];
  }

  if (special) {
    const sideImage = _.get(sideSet, `${membCode}_${special}`);
    if (sideImage) return sideImage;
  }

  return _.get(sideSet, membCode) || side.alt;
};
