import React, { Component } from "react";
import { connect } from "react-redux";

import "./cart.styles.scss";
import { Col, Container, Row } from "../../components/Grid";
import Cart from "../../components/Cart/Cart";
import _ from "lodash";

// import { ReactComponent as DeliveryTime } from "./../../assets/icons/delivery-time.svg";

import {
  createBillingAddressAction,
  deleteItemToCartAction,
  getCartContentAction,
  updateItemToCartAction
} from "../../actions/cart.actions";
import {
  getCountriesAction,
  getProvincesAction
} from "../../actions/utils.actions";
import { goTo } from "../../actions/app.actions";
import Config from "../../Config";
import Localize, { getCurrentLocale } from "../../libs/localization";
import Loading from "../../components/Loading/Loading";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { scrollToTop } from "../../libs/utils";
import { PageView } from "../../libs/helpers/gtm";

import restrictedCountries from "./restrictedCountries.json";

class CartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      preferredOption: null,
      loading: true,
      addressOptions: []
    };
  }

  componentDidMount() {
    const { getCartContentAction, user } = this.props;
    const restrictedCountriesArray = restrictedCountries.map(
      rc => rc.countryCode
    );

    scrollToTop();

    if (!user.dob || !user.taxCode) {
      const redirectUrl = `${Config.authBaseurl}/${Config.apis.oauth.addUserInfo}?client_id=${Config.oauthClientId}&fields=taxcode&redirect_uri=${window.location.href}`;
      PageView(redirectUrl);
      window.location = redirectUrl;
    } else {
      PageView();
      const addressOptions = _.filter(user.shippingAddresses, address => {
        address.id = address.addressId;
        address.restricted =
          !address.countryCode ||
          restrictedCountriesArray.includes(address.countryCode);
        return true;
      });

      this.setState({
        addressOptions,
        preferredOption: _.find(addressOptions, ["isPreferred", true])
          ? _.find(addressOptions, ["isPreferred", true]).id
          : null
      });

      getCartContentAction();
    }
  }

  componentDidUpdate(prevProps) {
    const restrictedCountriesArray = restrictedCountries.map(
      rc => rc.countryCode
    );
    const { cart, user, match, deleteItemToCartAction } = this.props;
    const { params } = match;
    const { cart_item } = params;
    const { search } = this.props.location;

    const URLparams = new URLSearchParams(search);

    const address_id = URLparams.get("address_id");

    if (
      address_id &&
      user &&
      cart_item &&
      cart &&
      (!prevProps.cart || !_.isEqual(user, prevProps.user))
    ) {
      const addressOptions = _.filter(user.shippingAddresses, address => {
        address.id = address.addressId;
        address.restricted =
          !address.countryCode ||
          restrictedCountriesArray.includes(address.countryCode);
        return address;
      });
      /*eslint eqeqeq:0*/
      const selAdd = _.find(
        addressOptions,
        a =>
          a.id == address_id &&
          !restrictedCountriesArray.includes(a.countryCode)
      );
      this._onAddressChange(selAdd, cart_item);
      this.setState({ addressOptions });

      scrollToTop();
    }

    if (cart && !_.isEqual(cart, prevProps.cart)) {
      const jcards = _.filter(cart.items, i =>
        _.find(_.get(i, "product.tags"), t => t.code === "jcard")
      );
      const incompleteJCards = _.filter(
        jcards,
        j =>
          _.size(
            _.get(
              j,
              `configuration.products[${_.get(j, "product.id")}].documents`
            )
          ) <= 0
      );
      incompleteJCards.map(
        async j => await deleteItemToCartAction({ id: j.id })
      );
    }
  }

  _setSelectedOptions = ({ cart_item, address_id }) => {
    this.setState(state => {
      if (state.selected.length === 0) {
        state.selected.push({
          cart_item,
          address_id
        });
      } else {
        const selected = state.selected.map(item => {
          if (item.cart_item === cart_item) {
            item.address_id = address_id;
          }
          return item;
        });
        return {
          selected
        };
      }
    });
  };

  _addressEnrichmentCallback = currentItemCart => {
    window.location = `${
      Config.authBaseurl
    }/${getCurrentLocale()}/address-choice?address_type=shipping&client_id=${
      Config.oauthClientId
    }&redirect_uri=${Config.appBaseurl}/cart/${currentItemCart}`;
  };

  _deleteItemToCartActionCallback = id => {
    const { deleteItemToCartAction } = this.props;
    deleteItemToCartAction({ id });
  };

  _modifyItemInCartCallback = ({
    codeName,
    product,
    gift,
    coupon = false,
    cartItem,
    configuration,
    buyFor
  }) => {
    const { goTo } = this.props;
    const { renewalOf, membership } = _.get(
      configuration,
      `products[${product.id}]`
    );
    if (codeName === "membership_upgrade" && membership) {
      goTo(
        `/products/${product.id}/upgrade/${_.get(
          membership,
          `code`
        )}?cartItem=${cartItem}`
      );
    } else if (renewalOf) {
      goTo(`/products/${product.id}/renewal/${renewalOf}?cartItem=${cartItem}`);
    } else {
      goTo(
        `/products/${product.id}/configure/`
          .concat(gift ? "gift" : coupon ? "coupon" : "buy")
          .concat(`?cartItem=${cartItem}&buyFor=${buyFor}`)
      );
    }
  };

  _onAddressChange = (address, itemId) => {
    const { cart, updateItemToCartAction } = this.props;
    let cartItem = _.find(cart.items, { id: itemId });
    let shippingAddress = null;
    if (address) {
      shippingAddress = address;
      shippingAddress.addressId = address.id;
      shippingAddress.phone =
        address.phone && address.phone.replace(/^\+/, "00");
    }
    cartItem = { ...cartItem, shippingAddress };
    updateItemToCartAction({
      data: cartItem,
      id: itemId
    });
  };

  _setWaiverOfWithdraw = (active = false, prodId, itemId) => {
    const { cart, updateItemToCartAction } = this.props;
    let data = _.find(cart.items, { id: itemId });
    data = _.set(
      data,
      `configuration.products[${prodId}].waiverOfWithdraw`,
      active
    );
    updateItemToCartAction({
      data,
      id: itemId
    });
  };

  _hasJCard = items =>
    _.some(items, i => _.some(i.product.tags, t => t.code === "jcard"));

  _hasMembership = items =>
    _.some(items, i => _.some(i.product.tags, t => t.code === "membership"));

  _needsShipment = items =>
    _.find(
      items,
      (el, i) =>
        !el.product.virtual ||
        _.some(el.product.groupedProducts, gp => !gp.virtual)
    );

  render() {
    const { cart, countries, provinces, goTo, user, cartLoading } = this.props;
    const { preferredOption, addressOptions } = this.state;

    const breadcrumbs = [
      { value: Localize("BREADCRUMB.HOME"), onClick: () => goTo("/products") },
      { value: Localize("BREADCRUMB.CART") }
    ];

    return (
      <Container className={"j-cart-page"}>
        {cartLoading && <Loading coverBack={!cart} />}
        <Row>
          <Col size={24} className={"banner-title"}>
            <span className={"banner-title-text"}>{Localize(`CART.CART`)}</span>
          </Col>
          <Col
            size={{ sm: 24, md: 24, xl: 20, lg: 22 }}
            offset={{ lg: 2, xl: 4 }}
            className={"breadcrumb-box translate-top pl-lg-5 pt-4 pb-4"}
          >
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Col>
          <Col size={{ sm: 24, md: 24 }} className="pl-lg-5 translate-top">
            <Cart
              countries={countries}
              provinces={provinces}
              cartInfo={cart}
              user={user}
              addressOptions={addressOptions}
              preferredOption={preferredOption}
              addressChange={this._onAddressChange}
              addressEnrichment={this._addressEnrichmentCallback}
              updateItemToCart={this._updateItemToCartActionCallback}
              deleteItemToCart={this._deleteItemToCartActionCallback}
              modifyItemInCart={this._modifyItemInCartCallback}
              setWaiverOfWithdraw={this._setWaiverOfWithdraw}
              goToCheckout={() => goTo(`/checkout`)}
              continuePurchase={() => goTo(`/products`)}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ cart, auth, utils }) => ({
  cart: cart.data,
  cartLoading: cart.loading,
  user: auth.user,
  countries: utils.countries,
  provinces: utils.provinces
});

const mapDispatchToProps = {
  getCartContentAction,
  createBillingAddressAction,
  updateItemToCartAction,
  deleteItemToCartAction,
  goTo,
  getCountriesAction,
  getProvincesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
