import PropTypes from "prop-types";
import React, { useRef } from "react";
import "./Carousel.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { A11y, Navigation, Keyboard, EffectCreative } from "swiper";
import { ReactComponent as CircleArrow } from "../../assets/icons/circle-arrow.svg";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module

const Carousel = ({
  children,
  slidesPerView,
  className,
  spaceBetween = 0,
  creativeEffect = null,
  navigationHidden = false,
  arrowFill = "black",
  navigationContainerStyle
}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <>
      <Swiper
        className={["j-carousel", className].join(" ")}
        modules={[A11y, Navigation, Keyboard, EffectCreative]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        autoHeight={true}
        slideToClickedSlide={false}
        touchMoveStopPropagation={true}
        preventClicksPropagation={true}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
          disabledClass: "j-carousel-nav-disabled"
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true
        }}
        {...(creativeEffect
          ? {
              effect: "creative",
              creativeEffect: {
                prev: {
                  shadow: true,
                  translate: [0, 0, -400]
                },
                next: {
                  translate: ["100%", 0, 0]
                }
              }
            }
          : {})}
      >
        {Array.isArray(children) ? (
          children.map((el, i) => <SwiperSlide key={i}>{el}</SwiperSlide>)
        ) : (
          <SwiperSlide>{children}</SwiperSlide>
        )}
      </Swiper>
      {!navigationHidden && (
        <div style={navigationContainerStyle}>
          <div
            className={"icons pt-4 pointer arrow-on-left d-xs-none d-sm-none"}
            ref={navigationPrevRef}
          >
            <CircleArrow className={"flip"} fill={arrowFill} />
          </div>
          <div
            className={"icons pt-4 pointer arrow-on-right d-xs-none d-sm-none"}
            ref={navigationNextRef}
          >
            <CircleArrow fill={arrowFill} />
          </div>
        </div>
      )}
    </>
  );
};

// const CarouselOld = ({ children, type = null, elements = [] }) => {
//   const isOnMobile = isMobileDevice();
//   return (
//     <div className={"j-carousel"}>
//       <ReactCarousel centered infinite arrows slidesPerPage={2}>
//         {/* <div
//         id="carouselExampleIndicators"
//         className="carousel slide"
//         data-interval="false"
//         data-ride="carousel"
//       >
//         <ol className="carousel-indicators">
//           {elements.map((el, i) => (
//             <li
//               key={i}
//               data-target="#carouselExampleIndicators"
//               data-slide-to={i}
//               className={i === 0 ? "active" : ""}
//             />
//           ))}
//         </ol>
//         <div className="carousel-inner"> */}
//         {Array.isArray(children) ? children.map((c) => c) : children}
//         {/* </div>
//       </div> */}
//       </ReactCarousel>
//     </div>
//   );
// };

Carousel.propTypes = {
  type: PropTypes.oneOf(["PRODUCT_CARD", "PHOTO", "OTHER"]),
  elements: PropTypes.array
};

export default Carousel;
