import { reducerFromMap } from "../libs/helpers/reducers";
import {
  BUY_PRODUCT_ERROR,
  BUY_PRODUCT_GOTO,
  BUY_PRODUCT_LOADING
} from "../actions/buyProduct.actions";

const initialState = {
  step: "VERIFY",
  configuration: null
};

const reducer = {
  [BUY_PRODUCT_LOADING]: (state, actions) => ({
    ...state,
    loading: true
  }),
  [BUY_PRODUCT_ERROR]: (state, actions) => ({
    ...state,
    loading: false
  }),
  [BUY_PRODUCT_GOTO]: (state, actions) => ({
    ...state,
    step: actions.data
  })
};
export default reducerFromMap(reducer, initialState);
