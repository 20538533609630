import React from "react";

import "./Breadcrumb.scss";

const Breadcrumb = ({ breadcrumbs = [] }) => {
  return (
    <div className={"j-breadcrumb"}>
      {breadcrumbs.map((el, i) => {
        if (i === breadcrumbs.length - 1) {
          return <span key={i}>{el.value}</span>;
        } else {
          return (
            <span key={i}>
              <span
                onClick={e =>
                  (typeof el.onClick === "function" && el.onClick(e)) || null
                }
                className={"underline pointer"}
              >
                {el.value}
              </span>{" "}
              /{" "}
            </span>
          );
        }
      })}
    </div>
  );
};

// Breadcrumb.propTypes = {
//   onClick: PropTypes.func,
//   breadcrumbs: PropTypes.array(
//     PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })
//   )
// };

export default Breadcrumb;
