import { reducerFromMap } from "../libs/helpers/reducers";
import {
  PRODUCT_DETAIL_LOADING,
  PRODUCT_DETAIL_LOADED,
  PRODUCTS_ERROR,
  PRODUCTS_LOADED,
  PRODUCTS_LOADING,
  MEM_CODES_ERROR,
  MEM_CODES_LOADED,
  MEM_CODES_LOADING
} from "../actions/products.actions";

const initialState = {
  data: [],
  cmsLayout: null
};

const reducer = {
  [PRODUCTS_LOADING]: (state, actions) => ({
    ...state,
    loading: true,
    error: false,
    data: []
  }),
  [PRODUCTS_ERROR]: (state, actions) => ({
    ...state,
    loading: false,
    error: true
  }),
  [PRODUCTS_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    data: actions.data
  }),
  [MEM_CODES_LOADING]: (state, actions) => ({
    ...state,
    loading: true,
    codes: []
  }),
  [MEM_CODES_ERROR]: (state, actions) => ({
    ...state,
    loading: false
  }),
  [MEM_CODES_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    codes: actions.data
  }),
  [PRODUCT_DETAIL_LOADING]: (state, actions) => ({
    ...state,
    loading: true,
    productDetail: null
  }),
  [PRODUCT_DETAIL_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    productDetail: actions.data
  })
};
export default reducerFromMap(reducer, initialState);
