import React from "react";
import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg";

import "./CheckMark.scss";
import { isMobileDevice, isTouchScreen } from "../../libs/utils";
import { useState } from "react";

export default ({
  id = "MARK",
  label,
  onChange,
  value = false,
  tooltip,
  required = false,
  disabled = false
}) => {
  const [showTooltip, setShowTooltip] = useState(
    isMobileDevice() || isTouchScreen()
  );
  return (
    <div className={`j-checkbox${disabled ? " disabled" : ""}`}>
      <div className={"md-checkbox"}>
        <input
          id={id}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={e =>
            (typeof onChange === "function" &&
              !disabled &&
              onChange(e.target.checked)) ||
            null
          }
        />
        <label htmlFor={id}>
          <div>
            {required && <span className="required">* </span>}
            {label}
            {tooltip && !isMobileDevice() && (
              <QuestionMark
                onMouseOver={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
            )}
          </div>
        </label>
        {tooltip && showTooltip && (
          <span className="tooltip bottom">{tooltip}</span>
        )}
      </div>
    </div>
  );
};
