import React from "react";
import _ from "lodash";

import "./CartItemDetail.scss";
import { Col, Row } from "../Grid";
import Localize from "../../libs/localization";
// import { isMobileDevice } from "../../libs/utils";
import LabelText from "../LabelText/LabelText";
import moment from "moment";

export default ({ item, itemDetail, user }) => {
  const { gift } = item;
  // const isOnMobile = isMobileDevice();

  const codeName = _.get(item, "product.tags[0].code", null);

  return (
    <Row className={"j-cartItemDetail px-3"}>
      {Object.keys(_.get(item, "configuration.products")).map((k, i) => {
        const p = _.get(item, `configuration.products[${k}]`);
        if (p.membership) {
          return (
            <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
              <LabelText label={Localize(`SUMMARY_CARD.MEM_CODE`)} />
              <div className={"value-label-detail"}>{p.membership.code}</div>
            </Col>
          );
        }
        if (p.automaticRenewal) {
          return (
            <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
              <LabelText label={Localize(`SUMMARY_CARD.AUTORENEWAL`)} />
              <div className={"value-label-detail"}>
                {Localize(`COMMON.${p.automaticRenewal ? "ON" : "OFF"}`)}
              </div>
            </Col>
          );
        }
        if (p.variant) {
          return (
            <>
              {p.variant && (
                <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
                  <LabelText label={Localize(`SUMMARY_CARD.VARIANT`)} />
                  <div className={"value-label-detail"}>
                    {_.get(
                      _.find(
                        _.get(
                          _.find(
                            codeName === "membership_upgrade"
                              ? _.get(item, "product.upgradeTo.groupedProducts")
                              : _.get(item, "product.groupedProducts"),
                            // eslint-disable-next-line eqeqeq
                            p => p.id == k
                          ),
                          "variants"
                        ),
                        { id: p.variant }
                      ),
                      "name"
                    )}
                  </div>
                </Col>
              )}
              {p.customization &&
                Object.keys(p.customization || {}).map(c => (
                  <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
                    <LabelText label={Localize(`SUMMARY_CARD.CUSTOMIZATION`)} />
                    <div className={"value-label-detail"}>
                      {p.customization[c]}
                    </div>
                  </Col>
                ))}
            </>
          );
        }
        return null;
      })}
      {gift ? (
        <>
          <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
            <LabelText label={Localize(`SUMMARY_CARD.GIFT_TEXT`)} />
            <div className={"value-label-detail"}>
              {item.configuration.gift.message || "-"}
            </div>
          </Col>
          <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
            <LabelText label={Localize(`SUMMARY_CARD.EMAIL`)} />
            <div className={"value-label-detail"}>
              {item.configuration.gift.email || "-"}
            </div>
          </Col>
          <Col size={{ sm: 24, md: 12 }} className={"p-3"}>
            <LabelText label={Localize(`SUMMARY_CARD.PHONE`)} />
            <div className={"value-label-detail"}>
              {item.configuration.gift.phone || "-"}
            </div>
          </Col>
        </>
      ) : (
        user && (
          <>
            <Col size={{ xs: 12, sm: 12, md: 12 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.NAME`)} />
              <div className={"value-label-detail d-sm-none"}>
                {user.firstName || "-"}
              </div>
            </Col>

            <Col size={{ xs: 12, sm: 12, md: 12 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.SURNAME`)} />
              <div className={"value-label-detail"}>{user.lastName || "-"}</div>
            </Col>
            <Col size={{ sm: 24, md: 12, xs: 24 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.EMAIL`)} />
              <div className={"value-label-detail"}>{user.email || "-"}</div>
            </Col>
            <Col size={{ sm: 24, md: 12, xs: 24 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.PHONE`)} />
              <div className={"value-label-detail"}>{user.phone || "-"}</div>
            </Col>
            <Col size={{ xs: 12, sm: 12, lg: 12 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.BIRTH_DATE`)} />
              <div className={"value-label-detail"}>
                {user.dob ? moment(user.dob).format("YYYY-MM-DD") : "-"}
              </div>
            </Col>
            <Col size={{ md: 12, sm: 24, xs: 24 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.NATIONALITY`)} />
              <div className={"value-label-detail"}>
                {user.birthCountry || "-"}
              </div>
            </Col>
            <Col size={{ lg: 12, sm: 24, xs: 24 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.BIRTH_PROVINCE`)} />
              <div className={"value-label-detail"}>
                {user.birthProvince || "-"}
              </div>
            </Col>
            <Col size={{ lg: 12, sm: 24, xs: 24 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.BIRTH_PLACE`)} />
              <div className={"value-label-detail"}>
                {user.birthCity || "-"}
              </div>
            </Col>
            {codeName === "jcard" && (
              <Col size={{ lg: 12, sm: 24, xs: 24 }} className={"p-3"}>
                <LabelText label={Localize(`CART.DETAIL.CF`)} />
                <div className={"value-label-detail"}>
                  {user.taxCode || "-"}
                </div>
              </Col>
            )}
            <Col size={{ lg: 12, sm: 24, xs: 24 }} className={"p-3"}>
              <LabelText label={Localize(`CART.DETAIL.GENDER`)} />
              <div className={"value-label-detail"}>{user.gender || "-"}</div>
            </Col>
          </>
        )
      )}
    </Row>
  );
};
