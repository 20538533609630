import _ from "lodash";

const CURRENCY = "EUR";

export const PushDataLayer = data => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const PageView = (page = window.location.href) =>
  PushDataLayer({
    event: "JMembVirtualPageviewEvent",
    VPpageUrl: page
  });

export const PurchaseCompleted = order => {
  const {
    incrementId,
    grandTotal,
    taxAmount,
    shippingCost,
    voucher,
    items
  } = order;
  PushDataLayer({
    event: "JMembPurchaseCompleted",
    ecommerce: {
      currencyCode: CURRENCY,
      purchase: {
        actionField: {
          id: incrementId,
          affiliation: undefined,
          revenue: grandTotal,
          tax: taxAmount,
          shipping: shippingCost,
          coupon: voucher ? voucher.code : undefined
        },
        products: _.map(
          items,
          i =>
            i.product && {
              id: i.product.id,
              name: i.product.name,
              price: i.product.price,
              brand: _.get(i.product, "tags[0].code", undefined),
              variant: i.gift ? "gift" : "buy",
              category: _.get(
                i.product,
                "membershipCode.code",
                _.get(i.product, "tags[0].code", undefined)
              ),
              quantity: 1,
              coupon: _.get(i, "voucher.code", undefined)
            }
        )
      }
    }
  });
};

export const CheckoutSteps = ({ step, product, process }) => {
  PushDataLayer({
    event: "JMembCheckoutSteps",
    ecommerce: {
      currencyCode: CURRENCY,
      checkout: {
        actionField: {
          step
        },
        products: [
          {
            id: product.id,
            name: product.name,
            price: product.price,
            brand: _.get(product, "tags[0].code", undefined),
            variant: process || "buy",
            category: _.get(
              product,
              "membershipCode.code",
              _.get(product, "tags[0].code", undefined)
            ),
            quantity: 1,
            coupon: undefined
          }
        ]
      }
    }
  });
};

export const UserDataLoad = uuid => {
  PushDataLayer({
    event: "UserDataLoad",
    userLogged: uuid ? "Logged" : "NotLogged",
    userID: uuid
  });
};

export const ProductImpressions = products => {
  PushDataLayer({
    event: "JMembProductImpressions",
    ecommerce: {
      currencyCode: CURRENCY,
      impressions: _.map(products, (p, i) => ({
        id: p.id,
        name: p.name,
        price: p.price,
        category: _.get(
          p,
          "membershipCode.code",
          _.get(p, "tags[0].code", undefined)
        ),
        brand: _.get(p, "tags[0].code", undefined),
        position: i + 1,
        list: "membership list"
      }))
    }
  });
};

export const ProductClick = product => {
  PushDataLayer({
    event: "JMembProductClick",
    ecommerce: {
      currencyCode: CURRENCY,
      click: {
        products: [
          {
            id: product.id,
            name: product.name,
            price: product.price,
            brand: _.get(product, "tags[0].code", undefined),
            category: _.get(
              product,
              "membershipCode.code",
              _.get(product, "tags[0].code", undefined)
            )
          }
        ]
      }
    }
  });
};

export const ProductDetail = product => {
  PushDataLayer({
    event: "JMembProductDetail",
    ecommerce: {
      currencyCode: CURRENCY,
      detail: {
        products: [
          {
            id: product.id,
            name: product.name,
            price: product.price,
            brand: _.get(product, "tags[0].code", undefined),
            category: _.get(
              product,
              "membershipCode.code",
              _.get(product, "tags[0].code", undefined)
            )
          }
        ]
      }
    }
  });
};

export const ProductAdd = (product, gift = false) => {
  PushDataLayer({
    event: "JMembProductAdd",
    ecommerce: {
      currencyCode: CURRENCY,
      add: {
        products: [
          {
            id: product.id,
            name: product.name,
            price: product.price,
            brand: _.get(product, "tags[0].code", undefined),
            category: _.get(
              product,
              "membershipCode.code",
              _.get(product, "tags[0].code", undefined)
            ),
            variant: gift ? "gift" : "buy",
            quantity: 1
          }
        ]
      }
    }
  });
};
