import auth from "./auth.reducer";
import app from "./app.reducer";
import { connectRouter } from "connected-react-router";
import history from "../store/history";
import { combineReducers } from "redux";
import cart from "./cart.reducer";
import products from "./products.reducer";
import utils from "./utils.reducer";
import buyProduct from "./buyProduct.reducer";
import checkout from "./checkout.reducer";
import customer from "./customer.reducer";
import mymembership from "./mymembership.reducer";

const rootReducer = combineReducers({
  router: connectRouter(history),
  app,
  auth,
  cart,
  products,
  utils,
  buyProduct,
  checkout,
  customer,
  mymembership
});

export default rootReducer;
