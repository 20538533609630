import AppInfo from "./libs/helpers/appInfo";

const dev = {
  env: "dev",
  label: "Development",
  logger: true,
  forceLocale: "it"
};

const test = {
  env: "test",
  label: "Test",
  logger: true
};

const prod = {
  env: "prod",
  label: "Staging",
  logger: false
};

const apis = {
  oauth: {
    login: "api/v1/auth/aic/login",
    sso: "oauth/sso_authorization_code",
    callback: "api/v1/auth/aic/callback",
    logout: "api/v1/auth/aic/logout",
    me: "api/v1/customers/me",
    addUserInfo: "add-user-info",
    dashboard: "dashboard",
    profileImage: "api/v1/customers/me/profile-picture"
  },
  cart: {
    details: "api/v1/cart",
    items: "api/v1/cart/items",
    billingAddress: "api/v1/cart/billing-address",
    voucher: "api/v1/cart/voucher"
  },
  checkout: {
    paymentMethods: "api/v1/checkout/payment-methods",
    paymentMethodsCustomer: "api/v1/customers/me/payment-methods",
    pay: "api/v1/checkout/payments",
    checkoutCallback: "api/v1/checkout/payments/return",
    coupon: "api/v1/checkout/coupons",
    couponCheck: "api/v1/checkout/check-coupon"
  },
  product: {
    items: "api/v1/products",
    forSale: "api/v1/products/for-sale",
    membershipCodes: "api/v1/products/membership-codes"
  },
  order: {
    details: "api/v1/customers/me/orders"
  },
  membership: {
    details: "api/v1/memberships"
  },
  jservices: {
    country: "api/v1/checkout/utils/country",
    province: "api/v1/checkout/utils/province",
    municipality: "api/v1/checkout/utils/municipality",
    cfvalidate: "api/v1/checkout/utils/cfvalidate"
  }
};

const env_config = function() {
  switch (AppInfo.env) {
    case "production":
      return prod;
    case "test":
      return test;
    case "development":
      return dev;
    default:
      return dev;
  }
};

const Config = {
  // Add common config values here
  // start: new Date(),
  app_name: AppInfo.name,
  app_version: AppInfo.version,
  // supported_languages: ["EN", "IT"],
  regex: {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Letters: /[a-z A-Z\-'\s]/g,
    FirstLetter: /^[a-z A-Z]/g,
    Last: /[a-zA-Z']\b/g,
    Only: /(['-/])\1/g,
    NoSubstring: /-/g,
    sdi: /^[A-Za-z0-9]{7}$/,
    // piva: /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}[A-Z]{1}|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?([0-9]{7}[A-Z]{1}|[0-9]{1}[A-Z]{1}[0-9]{5}[A-Z]{1}|[0-9]{6}[A-Z]{2})|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NO)?[0-9]{9}MVA|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10}|(ES)?([A-Z]{1}[0-9]{8}|[0-9]{8}[A-Z]{1}|[A-Z]{1}[0-9]{7}[A-Z]{1})|(CHE)?[0-9]{9}(MWST|TVA|IVA))$/,
    piva: /^[A-Za-z0-9]{5,30}$/,
    piva_iso: {
      AU: {
        countryName: "Austria",
        regex: /^(AT)?U[0-9]{8}$/
      },
      BE: {
        countryName: "Belgium",
        regex: /^(BE)?0[0-9]{9}$/
      },
      BG: {
        countryName: "Bulgaria",
        regex: /^(BG)?[0-9]{9,10}$/
      },
      CH: {
        countryName: "Switzerland",
        regex: /^(CHE)?[0-9]{9}(MWST|TVA|IVA)$/
      },
      CY: {
        countryName: "Cyprus",
        regex: /^(CY)?[0-9]{8}L$/
      },
      CR: {
        countryName: "Czech Republic",
        regex: /^(CZ)?[0-9]{8,10}$/
      },
      DE: {
        countryName: "Germany",
        regex: /^(DE)?[0-9]{9}$/
      },
      DK: {
        countryName: "Denmark",
        regex: /^(DK)?[0-9]{8}$/
      },
      EE: {
        countryName: "Estonia",
        regex: /^(EE)?[0-9]{9}$/
      },
      GR: {
        countryName: "Greece",
        regex: /^(EL|GR)?[0-9]{9}$/
      },
      ES: {
        countryName: "Spain",
        regex: /^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$/
      },
      FI: {
        countryName: "Finland",
        regex: /^(FI)?[0-9]{8}$/
      },
      FR: {
        countryName: "France",
        regex: /^(FR)?[0-9A-Z]{2}[0-9]{9}$/
      },
      GB: {
        countryName: "United Kingdom",
        regex: /^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/
      },
      HU: {
        countryName: "Hungary",
        regex: /^(HU)?[0-9]{8}$/
      },
      IE: {
        countryName: "Ireland",
        regex: /^(IE)?[0-9]S[0-9]{5}L$/
      },
      IT: {
        countryName: "Italy",
        regex: /^[0-9]{11}$/
      },
      LT: {
        countryName: "Lithuania",
        regex: /^(LT)?([0-9]{9}|[0-9]{12})$/
      },
      LU: {
        countryName: "Luxembourg",
        regex: /^(LU)?[0-9]{8}$/
      },
      LV: {
        countryName: "Latvia",
        regex: /^(LV)?[0-9]{11}$/
      },
      MT: {
        countryName: "Malta",
        regex: /^(MT)?[0-9]{8}$/
      },
      NL: {
        countryName: "Netherlands",
        regex: /^(NL)?[0-9]{9}B[0-9]{2}$/
      },
      NO: {
        countryName: "Norway",
        regex: /^(NO)?[0-9]{9}MVA,$/
      },
      PL: {
        countryName: "Poland",
        regex: /^(PL)?[0-9]{10}$/
      },
      PT: {
        countryName: "Portugal",
        regex: /^(PT)?[0-9]{9}$/
      },
      RO: {
        countryName: "Romania",
        regex: /^(RO)?[0-9]{2,10}$/
      },
      SE: {
        countryName: "Sweden",
        regex: /^(SE)?[0-9]{12}$/
      },
      SI: {
        countryName: "Slovenia",
        regex: /^(SI)?[0-9]{8}$/
      },
      SK: {
        countryName: "Slovakia",
        regex: /^(SK)?[0-9]{10}$/
      }
    }
  },
  apis,
  ...env_config(),
  start: new Date().toISOString(),
  // env: "dev",
  // label: "Development",
  logger: false,
  localeCookieName: "jc_prf_lng",
  // apiBaseurl: "https://juventus-membership-api-dev.herokuapp.com",
  // authBaseurl: "https://account-sprint.certimetergroup.com",
  // jApiServices: "https://juventusapp-test.juventus.com/JApiServices/rest",
  // oauthClientId: "8kqnrkp65ca3kfr4hdc88aa65ax84k4s",
  ...window.config
};

export default Config;
