import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./TextInput.scss";
import LabelText from "../LabelText/LabelText";

import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg";
import Localize from "../../libs/localization";

export default ({
  label,
  disabled = false,
  className = "",
  value,
  name,
  placeholder,
  required = false,
  onChange,
  onBlur,
  error = false,
  errorLabel = Localize("FORMS.ERRORS.GENERIC"),
  info = false,
  type = "text",
  regex = null,
  tooltip,
  prefix,
  maxlength,
  minlength,
  fullObjectReturn = false,
  autocomplete = true
}) => {
  return (
    <div className={"j-input"}>
      {label && <LabelText label={label} required={required} />}
      {info && <QuestionMark />}
      {error && <span className="error-box">{errorLabel}</span>}
      {type === "phone" ? (
        <PhoneInput
          disabled={disabled}
          placeholder={placeholder}
          country="it"
          value={value || ""}
          onChange={onChange}
          inputClass={
            "phone-box input-box" +
            ((!disabled && " activeBorder") || "") +
            ((error && " error") || "")
          }
          autoFormat={!disabled}
          buttonClass={"phone-dropdown"}
          disableDropdown={disabled}
          prefix={disabled ? "" : undefined}
        />
      ) : (
        <input
          name={name}
          autoComplete={(autocomplete === "false" && "off") || ""}
          className={
            "input-box" +
            ((!disabled && " activeBorder") || "") +
            ((error && " error") || "") +
            (className ? ` ${className}` : "")
          }
          disabled={disabled && "disabled"}
          value={value || ""}
          maxLength={maxlength || 255}
          minLength={minlength || 0}
          required={required}
          placeholder={placeholder}
          contentEditable={!disabled}
          onBlur={
            typeof onBlur === "function"
              ? e => onBlur(e.currentTarget.value)
              : null
          }
          type={type}
          onChange={e => {
            let val = e.currentTarget.value;
            if (type === "number") {
              val = val.replace(/\D+/g, "");
            }
            if (regex) {
              val = val.replace(regex, "");
            }
            if (prefix && typeof val === "string" && val !== "") {
              val = val.replace(prefix, "");
              val = `${prefix}${val}`;
            }
            onChange(fullObjectReturn ? { value: val, key: name } : val, name);
          }}
        />
      )}
    </div>
  );
};
