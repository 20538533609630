import React, { useEffect } from "react";
import "./Snackbar.scss";

const Snackbar = ({
  message,
  open,
  handleClose,
  variant = "success",
  autoHideDuration = 3000
}) => {
  useEffect(() => {
    const timer =
      open &&
      setTimeout(() => {
        handleClose();
      }, autoHideDuration);
    return () => open && timer && clearTimeout(timer);
  }, [open, handleClose, autoHideDuration]);

  return (
    <div
      className={`snackbar ${variant} animation fadein-down ${
        open ? "show" : ""
      }`}
    >
      <div className="snackbar-content" onClick={() => handleClose()}>
        <div className="snackbar-message">{message}</div>
      </div>
    </div>
  );
};

export default Snackbar;
