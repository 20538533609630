import _ from "lodash";
import moment from "moment";
import log from "./logger";
import appInfo from "./appInfo";
import { getFromLocalStorage, removeFromLocalStorage } from "./localstorage";
import { push } from "connected-react-router";
import { setErrorAction } from "../../actions/app.actions";
import { getCurrentLocale } from "../localization";

export const API_ERROR = "API_ERROR";
export const API_LOADING = "API_LOADING";

const defaultErrorProcessor = (error, dispatch, errorAction) => {
  /* Default error processor, clears the user if the API response status is 401 */
  log.error(error.stack, error);

  // Internal mock
  // {"id":"voucher.creation.existent_code","code":"70-202","label":"Il codice del voucher è già presente","status":422,"message":""}

  let { name, status, message, id, date, details, code } = error;

  const { label } = details || {};

  message = label ? label : message;

  // eslint-disable-next-line eqeqeq
  if (status == 401 || status == 403) {
    removeFromLocalStorage("user");
    removeFromLocalStorage("token");
    dispatch(push("/login"));
  } else {
    dispatch(
      setErrorAction({
        name,
        status,
        message,
        id,
        date,
        code
      })
    );
  }

  // switch (code) {
  //   case "401":
  //     dispatch(
  //       setErrorAction({
  //         message: "UNAUTHORIZED",
  //         code: 401,
  //         stack: error.message,
  //       })
  //     );
  //     // dispatch(push("/error"));
  //     // dispatch({
  //     //   type: USER_LOGOUT
  //     // });
  //     break;
  //   case "500":
  //     dispatch(push("/error"));
  //     // dispatch({
  //     //   type: "SERVER_ERROR",
  //     //   code
  //     // });
  //     break;
  //   case "503":
  //     dispatch(push("/error"));
  //     // dispatch({
  //     //   type: "SERVER_ERROR",
  //     //   code
  //     // });
  //     break;
  //   default:
  //     dispatch(push("/error"));
  //   // dispatch({
  //   //   type: "SERVER_ERROR",
  //   //   code
  //   // });
  // }
};

export const headersBuilder = () => ({
  // "Accept-Language": getCurrentLocaleExtended(),
  "Accept-Language": getCurrentLocale(),
  "App-Launch-Count": _.get(getFromLocalStorage(), "app-launch-count", 1), //How many times the app was launched
  "App-Unique-Run-Id": _.get(getFromLocalStorage(), "app-run-id", null), //Unique Id to this concrete execution
  "App-Id": appInfo.name, //Packagename/Bundle Identifier
  "App-Build-Type": appInfo.env, //Debug/Release
  "App-Version-Code": appInfo.version, //Version Code
  "Device-OS": navigator.platform, //Operating System ios/android
  "Device-OS-Version": navigator.appVersion || navigator.userAgent, //Operating System Version
  "Device-Screen-Width": window.screen.availWidth, //Screen width in pixels
  "Device-Screen-Height": window.screen.availHeight, //Screen height in pixels
  // "Device-Push-Notifications-Enabled":
  //   _.get(Notification, "permission") === "granted" ? true : false, //true/false
  TimeOffset: moment().format("ZZ"), //Offset from UTC in Format +-HHMM (See ISO8601 Specs)
  "Device-Screen-DPR": `${window.devicePixelRatio}x` //DPR zoom !== DPI
});

/**
 * Handles API calls and manages actions and errors
 *
 * @param {Function} apiCall
 * @param {Boolean} secured Injects access_token (default: false)
 * @param {String} startAction The first action to be called (default: API_LOADING)
 * @param {String} successAction The success action to be called
 * @param {String} errorAction The error action to be called (default: API_ERROR)
 * @param {Function} processResult The optional function to be called for result processing {result, dispatch, getState}
 * @param {Function} processError The optional function to be called for error processing {error, dispatch, errorAction, getState} (default: defaultErrorProcessor)
 * @param {Function} headers The optional function to be called to build headers {user} (default: headersBuilder)
 * @param {String} queryAuth The optional choice to put token as a queryParam (default: false)
 */
export default ({
  apiCall,
  secured = false,
  startAction = API_LOADING,
  successAction,
  errorAction = API_ERROR,
  processResult = null,
  processError = defaultErrorProcessor,
  headers = headersBuilder,
  queryAuth = false
}) => {
  return (params = {}) => async (dispatch, getState) => {
    const { token } = getState().auth;
    params.headers = headers();
    if (secured && token) {
      /* If secured, injects access_token in the apiCall function */
      if (queryAuth) {
        params[queryAuth] = token;
      } else {
        params.headers.Authorization = `Bearer ${token}`;
      }
    }
    dispatch({ type: startAction, params });

    try {
      let result = await apiCall(params);

      if (processResult)
        result = processResult(result, dispatch, getState, params);

      if (successAction) {
        dispatch({
          type: successAction,
          data: result
        });
      }
    } catch (error) {
      if (processError)
        processError(error, dispatch, errorAction, getState, params);
      dispatch({
        type: errorAction,
        error
      });
    }
  };
};
