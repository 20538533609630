import Config from "../Config";
import ErrorMessage from "../libs/helpers/Error";

export const authorizeApi = async ({ code }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.callback}?authorization_code=${code}&redirect_uri=${Config.appBaseurl}/authorize`,
    {
      method: "GET"
    }
  );
  const response = await res.json();
  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const getUserInfoApi = async ({ headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.oauth.me}`, {
    method: "GET",
    headers
  });

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const ssoLoginApi = async () => {
  return fetch(
    `${Config.authBaseurl}/${Config.apis.oauth.sso}?client_id=${Config.oauthClientId}&redirect_uri=${Config.appBaseurl}/authorize`,
    {
      credentials: "include"
    }
  );
};
