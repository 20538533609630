import { reducerFromMap } from "../libs/helpers/reducers";

import {
  PAYMENT_DONE,
  PAYMENT_ERROR,
  PAYMENT_STARTED,
  CHECKOUT_DOING,
  CHECKOUT_ERROR,
  CHECKOUT_DONE
} from "../actions/checkout.actions";

const reducer = {
  [CHECKOUT_DOING]: (state, actions) => ({
    ...state,
    loading: true
  }),
  [CHECKOUT_DONE]: (state, actions) => ({
    ...state,
    // loading: false,
    error: null
    // user: null,
  }),
  [CHECKOUT_ERROR]: (state, actions) => ({
    ...state,
    loading: false,
    error: actions.data
  }),
  [PAYMENT_STARTED]: (state, actions) => ({
    ...state,
    loading: true
  }),
  [PAYMENT_DONE]: (state, actions) => ({
    ...state,
    loading: false,
    error: null
    // user: null,
  }),
  [PAYMENT_ERROR]: (state, actions) => ({
    ...state,
    loading: false,
    error: actions.data
  })
};

export default reducerFromMap(reducer);
