import React from "react";
import Button from "../Button/Button";
import localize from "../../libs/localization";

import "./EditableCard.scss";
import { Row } from "../Grid";

export default ({
  children,
  hideUI = false,
  editing = false,
  cancelFunction,
  editFunction
}) => {
  if (hideUI) {
    return <div>{children}</div>;
  } else {
    return (
      <div className="editable-card">
        <Row>
          {Array.isArray(children)
            ? children.map((c, i) => ({
                ...c,
                props: { ...c.props, disabled: !editing }
              }))
            : { ...children, props: { ...children.props, disabled: !editing } }}
        </Row>
        <Row style={{ marginTop: 30 }}>
          {editing ? (
            <Button
              label={localize("COMMON.CANCEL")}
              type="button"
              size="M"
              // onClick={typeof cancelFunction === "function" && cancelFunction}
              onClick={e =>
                (typeof cancelFunction === "function" && cancelFunction(e)) ||
                null
              }
            />
          ) : (
            <Button
              label={localize("COMMON.EDIT")}
              type="button"
              size="M"
              onClick={typeof editFunction === "function" && editFunction}
            />
          )}
        </Row>
      </div>
    );
  }
};
