import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Arrows } from "../../assets/icons/double-arrow.svg";
import { ReactComponent as AddPlus } from "../../assets/icons/add-plus.svg";

import "./Button.scss";

const Button = ({
  label,
  primary = false,
  white = false,
  transparent = false,
  noBorder = false,
  onClick = null,
  style,
  hideHover = false,
  disabled = false,
  size = "L",
  className = "",
  icon = false,
  textUnderline = false,
  textUnderlineHover = false,
  flexWidth = false,
  role = "button"
}) => {
  if (disabled) {
    hideHover = true;
  }
  const classNameArray = ["button"];
  classNameArray.push(className);
  classNameArray.push((primary && "button-primary") || "");
  classNameArray.push((white && "button-white") || "");
  classNameArray.push((transparent && "button-transparent") || "");
  classNameArray.push((noBorder && "no-border") || "");
  classNameArray.push((hideHover && "no-hover") || "");
  classNameArray.push((flexWidth && "flex-width") || "");
  classNameArray.push(`button-${size.toLowerCase()}`);

  className = classNameArray
    .join(" ")
    .replace(/[ ]{2,}/g, " ")
    .trim();

  // style = { ...((!onClick && { pointerEvents: "none" }) || {}), ...style };

  return (
    <button
      className={className}
      style={style}
      type="button"
      onClick={typeof onClick === "function" ? onClick : null}
      disabled={disabled && "disabled"}
      role={role}
    >
      <span
        className={`label${textUnderlineHover ? " hover-underline" : ""}`}
        style={textUnderline ? { textDecoration: "underline" } : {}}
      >
        {(icon && typeof icon === "boolean" && <AddPlus />) || icon || null}
        {label}
      </span>
      {!hideHover && (
        <span className="hover-icon">
          <Arrows />
        </span>
      )}
    </button>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(["", "XL", "L", "M", "S"])
};

Button.defaultProps = {
  size: "L"
};

export default Button;
