import React from "react";

import "./Grid.scss";

const maxCols = 24;

const Container = ({ children, style, className }) => (
  <div className={`container ${className || ""}`} style={style}>
    {children.length > 1 ? children.map((c, i) => c) : children}
  </div>
);

const Row = ({ id, children, style, className }) => (
  <div id={id} className={`row ${className || ""}`} style={style}>
    {children && children.length > 1 ? children.map(c => c) : children}
  </div>
);

const getColClass = (size = maxCols, span = null, skipObj = false) => {
  let classSize = "";
  if (size === null || size === undefined) {
    classSize = `col${span ? `-${span}` : ""}-${maxCols}`;
  } else if (typeof size === "number") {
    classSize = `col${span ? `-${span}` : ""}-${
      size > maxCols ? maxCols : size
    }`;
  } else if (size === "auto") {
    classSize = `col${span ? `-${span}` : ""}-${size}`;
  } else if (size === "") {
    classSize = `col${span ? `-${span}` : ""}`;
  } else if (typeof size === "string") {
    let parsedSize;
    try {
      parsedSize = parseInt(size);
    } catch (e) {
      parsedSize = "auto";
    }
    classSize = `col${span ? `-${span}` : ""}-${
      !isFinite(parsedSize) || parsedSize > maxCols ? maxCols : parsedSize
    }`;
  } else if (typeof size === "object" && skipObj === false) {
    Object.keys(size).map((s, i) => {
      classSize += `${getColClass(size[s], s, true)} `;
      return s;
    });
  }

  return classSize;
};

const getOffsetClass = (offset = 0, span = null, skipObj = false) => {
  let classOffset = "";
  if (typeof offset === "number") {
    classOffset = `offset${span ? `-${span}` : ""}-${
      offset > maxCols - 1 ? maxCols - 1 : offset
    }`;
  } else if (typeof offset === "string") {
    let parsedOffset = parseInt(offset);
    classOffset = `offset${span ? `-${span}` : ""}-${
      !isFinite(parsedOffset) || parsedOffset > maxCols - 1
        ? maxCols - 1
        : parsedOffset
    }`;
  } else if (typeof offset === "object" && skipObj === false) {
    Object.keys(offset).map((s, i) => {
      classOffset += `${getOffsetClass(offset[s], s, true)} `;
      return s;
    });
  }

  return classOffset;
};

const Col = ({
  children,
  style,
  className = "",
  size = null,
  offset = null
}) => {
  let classSize = getColClass(size);
  let classOffset = offset ? getOffsetClass(offset) : null;

  return (
    <div
      className={`${classSize || ""} ${classOffset || ""} ${className}`}
      style={style}
    >
      {Array.isArray(children) ? children.map(c => c) : children}
    </div>
  );
};
export { Container, Row, Col };
