import React, { lazy } from "react";

import { type } from "./libs/router";

import Config from "./Config";
import { removeFromLocalStorage } from "./libs/helpers/localstorage";
// import ProductsListPage from "./pages/productsList/productsList.page";
// import CartPage from "./pages/cart/cart.page";
// import ProductDetailPage from "./pages/productDetail/productDetail.page";
import CheckoutPage from "./pages/checkout/checkout.page";
// import BuyProductPage from "./pages/buyProduct/buyProduct.page";
// import BuyProductUpgrade from "./pages/buyProduct/buyProductUpgrade";
// import BuyProductRenewal from "./pages/buyProduct/buyProductRenewal";
import PaymentPage from "./pages/system/payment.page";

import Localize from "./libs/localization";
import AuthPage from "./pages/system/auth.page";
import RedeemGift from "./pages/system/redeemGift.page";
import ReloadDocuments from "./pages/system/reloadDocuments.page";
import { LoginPage, CourtesyPage } from "./pages";

const ProductsListPage = lazy(() =>
  import("./pages/productsList/productsList.page")
);
const ProductsListNewPage = lazy(() =>
  import("./pages/productsList/productsList.new.page")
);
const ProductDetailPage = lazy(() =>
  import("./pages/productDetail/productDetail.page")
);
const BuyProductPage = lazy(() => import("./pages/buyProduct/buyProduct.page"));
const BuyProductUpgrade = lazy(() =>
  import("./pages/buyProduct/buyProductUpgrade")
);
const BuyProductRenewal = lazy(() =>
  import("./pages/buyProduct/buyProductRenewal")
);
const CartPage = lazy(() => import("./pages/cart/cart.page"));
const ManageMyMembPage = lazy(() => import("./pages/mymembership/manage.page"));
const ThankYouPage = lazy(() => import("./pages/thankYou/thankYou.page"));
const CouponPage = lazy(() => import("./pages/coupon/coupon.page"));

export default [
  {
    name: "Login",
    path: "/login",
    noFollow: true,
    title: Localize("PAGES.LOGIN.TITLE"),
    showInMenu: false,
    type: type.AUTHENTICATION,
    component: LoginPage
    // component: ({ location }) => {
    //   const { search } = location;
    //   const URLparams = new URLSearchParams(search);
    //   const redirectUri = URLparams.get("redirectUri");

    //   ssoLoginApi()
    //   .then(res => res.json())
    //   .then(res => {
    //     const { data } = res;
    //     if (!res.error && data && data.authorization_code) {
    //       authorizeAction({
    //         code: data.authorization_code
    //       });
    //     }
    //   })
    //   .catch(e => {
    //     console.warn("sso", e);
    //   });

    //   setOnLocalStorage({ redirectUri });

    //   // const loginUrl = `${Config.authBaseurl}/${Config.apis.oauth.sso}?client_id=${Config.oauthClientId}&redirect_uri=${Config.appBaseurl}/authorize`;
    //   const loginUrl = `${Config.apiBaseurl}/${Config.apis.oauth.login}?redirect_uri=${Config.appBaseurl}/authorize`;
    //   PageView(loginUrl);
    //   window.location = loginUrl;
    //   return <Loading coverBack={true} />;
    // }
    // redirectToExternal: `${Config.apiBaseurl}/${Config.apis.oauth.login}`,
    // redirectToExternal: `${Config.apiBaseurl}/${Config.apis.oauth.login}?redirect_uri=${Config.appBaseurl}/authorize`,
  },
  {
    name: "Authorize",
    path: "/authorize",
    noFollow: true,
    component: AuthPage,
    // component: () => <code>Authorizing...</code>,
    showInMenu: false,
    type: type.AUTHENTICATION
  },
  {
    name: "Logout",
    path: "/logout/do",
    showInMenu: false,
    noFollow: true,
    type: type.SECURED,
    component: () => {
      removeFromLocalStorage("user");
      removeFromLocalStorage("token");
      removeFromLocalStorage(null, "user_buffer");
      window.location = `${Config.apiBaseurl}/${Config.apis.oauth.logout}`;
      return <AuthPage />;
    }
    // redirectToExternal: `${Config.apiBaseurl}/${Config.apis.oauth.logout}`
  },
  {
    name: "Root",
    path: "/",
    title: Localize("PAGES.PRODUCTS.TITLE_SAFE"),
    showInMenu: false,
    icon: "idcard",
    redirectTo: "/products"
  },
  // {
  //   name: "Products",
  //   title: Localize("PAGES.PRODUCTS.TITLE_SAFE"),
  //   path: "/products-new",
  //   component: ProductsListPage,
  //   showInMenu: true,
  //   icon: "box",
  //   type: type.PUBLIC,
  //   cart: "light",
  //   coupon: "light",
  // },
  {
    name: "Products New",
    title: Localize("PAGES.PRODUCTS.TITLE_SAFE"),
    path: "/products",
    component: ProductsListNewPage,
    showInMenu: true,
    icon: "box",
    type: type.PUBLIC,
    cart: "light",
    coupon: "light"
  },
  {
    name: "ProductDetail",
    path: "/products/:id",
    component: ProductDetailPage,
    showInMenu: false,
    noFollow: true,
    type: type.PUBLIC,
    cart: "light",
    coupon: "light"
  },
  {
    name: "BuyProduct",
    path: "/products/:id/configure",
    noFollow: true,
    component: BuyProductPage,
    showInMenu: false,
    type: type.SECURED,
    cart: "light"
  },
  {
    name: "BuyProduct",
    path: "/products/:id/upgrade/:code",
    noFollow: true,
    component: BuyProductUpgrade,
    showInMenu: false,
    type: type.SECURED,
    cart: "dark"
  },
  {
    name: "BuyProduct",
    path: "/products/:id/renewal/:ms",
    noFollow: true,
    component: BuyProductRenewal,
    showInMenu: false,
    type: type.SECURED,
    cart: "dark"
  },
  {
    name: "BuyProduct",
    path: "/products/:id/configure/:process",
    noFollow: true,
    component: BuyProductPage,
    showInMenu: false,
    type: type.SECURED,
    cart: "dark"
  },
  {
    name: "Cart",
    path: "/cart",
    noFollow: true,
    component: CartPage,
    showInMenu: false,
    type: type.SECURED
  },
  {
    name: "Cart",
    path: "/cart/:cart_item",
    noFollow: true,
    component: CartPage,
    showInMenu: false,
    type: type.SECURED
  },
  {
    name: "Manage My Memberships",
    path: "/mymembership/manage",
    noFollow: true,
    component: ManageMyMembPage,
    showInMenu: false,
    type: type.SECURED
  },
  {
    name: "Manage My Membership",
    path: "/mymembership/manage/:id",
    noFollow: true,
    component: ManageMyMembPage,
    showInMenu: false,
    type: type.SECURED
  },
  {
    name: "Checkout",
    path: "/checkout",
    noFollow: true,
    component: CheckoutPage,
    showInMenu: false,
    type: type.SECURED
  },
  {
    name: "PaymentProcess",
    path: "/payment",
    noFollow: true,
    showInMenu: false,
    component: PaymentPage,
    // type: type.SECURED
    type: type.PUBLIC // AUTH REMOVED TO PREVENT AIC REFRESH ERRORS
  },
  {
    name: "PaymentGateway",
    path: "/paymentgateway/:process",
    noFollow: true,
    // component: LoginPage,
    component: AuthPage,
    showInMenu: false,
    type: type.AUTHENTICATION
  },
  {
    name: "ReloadDocuments",
    path: "/reload-docs",
    noFollow: true,
    showInMenu: false,
    exact: false,
    component: ReloadDocuments,
    type: type.SECURED
  },
  {
    name: "RedeemGift",
    path: "/redeem-gift",
    noFollow: true,
    showInMenu: false,
    exact: false,
    component: RedeemGift,
    type: type.SECURED
  },
  {
    name: "RedeemGift",
    path: "/reedem-gift",
    noFollow: true,
    showInMenu: false,
    exact: false,
    component: RedeemGift,
    type: type.SECURED
  },
  {
    name: "Renew",
    path: "/renewal",
    noFollow: true,
    showInMenu: false,
    exact: false,
    component: BuyProductRenewal,
    type: type.SECURED
  },
  {
    name: "ThankYou",
    path: "/thankYou",
    noFollow: true,
    component: ThankYouPage,
    showInMenu: false,
    type: type.SECURED
  },
  {
    name: "Coupon",
    path: "/coupon",
    noFollow: false,
    component: CouponPage,
    showInMenu: false,
    type: type.PUBLIC
  },
  {
    name: "Error",
    path: "/error",
    noFollow: true,
    showInMenu: false,
    type: type.PUBLIC,
    // component: ({ location }) => {
    //   const { search } = location;
    //   const URLparams = new URLSearchParams(search);
    //   const message = URLparams.get("message");
    //   debugger;
    //   return <Loading coverBack={true} />;
    // },
    component: CourtesyPage
  }
];
