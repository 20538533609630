import Config from "../Config";

export const orderApi = async ({ headers, orderId }) => {
  if (orderId) {
    try {
      const res = await fetch(
        `${Config.apiBaseurl}/${Config.apis.order.details}/${orderId}`,
        {
          method: "GET",
          headers
        }
      );

      const response = await res.json();

      if (res.ok && response) {
        return response;
      }

      return null;
    } catch (e) {
      return null;
    }
  }
  return null;
};
