// import { Layout, Button } from "antd";
import React, { Component } from "react";

import Localize from "../../libs/localization";
import { ReactComponent as JLogo } from "../../assets/icons/j_logo.svg";

import "./system.style.scss";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import { connect } from "react-redux";
import { paymentAction } from "../../actions/checkout.actions";
import { goTo } from "../../actions/app.actions";
import { PageView } from "../../libs/helpers/gtm";

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = { seeMore: false };
  }
  componentDidMount() {
    const { user, token, paymentAction, goTo } = this.props;
    const { search } = this.props.location;
    const URLparams = new URLSearchParams(search);
    const process = URLparams.get("process");
    this.setState({ query: search, process });

    if (user && token) {
      PageView();
      paymentAction({ query: search });
    } else {
      goTo("/error");
    }
  }

  componentWillMount() {
    // document.body.style = "";
  }

  componentDidUpdate(prevProps) {
    const { loading, error, goTo } = this.props;
    if (prevProps.loading === true && loading === false && !error) {
      goTo("/thankyou");
    }
  }

  render() {
    const { process } = this.state;
    const { user, token, loading, error, goTo } = this.props;

    if (!user || !token) {
      return <Loading />;
    }

    return (
      <div className="system-error animation fadein-up fast">
        <div className="system-page">
          <div className="system-box animation fadein fast">
            <div className="logo">
              <JLogo />
            </div>
            <div className="message-head">
              {Localize(
                `PAYMENT.${
                  error ? "ERROR" : (process || "DEFAULT").toUpperCase()
                }`
              )}
            </div>
            {loading ? (
              <Loading fixed={false} />
            ) : (
              <>
                {error && (
                  <>
                    <Button
                      onClick={() => goTo("/")}
                      style={{ marginTop: 20 }}
                      size={"M"}
                      label={Localize(`LOGIN.BACK_HOME`)}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, checkout }) => ({
  user: auth.user,
  token: auth.token,
  loading: checkout.loading,
  error: checkout.error
});

const mapDispatchToProps = { paymentAction, goTo };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
