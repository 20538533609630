import React, { Component } from "react";

import { goTo } from "../../actions/app.actions";
import { getProductDetailAction } from "../../actions/products.actions";
import { connect } from "react-redux";
import { Col, Container, Row } from "../../components/Grid";
import SimpleCard from "../../components/SimpleCard/SimpleCard";
import { isMobileDevice, scrollToTop } from "../../libs/utils";
import Localize from "../../libs/localization";
import { ReactComponent as CircleCheck } from "../../assets/icons/circle-check.svg";
import {
  checkoutGallery1,
  checkoutGallery2,
  checkoutGallery3,
  memb_ph
} from "../../assets/images";
import "./thankYou.styles.scss";
import MetaTags from "../../components/MetaTags/MetaTags";
import { PageView, PurchaseCompleted } from "../../libs/helpers/gtm";
import { orderApi } from "../../api/order.api";
import { headersBuilder } from "../../libs/helpers/callApi";
import Carousel from "../../components/Carousel/Carousel";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentCarouselValue: 0
    };
  }

  componentDidMount() {
    PageView();
    this.setState(
      {
        photos: [
          {
            label: Localize("THANK_YOU.GALLERY.1.TEXT"),
            image: checkoutGallery1,
            onClick: () =>
              (window.location = Localize("THANK_YOU.GALLERY.1.URL")),
            button: Localize("THANK_YOU.GALLERY.1.BUTTON")
          },
          {
            label: Localize("THANK_YOU.GALLERY.2.TEXT"),
            image: checkoutGallery2,
            onClick: () =>
              (window.location = Localize("THANK_YOU.GALLERY.2.URL")),
            button: Localize("THANK_YOU.GALLERY.2.BUTTON")
          },
          {
            label: Localize("THANK_YOU.GALLERY.3.TEXT"),
            image: checkoutGallery3,
            onClick: () =>
              (window.location = Localize("THANK_YOU.GALLERY.3.URL")),
            button: Localize("THANK_YOU.GALLERY.3.BUTTON")
          }
        ]
      },
      async () => {
        scrollToTop();
        const { location, token } = this.props;
        const { search } = location;
        const URLparams = new URLSearchParams(search);
        const orderId = URLparams.get("orderId");
        if (token && orderId) {
          const order = await orderApi({
            orderId,
            headers: { ...headersBuilder(), Authorization: `Bearer ${token}` }
          });
          if (order) {
            PurchaseCompleted(order);
          }
        }
      }
    );
  }

  render() {
    const { photos = [] } = this.state;
    const isOnMobile = isMobileDevice();
    return (
      <Container className={"j-thank-you"}>
        <MetaTags
          title={`Juventus Membership - ${Localize("PRODUCTS.TITLE_SAFE")}`}
          desc={Localize("PRODUCTS.DESC_SAFE")}
          img={memb_ph}
        />
        <Row>
          <Col size={24} className={"text-center svg-circle"}>
            <CircleCheck />
          </Col>
          <Col
            size={24}
            className={"text-center title-text d-xs-none d-sm-none"}
          >
            {Localize("THANK_YOU.TITLE")}
          </Col>
          <Col size={24} className={"text-center title-text d-md-none"}>
            {Localize("THANK_YOU.TITLE_MOBILE")}
          </Col>
          <Col size={24} className={"text-center label-text d-md-none"}>
            {Localize("THANK_YOU.LABEL")}
          </Col>
          <Col size={24} className={"text-center my-5"}>
            <Link to={`/products`}>
              <Button
                className={"pointer"}
                primary={true}
                size={"L"}
                label={Localize("PRODUCT_CARD.GO_BACK")}
                onClick={e => {
                  e.stopPropagation();
                }}
                role="link"
              />
            </Link>
            <br />
            <Link to={`/products/jcard`}>
              <Button
                className={"pointer"}
                primary={false}
                size={"L"}
                label={Localize("BUY_PRODUCT.BUY_JCARD")}
                onClick={e => {
                  e.stopPropagation();
                }}
                role="link"
              />
            </Link>
          </Col>
        </Row>

        <Row className={"hero-thanks"}>
          <Col
            size={{ xs: 24, sm: 24, md: 18 }}
            className={"mx-auto py-md-5 px-md-0 wrap-carousel pr-sm-0 pr-xs-0"}
          >
            <Carousel
              slidesPerView={
                isOnMobile ? 1.2 : window.screen.width < 1024 ? 2.2 : 3.2
              }
              navigationHidden
            >
              {photos.map((el, i) => (
                <SimpleCard
                  key={i}
                  fullImage={true}
                  textDark={false}
                  image={el.image}
                  title={el.label}
                  button={el.button}
                  onClick={el.onClick}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, products }) => ({
  token: auth.token,
  product: products && products.productDetail,
  cmsLayout: products && products.cmsLayout
});

const mapDispatchToProps = {
  goTo,
  getProductDetailAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
