import React from "react";

import "./SimpleCard.scss";
import { Col, Container, Row } from "../Grid";
import Button from "../Button/Button";

export default ({
  title,
  text,
  image,
  button,
  onClick,
  normalized = false,
  fullImage = false,
  noBorderRadius = false,
  textDark = true,
  css = {},
  className = ""
}) => {
  return normalized ? (
    <Container className={`j-simpleCard normalized ${className}`}>
      <Row>
        {image && (
          <Col
            size={24}
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              height: 300,
              ...css
            }}
          />
        )}
        {title && text && (
          <>
            <Col
              size={24}
              className="titleText px-lg-5 px-sm-2 px-xs-2 pt-lg-5 pt-sm-2 pt-xs-2"
            >
              {title}
            </Col>
            <Col
              size={24}
              className="simpleText px-lg-5 px-sm-2 px-xs-2 pt-lg-3 pb-lg-5 p-sm-2 p-xs-2"
            >
              {text}
            </Col>
          </>
        )}
        {((!title && text) || (title && !text)) && (
          <Col
            size={24}
            className={`${
              title ? "titleText" : "simpleText"
            } p-lg-5 p-sm-2 p-xs-2`}
          >
            {title || text}
          </Col>
        )}
      </Row>
    </Container>
  ) : (
    <Container
      className={`j-simpleCard ${className}`}
      style={{ backgroundImage: `url(${image})`, ...css }}
    >
      {/* <div> */}
      {/* <img alt={"logo"} className={"image-background"} src={image} /> */}
      {/* <Row> */}
      <div
        // size={24}
        className={`titleText ${!textDark ? " color-white" : ""} px-3 py-4`}
      >
        {title}
        {button && (
          <>
            <Button
              className={"mt-3"}
              disabled={typeof onClick !== "function"}
              label={button}
              onClick={onClick}
              size={"S"}
              primary={true}
              style={{ display: "block" }}
            />
          </>
        )}
      </div>
      {/* </Row> */}
      {/* </div> */}
    </Container>
  );
};
