import React, { Component } from "react";
import { connect } from "react-redux";
import { goTo } from "../../actions/app.actions";

import icons from "../../assets/icons";

import "./CouponFab.scss";
import Localize from "../../libs/localization";

class CouponFab extends Component {
  state = {
    showSnackbar: false,
    snackbarMessage: undefined
  };

  componentDidMount() {
    // const { getCartContentAction, authorized, token } = this.props;
    // if (authorized || token) getCartContentAction();
  }

  componentDidUpdate(prevProps) {}

  render() {
    const { items = [], goTo, light = false } = this.props;
    return (
      <>
        <div
          className={`j-coupon-fab${light ? " light" : ""}`}
          onClick={() => goTo("/coupon")}
        >
          <div className="show-on-hover">{Localize("COUPON.COUPON")}</div>
          <div className="coupon-icon">
            <icons.couponFab style={{ height: "1.5em" }} />
          </div>
          <div className="new-badge" />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  goTo
};

export default connect(null, mapDispatchToProps)(CouponFab);
