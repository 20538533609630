import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { routerMiddleware } from "connected-react-router";
import history from "./history";

import rootReducer from "../reducers";
import appMiddlewares from "../middlewares";

import { register } from "../libs/helpers/ioc";
import { APP_START } from "../actions/app.actions";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const middlewares = [...appMiddlewares, routerMiddleware(history), thunk];

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    module.hot.accept("../reducers/", () => {
      const updatedRootReducer = require("../reducers").default;
      store.replaceReducer(updatedRootReducer);
    });
  }

  store.dispatch({ type: APP_START });

  register("store", store);

  return store;
};

export default configureStore;

export { history };
