import React, { useEffect } from "react";

import Localize from "../../libs/localization";

import { ReactComponent as Logo } from "../../assets/icons/j_logo.svg";

import "./system.style.scss";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { setErrorAction, resetErrorAction } from "../../actions/app.actions";
import { connect } from "react-redux";
import { scrollToTop } from "../../libs/utils";

const CourtesyPage = ({ location, setErrorAction, resetErrorAction }) => {
  const { search } = location;
  const URLparams = new URLSearchParams(search);
  let error = URLparams.get("error");
  if (error) {
    error = JSON.parse(error);
  }
  useEffect(() => {
    scrollToTop();
    return () => resetErrorAction();
  }, [resetErrorAction]);

  useEffect(() => {
    if (error) {
      const { id, label: message, code } = error;
      setErrorAction({
        status: null,
        message,
        id,
        code
      });
    }
  }, [error, setErrorAction]);

  return (
    <div className="system-page">
      <div className="system-box">
        <div className="logo">
          <Logo />
        </div>
        <div className="message">
          {/* <h2>{Localize("LOGIN.FORBIDDEN")}</h2> */}
        </div>
        <div className="action">
          <Link to={"/"}>
            <Button
              primary={true}
              // size={"M"}
              label={Localize(`ERROR.GET_BACK_HOME`)}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

// export default CourtesyPage;

const mapStateToProps = ({ app }) => ({
  // error: app.error,
});

export default connect(mapStateToProps, { setErrorAction, resetErrorAction })(
  CourtesyPage
);
