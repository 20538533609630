import Config from "../Config";
import ErrorMessage from "../libs/helpers/Error";

/*** PRODUCTS ***/
export const getProductsApi = async params => {
  let { data, headers } = params;
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.product.forSale}`,
    {
      method: "GET",
      headers,
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const getMembershipCodesApi = async params => {
  let { headers } = params;
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.product.membershipCodes}`,
    {
      method: "GET",
      headers
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const getProductDetailApi = async params => {
  let { headers, id } = params;
  // const qs = [
  //   `relations[]=tags`,
  //   `relations[]=images`,
  //   `relations[]=customizations`,
  //   `relations[]=promos`, //promo price
  //   `relations[]=membershipCode`,
  //   `relations[]=groupedProducts.tags`,
  //   `relations[]=groupedProducts.variants`,
  //   `relations[]=groupedProducts.customizations`,
  //   `relations[]=groupedProducts.images`,
  //   `relations[]=cms`,
  //   `relations[]=upgradeTo`,
  //   `relations[]=upgradeTo.images`,
  //   `relations[]=upgradeTo.cms`,
  //   `relations[]=upgradeTo.membershipCode`,
  //   `relations[]=upgradeTo.groupedProducts.tags`,
  //   `relations[]=upgradeTo.groupedProducts.variants`,
  //   `relations[]=upgradeTo.groupedProducts.customizations`,
  //   `relations[]=upgradeTo.groupedProducts.images`
  // ];
  const res = await fetch(
    // `${Config.apiBaseurl}/${Config.apis.product.items}/${id}/details?${qs.join(
    //   "&"
    // )}`,
    `${Config.apiBaseurl}/${Config.apis.product.items}/${id}/details`,
    {
      method: "GET",
      headers
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const getProductSizesApi = async ({ id, config }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.product.items}/${id}/sizes?${
      config ? `config=${JSON.stringify(config)}` : ""
    }`,
    {
      method: "GET"
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const getProductSizesByTypeApi = async ({ type }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.product.items}/sizes-type/${type}`,
    {
      method: "GET"
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const getProductByCouponCodeApi = async ({ headers, couponCode }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.checkout.coupon}/${couponCode}`,
    {
      method: "GET",
      headers
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};
