import React from "react";

import "./LabelText.scss";
import Icons from "../../assets/icons";

export default ({
  label,
  subLabel,
  subLabelIcon = <Icons.info />,
  htmlFor,
  className = "",
  required = false
}) => {
  return (
    <label htmlFor={htmlFor} className={"j-label"}>
      <div className={className}>
        {label}
        {required && <span className="required">*</span>}
        {subLabel && (
          <div className="sub-label">
            <div>{subLabelIcon}</div>
            <div>{subLabel}</div>
          </div>
        )}
      </div>
    </label>
  );
};
