import callApi from "../libs/helpers/callApi";
import {
  addItemsToCartApi,
  paymentMethodsApi,
  paymentMethodDeleteApi,
  paymentMethodsCustomerApi,
  createBillingAddressApi,
  deleteItemToCartApi,
  getCartContentApi,
  updateItemToCartApi,
  redeemVoucherApi
} from "../api/cart.api";

export const CART_LOADING = "CART_LOADING";
export const CART_LOADED = "CART_LOADED";
export const CART_ERROR = "CART_ERROR";

export const getCartContentAction = callApi({
  apiCall: p => getCartContentApi(p),
  secured: true,
  startAction: CART_LOADING,
  errorAction: CART_ERROR,
  successAction: CART_LOADED
});

export const CART_ADDED = "CART_ADDED";

export const addItemsToCartAction = callApi({
  apiCall: p => addItemsToCartApi(p),
  secured: true,
  startAction: CART_LOADING,
  errorAction: CART_ERROR,
  successAction: CART_ADDED
});

export const createBillingAddressAction = callApi({
  apiCall: p => createBillingAddressApi(p),
  secured: true,
  startAction: CART_LOADING,
  errorAction: CART_ERROR,
  successAction: CART_LOADED
});

export const createBillingAddressSilentAction = callApi({
  apiCall: p => createBillingAddressApi(p),
  secured: true,
  errorAction: CART_ERROR,
  successAction: CART_LOADED
});

export const updateItemToCartAction = callApi({
  apiCall: p => updateItemToCartApi(p),
  secured: true,
  startAction: CART_LOADING,
  errorAction: CART_ERROR,
  successAction: CART_ADDED
});

export const deleteItemToCartAction = callApi({
  apiCall: p => deleteItemToCartApi(p),
  secured: true,
  startAction: CART_LOADING,
  errorAction: CART_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch(getCartContentAction());
    }
  }
});

export const REDEEM_CODE_LOADING = "REDEEM_CODE_LOADING";
export const REDEEM_CODE_LOADED = "REDEEM_CODE_LOADED";
export const REDEEM_CODE_ERROR = "REDEEM_CODE_ERROR";

export const redeemVoucherAction = callApi({
  apiCall: p => redeemVoucherApi(p),
  secured: true,
  startAction: CART_LOADING,
  errorAction: CART_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: CART_LOADED, data: result });
    }
  }
});

export const PAYMENT_METHODS_LOADING = "PAYMENT_METHODS_LOADING";
export const PAYMENT_METHODS_LOADED = "PAYMENT_METHODS_LOADED";
export const PAYMENT_METHODS_ERROR = "PAYMENT_METHODS_ERROR";

export const paymentMethodsAction = callApi({
  apiCall: p => paymentMethodsApi(p),
  secured: true,
  startAction: PAYMENT_METHODS_LOADING,
  errorAction: PAYMENT_METHODS_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: PAYMENT_METHODS_LOADED, data: result });
    }
  }
});

export const paymentMethodDeleteAction = callApi({
  apiCall: p => paymentMethodDeleteApi(p),
  secured: true,
  startAction: PAYMENT_METHODS_LOADING,
  errorAction: PAYMENT_METHODS_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch(paymentMethodsAction());
    }
  }
});

export const PAYMENT_METHODS_CUSTOMER_LOADING =
  "PAYMENT_METHODS_CUSTOMER_LOADING";
export const PAYMENT_METHODS_CUSTOMER_LOADED =
  "PAYMENT_METHODS_CUSTOMER_LOADED";
export const PAYMENT_METHODS_CUSTOMER_ERROR = "PAYMENT_METHODS_CUSTOMER_ERROR";

export const paymentMethodsCustomerAction = callApi({
  apiCall: p => paymentMethodsCustomerApi(p),
  secured: true,
  startAction: PAYMENT_METHODS_CUSTOMER_LOADING,
  errorAction: PAYMENT_METHODS_CUSTOMER_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: PAYMENT_METHODS_CUSTOMER_LOADED, data: result });
    }
  }
});

// export const getVoucherAction = callApi({
//   apiCall: (p) => paymentMethodsApi(p),
//   secured: true,
//   startAction: PAYMENT_METHODS_LOADING,
//   errorAction: PAYMENT_METHODS_ERROR,
//   processResult: (result, dispatch, getState, params) => {
//     if (result.error) {
//       console.error(result.error);
//     } else {
//       dispatch({ type: PAYMENT_METHODS_LOADED, data: result });
//     }
//   },
// });
