import { refreshPage } from "../libs/utils";
import {
  APP_START,
  ROUTER_LOCATION_CHANGE,
  SET_ERROR
} from "../actions/app.actions";
import {
  AUTHORIZE_DONE,
  authorizeAction,
  updateAuth,
  getUserInfoAction,
  USERINFO_LOADED
} from "../actions/auth.actions";
import {
  getFromLocalStorage,
  setOnLocalStorage,
  removeFromLocalStorage
} from "../libs/helpers/localstorage";
import Config from "../Config";
import { getCookie, setCookie } from "../libs/helpers/cookie";
import { UserDataLoad } from "../libs/helpers/gtm";
import { matchPath } from "react-router";
import { getFlatRouteList, type } from "../libs/router";
import routes from "../routes";

const getStateAuthInfo = store => store.getState().auth;
// const getRoute = store => store.getState().router.location.pathname;

const checkUserLocale = lang => {
  const userLocale = getCookie(Config.localeCookieName);
  if (userLocale) {
    setOnLocalStorage(userLocale, "lang");
  }
};

const checkUser = (store, force = false) => {
  const ls = getFromLocalStorage();

  const storageInfoId = ls && ls.user && ls.user.id;
  const storageInfoToken = ls && ls.token;

  const { user, token } = getStateAuthInfo(store);

  // if (user && user.role && user.role.scopes) {
  //   register("scopes", generatePermFromScopes(user.role.scopes));
  // }
  if (storageInfoId && user && user.id && user.id !== storageInfoId) {
    removeFromLocalStorage("user");
    removeFromLocalStorage("token");
    refreshPage();
  } else if (token && force) {
    store.dispatch(getUserInfoAction());
  } else if (
    // (!token || token !== storageInfoToken) &&
    storageInfoToken &&
    storageInfoId
  ) {
    store.dispatch(updateAuth({ user: ls.user, token: ls.token }));
  } else if (force) {
    removeFromLocalStorage("user");
    removeFromLocalStorage("token");
  }
};

const checkQueryParams = store => {
  let { hash, query } = store.getState().router.location;

  if (query && query.authorization_code /*|| query.code*/) {
    /* CHECKING OAUTH RESPONSES */
    store.dispatch(
      authorizeAction({
        code: query.authorization_code /*|| query.code*/
      })
    );
    return true;
  } else if (query && query.logout === "OK") {
    window.location = "/";
  } else if (query && query.locale) {
    setCookie(Config.localeCookieName, query.locale);
    setOnLocalStorage(query.locale, "lang");
  } else if (hash) {
    [, hash] = hash.split("#");
    const params = {};
    hash.split("&").forEach(t => {
      const [k, v] = t.split("=");
      params[k] = v;
    });
  }
  return false;
};

// const checkURLProcess = (store) => {
//   let { hash, query } = store.getState().router.location;
//   if (query && query.process === "PAYMENT_PROCESS") {
//     /* CHECKING PAYMENT REDIRECTIONS */
//     // ("orderId=JMO20201000000&payload=Ab02b4c0%21BQABAgCoyLbBzRl5kHm2xsr9h3FfTr44GIZ6D8uA8QKJ9JnRx%2FLiLwnjYOawcKF9QqyMZHBSJ6FCnuvjDgbLswo2OvBiVnFwQzXZqHirhNjJqTDc9ELMdHZc93chQt8SwSPj5gWVfz6YEFtFr%2FsmJB5LZQNY7YnA%2BW7o%2BBn%2BehqIt87Rqh2i2xV7n9QaZChciNE4lFy%2FPjQPjLsdg1OsKFbmOlb1FPumILPigymMdNsjDzHwKVNWa2BochNLKYxchiYZPJrJXs6elCBO11dNu0b2EQK0SYRp%2FrRDYPbI%2BCBgfESle8x%2FwocTCsS5lMTNJYvX4Y%2B9hFfyrF3BOCeIEvCq%2Bd9PVktwxiwrtr9RGLaujIwKFj0doNe2VI5JqX9LB4A91XMG5rOD24oyHSAkmhpn9qHsVsS9ePv0%2F%2BjmvJ6NWksSanBdxi2HndLUjrDZnzdluC4e90vuP2CaPmuQz1OhyVG1G7S6q8KnPqu0uXnKdlsyrHRBhWmCCPkBhxeZ2jTuR2RGdU9phQHzxV%2Fi4I6%2Fl0ek09oXTqq%2BggQ7JdJmTq%2FNe%2BrATS0Vm2%2FVlFrzsnwgZqsIN1289gDC7k1DT56qqKqwQfx5UgLzaBd5poCmP%2FSj%2FbevmjDADqAaK7JSQU2Qg7PWSeoyWxrUlk3hTPDDh%2BUTFwifEbpqdTDf8%2FDtW0VrSRDvYp5rJbHxO1%2Be5dIWRQwnAEp7ImtleSI6IkFGMEFBQTEwM0NBNTM3RUFFRDg3QzI0REQ1MzkwOUI4MEE3OEE5MjNFMzgyM0Q2OERBQ0M5NEI5RkY4MzA1REMiffcXJA7oa3MU%2BGB4g9rvbazWz3%2BtScbOfpfAdjJni8wR7vfEE0lOf6NZ1UfgFshJoco39LlavjSvgFcM%2B5%2BavvdTqhzXTARogrqjdpKWs%2FCGrr%2FalZ4Sl%2FQZ3jeCkGWVg5oIOHk%2BxJ6EN0LYuxj%2BNZCHjaun6kb08GrsHmvZx9VylgMrt1mGeX4BkwLYprFykzzgjPcqNpvR56SFi%2BlSOcCKJmVjpjDCJ%2BOnCjg%2ByidkXX94mPlY07kC1Z0xAJuPx2rV%2B476mWNAjSu4Wn8YsR0uWTJMCf8knaxc2ZY%3D&type=complete&resultCode=authorised");
//     checkUser(store);
//     store.dispatch(paymentAction({ query }));
//     return true;
//   }
//   return false;
// };

const authMiddleware = store => next => action => {
  if (action.type === APP_START) {
    checkUserLocale();
    if (!checkQueryParams(store)) {
      checkUser(store);
    }
    removeFromLocalStorage(null, "user_buffer");
  } else if (action.type === AUTHORIZE_DONE) {
    const { user, token } = action.data;
    if (user && token) {
      UserDataLoad(user.providerId);
      setOnLocalStorage(action.data);
    } else {
      UserDataLoad();
      console.error("ERROR", "Session not saved");
    }
  } else if (action.type === USERINFO_LOADED) {
    const user = action.data;
    if (user) {
      setOnLocalStorage({ user });
      // checkURLProcess(store);
    } else {
      console.error("ERROR", "Session not saved");
    }
  } else if (action.type === SET_ERROR) {
    // next(action);
    // store.dispatch(push("/error"));
  } else if (action.type === ROUTER_LOCATION_CHANGE) {
    // window.scrollTo(0, 0);
    // if (
    //   action.payload &&
    //   action.payload.location &&
    //   action.payload.location.pathname &&
    //   action.payload.location.pathname.toLowerCase() !== "/forbidden" &&
    //   action.payload.location.pathname.toLowerCase() !== "/error" &&
    //   action.payload.location.pathname.toLowerCase() !== "/logout/do"
    //   // action.payload.location.pathname.toLowerCase() !== "/admin/auth"
    // ) {
    //   checkUserLocale();
    //   checkUser(store);
    // }
    window.scrollTo(0, 0);
    if (
      action.payload &&
      action.payload.location &&
      action.payload.location.pathname
    ) {
      checkUserLocale();
      const currentPath = getFlatRouteList(routes).find(v =>
        matchPath(action.payload.location.pathname, {
          path: v.path,
          exact: true
        })
      );

      checkUser(
        store,
        (currentPath && currentPath.type === type.SECURED) || false
      );
    }
  }

  next(action);
};

export default authMiddleware;
