import { get, has, keys } from "lodash";
import {
  MY_MEMBERSHIP_ERROR,
  MY_MEMBERSHIP_LOADED,
  MY_MEMBERSHIP_LOADING
} from "../actions/mymembership.actions";
import { reducerFromMap } from "../libs/helpers/reducers";

const initialState = {
  loading: true
};

const reducer = {
  [MY_MEMBERSHIP_LOADING]: (state, actions) => ({
    ...(has(state, "data.detail") ? { data: state.data } : {}),
    loading: true
  }),
  [MY_MEMBERSHIP_LOADED]: (state, actions) => {
    const availableKeys = keys(get(actions.data, "response", []));
    // const data = has(actions.data, "response.detail", false)
    //   ? { detail: actions.data.response.detail }
    //   : has(actions.data, resoponse) && {
    //       held: get(actions.data, "response.held", []),
    //       managed: get(actions.data, "response.managed", []),
    //       redeemed: get(actions.data, "response.redeemed", []),
    //       gifted: get(actions.data, "response.gifted", []),
    //     };
    const data = availableKeys.includes("detail")
      ? { detail: actions.data.response.detail }
      : (availableKeys.includes("held") ||
          availableKeys.includes("managed") ||
          availableKeys.includes("redeemed") ||
          availableKeys.includes("gifted")) && {
          held: get(actions.data, "response.held", []),
          managed: get(actions.data, "response.managed", []),
          redeemed: get(actions.data, "response.redeemed", []),
          gifted: get(actions.data, "response.gifted", [])
        };
    return {
      ...state,
      loading: false,
      error: null,
      data
    };
  },
  [MY_MEMBERSHIP_ERROR]: (state, actions) => ({
    ...state,
    error: actions.error,
    loading: false
  })
};
export default reducerFromMap(reducer, initialState);
