import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import "./TableInfo.scss";
import { Col, Container, Row } from "../Grid";
import Button from "../Button/Button";

import { ReactComponent as CircleCheck } from "../../assets/icons/circle-check.svg";
import { ReactComponent as CircleX } from "../../assets/icons/circle-x.svg";
import Localize from "../../libs/localization";
import Carousel from "../Carousel/Carousel";

export default ({
  onClickBuy,
  onClickGift,
  onClickSeeMore,
  onClickDetail,
  isMobile = false,
  mobileRatio = 1.2
}) => {
  const text = Localize("TABLE", [], false, false, true);
  const { GROUPS } = text || {};
  const [showMoreCode, setShowMoreCode] = useState(null);
  const [currentGroup, setCurrentGroup] = useState("ADULT");
  const [tableInfo, setTableInfo] = useState(
    isMobile
      ? {
          HEADER: [
            ..._.get(text, "ADULT.HEADER", []),
            ..._.get(text, "KIDS.HEADER", [])
          ],
          ROWS: [
            ..._.get(text, "ADULT.ROWS", []),
            ..._.get(text, "KIDS.ROWS", [])
          ],
          FOOTER: [
            ..._.get(text, "ADULT.FOOTER", []),
            ..._.get(text, "KIDS.FOOTER", [])
          ]
        }
      : text.ADULT
  );
  const { HEADER, ROWS, FOOTER } = tableInfo || {};
  useEffect(() => {
    if (!isMobile && !!showMoreCode) {
      setShowMoreCode(null);
    } else {
      if (showMoreCode) {
        // disable scroll on body
        document.body.style.overflow = "hidden";
      } else {
        // enable scroll on body
        document.body.style = "";
      }
    }
  }, [isMobile, showMoreCode]);

  useEffect(() => {
    if (!isMobile) setTableInfo(text[currentGroup]);
  }, [currentGroup, text, isMobile]);

  const seeMoreModal = useCallback(() => {
    if (!showMoreCode || !HEADER || !ROWS || !FOOTER) {
      return null;
    }
    const h = HEADER.find(h => h.CODE === showMoreCode) || null;
    const rs = ROWS.filter(r =>
      _.find(r.CODES, c => h.CODE === _.get(c, "code", c))
    );
    if (!rs.length || !h) {
      return null;
    }
    return (
      <div className="j-carousel-info-card modal">
        <div className="modal-content">
          <div className="j-carousel-info-card-title animation fadein-left">
            <div className="j-carousel-info-card-title-content">{h.TITLE}</div>
          </div>
          <div className="j-carousel-info-card-rows animation fadein-up">
            {rs.map((r, i) => (
              <div className="j-carousel-info-card-row">
                <div className="j-carousel-info-card-row-benefit-icon">
                  <CircleCheck />
                </div>
                <div className="j-carousel-info-card-row-benefit-title">
                  {r.TITLE}
                  {r.SUBTITLE && (
                    <div className="j-carousel-info-card-row-benefit-subtitle">
                      {r.SUBTITLE}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="modal-buttons">
            <div className="modal-button">
              <Button
                className={"pointer"}
                primary={false}
                noBorder={true}
                hideHover={true}
                textUnderlineHover={true}
                size={"L"}
                label={Localize("COMMON.BACK")}
                onClick={e => {
                  e.stopPropagation();
                  setShowMoreCode(null);
                }}
                role="link"
              />
            </div>
            <div className="modal-button">
              <Button
                className={"pointer"}
                primary={true}
                noBorder={false}
                hideHover={false}
                textUnderlineHover={true}
                size={"L"}
                label={Localize("TABLE.ACTIONS.SEE_MORE")}
                onClick={() => {
                  document.body.style = "";
                  onClickDetail(h.CODE);
                }}
                role="link"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [showMoreCode, HEADER, ROWS, FOOTER, onClickDetail]);

  const renderCodeValue = useCallback((r, h) => {
    const code = _.find(r.CODES, c => h.CODE === _.get(c, "code", c));
    if (!code) {
      return <CircleX />;
    }
    if (_.has(code, "value")) {
      return <span className="benefit-value">{code.value}</span>;
    } else {
      return <CircleCheck />;
    }
  }, []);

  if (HEADER && ROWS) {
    if (isMobile) {
      return (
        <>
          <Carousel
            className="j-carousel-info"
            slidesPerView={mobileRatio}
            creativeEffect
            navigationHidden
          >
            {_.orderBy(HEADER, "ORDER", "asc")
              .filter((_, i) => i > 0)
              .map((h, i) => (
                <div
                  key={i}
                  className={[
                    "j-carousel-info-card",
                    !!h.CODE ? "white" : "transparent"
                  ].join(" ")}
                >
                  <div className="j-carousel-info-card-title">
                    <div className="j-carousel-info-card-title-content">
                      {h.TITLE}
                    </div>
                  </div>
                  {ROWS.map(
                    (r, j) =>
                      _.size(r.CODES) > 0 &&
                      _.find(r.CODES, c => h.CODE === _.get(c, "code", c)) && (
                        <div key={j} className="j-carousel-info-card-row">
                          <div className="j-carousel-info-card-row-benefit-icon">
                            <CircleCheck />
                          </div>
                          <div className="j-carousel-info-card-row-benefit-title">
                            {r.TITLE}
                            {/* {r.SUBTITLE && <div className="j-carousel-info-card-row-benefit-subtitle">{r.SUBTITLE}</div>} */}
                          </div>
                        </div>
                      )
                  )}
                  {!!h.CODE && (
                    <div className="j-carousel-info-card-button">
                      <Button
                        key={i}
                        className={"pointer"}
                        primary={false}
                        noBorder={true}
                        hideHover={true}
                        textUnderlineHover={true}
                        size={"L"}
                        label={Localize("TABLE.ACTIONS.MORE_DETAILS")}
                        onClick={() => setShowMoreCode(h.CODE)}
                        role="link"
                      />
                    </div>
                  )}
                </div>
              ))}
          </Carousel>
          {seeMoreModal()}
        </>
      );
    }
    return (
      <Container className={"j-tableInfo pl-md-3 p-lg-5"}>
        <div className={"table"}>
          <div className={"row head"}>
            {_.orderBy(HEADER, "ORDER", "asc").map((h, i) => (
              <div key={i} className={`cell ${i > 0 ? "" : "big"} products`}>
                <div
                  className={
                    i > 0 ? "card-name padding-x" : "advantages-label floatLeft"
                  }
                >
                  {_.size(GROUPS) > 1 && (
                    <div className="group-switch">
                      {i === 0 &&
                        _.map(GROUPS, (g, k) => (
                          <Button
                            key={k}
                            className={"pointer"}
                            primary={currentGroup === k}
                            noBorder={true}
                            hideHover={true}
                            textUnderlineHover={false}
                            size={"L"}
                            label={g}
                            role="link"
                            onClick={() => setCurrentGroup(k)}
                          />
                        ))}
                    </div>
                  )}
                  {h.TITLE}
                </div>
                {h.CODE && (
                  <div className={"box-button"}>
                    {h.GIFT && (
                      <Button
                        className={"pointer"}
                        size={"S"}
                        transparent={true}
                        label={Localize("TABLE.ACTIONS.GIFT")}
                        onClick={() => onClickGift(h.CODE)}
                        role="link"
                      />
                    )}
                    {h.BUY && (
                      <Button
                        className={"pointer"}
                        primary={true}
                        size={"S"}
                        label={Localize("TABLE.ACTIONS.BUY")}
                        onClick={() => onClickBuy(h.CODE)}
                        role="link"
                      />
                    )}
                    {h.SCROLL && (
                      <Button
                        className={"pointer"}
                        primary={true}
                        size={"S"}
                        label={Localize("TABLE.ACTIONS.SEE_MORE")}
                        onClick={() => onClickSeeMore(h.CODE)}
                        role="link"
                      />
                    )}
                    {h.DETAIL && (
                      <Button
                        className={"pointer"}
                        primary={false}
                        noBorder={true}
                        hideHover={true}
                        textUnderlineHover={true}
                        size={"M"}
                        label={Localize("TABLE.ACTIONS.SEE_MORE")}
                        onClick={() => onClickDetail(h.CODE)}
                        role="link"
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          {ROWS.map((r, j) => {
            const hasCodes = _.size(r.CODES) > 0;
            return (
              <div
                key={j}
                className={hasCodes ? "row separatorTop" : "row group"}
              >
                <div className={"cell"}>
                  <div className={"title-benefits floatLeft"}>{r.TITLE}</div>
                  {r.SUBTITLE && (
                    <div className={"label-benefits floatLeft"}>
                      {r.SUBTITLE}
                    </div>
                  )}
                </div>
                {hasCodes &&
                  _.orderBy(
                    _.filter(HEADER, h => h.CODE),
                    "ORDER",
                    "asc"
                  ).map((h, i) => (
                    <div
                      key={i}
                      className={`cell center separatorLeft ${
                        _.find(r.ASTERISKS, c => c === h.CODE) ? "asterisk" : ""
                      }`}
                    >
                      {renderCodeValue(r, h)}
                    </div>
                  ))}
              </div>
            );
          })}
        </div>
        {(FOOTER || []).map((f, i) => (
          <Row key={i}>
            <Col
              size={{ xs: 24, sm: 24, md: 18, lg: 15 }}
              style={{
                color: "var(--light-grey-bg)",
                fontSize: "0.8em"
              }}
              className="mt-4 p-0"
            >
              {f}
            </Col>
          </Row>
        ))}
      </Container>
    );
  }
  return null;
};
