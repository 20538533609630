// import { Layout, Button } from "antd";
import React, { Component } from "react";
import _ from "lodash";

import Localize from "../../libs/localization";
import { ReactComponent as JLogo } from "../../assets/icons/j_logo.svg";
import { side } from "../../assets/images";

import "./system.style.scss";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import { connect } from "react-redux";
import { goTo } from "../../actions/app.actions";
import { redeemGiftApi, activateGiftApi } from "../../api/customer.api";
import {
  getAge,
  refreshPage,
  scrollToTop,
  getImageBackground
} from "../../libs/utils";
import VerifyData from "../../components/VerifyData/VerifyData";
import { Container, Row, Col } from "../../components/Grid";
import { PageView } from "../../libs/helpers/gtm";
import { checkGift } from "../../api/membership.api";

const MIN_AGE_MANAGER = 16;
class RedeemGift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      step: 0,
      selected: "BUY_CARD"
    };
  }

  componentDidMount() {
    // document.body.style = "overflow: hidden";

    const { token, user, goTo } = this.props;
    const { search } = this.props.location;

    const URLparams = new URLSearchParams(search);
    const code = URLparams.get("code");
    const giftCode = URLparams.get("giftCode");

    const headers = { Authorization: `Bearer ${token}` };

    if (token && code && giftCode && user) {
      /* UNCOMMENT THIS TWO LINES TO ENABLE IMMEDIATE REDEEM */
      // this._redeemGiftProcess({ headers, code, query: search });
      // PageView();
      /* COMMENT THIS TO HIDE REDEEM ALERT */
      this.setState({ loading: true }, () => {
        if (getAge((user || {}).dob) >= MIN_AGE_MANAGER) {
          console.log("checking");
          checkGift({ headers, code, giftCode })
            .then(({ response, ok }) => {
              const {
                manager,
                holder,
                code: codeError,
                label: labelError
              } = response;
              if (ok && manager === false && holder === false && response) {
                this.setState(
                  {
                    redeemParams: { headers, code, query: search },
                    step: 0,
                    loading: false
                  },
                  () => PageView()
                );
              } else if (!ok) {
                this.setState({
                  loading: false,
                  error: {
                    code: codeError,
                    label: Localize(
                      "REDEEM_GIFT.GENERIC_ERROR",
                      [labelError],
                      true
                    )
                  }
                });
              } else {
                this.setState(
                  {
                    loading: false
                  },
                  () => {
                    PageView();
                    this._redeemGiftProcess({ headers, code, query: search });
                  }
                );
              }
            })
            .catch(() => {
              this.setState({
                error: { label: Localize("ERROR.UNEXPECTED") },
                loading: false
              });
            });
        } else {
          this.setState({
            error: {
              title: Localize("ERROR.MANAGER_WRONG_ERROR_TITLE"),
              label: Localize("ERROR.MANAGER_WRONG_ERROR")
            },
            loading: false
          });
        }
      });
    } else {
      goTo("/");
    }
  }

  _redeemGiftProcess = ({ headers, code, query }) =>
    this.setState({ loading: true }, () =>
      redeemGiftApi({ headers, code, query })
        .then(res => {
          scrollToTop();
          const { user } = this.props;
          const { product, manager, status, membershipCode } =
            res.response || {};
          if (
            (res.ok || _.isEqual(_.get(manager, "id"), _.get(user, "id"))) &&
            product &&
            status === "ready"
          ) {
            this.setState({
              loading: false,
              step: 1,
              code,
              product: _.pick(product, [
                "sku",
                "tags",
                "name",
                "maxHolderAge",
                "minHolderAge"
              ]),
              membershipCode,
              userParam: this._getCurrentUserParams(),
              error: null
            });
          } else if (
            res.ok &&
            _.isEqual(_.get(manager, "id"), _.get(user, "id")) &&
            status !== "ready"
          ) {
            this.setState({
              loading: false,
              error: { label: Localize("ERROR.MEMBERSHIP_ALREADY_REDEEMED") }
            });
          } else {
            this.setState({
              loading: false,
              error: res.response
            });
          }
        })
        .catch(() =>
          this.setState({
            error: { label: Localize("ERROR.UNEXPECTED") },
            loading: false
          })
        )
    );

  _activateMembership = ({ headers, code, holderData = null }) =>
    this.setState({ loading: true }, () =>
      activateGiftApi({ headers, code, holderData })
        .then(res => {
          const { goTo } = this.props;
          if (res.ok) {
            this.setState(
              {
                loading: true,
                error: false,
                step: 0
              },
              () => goTo("/thankyou")
            );
          } else {
            this.setState(
              { loading: false, error: res.response, step: 0 },
              () => scrollToTop()
            );
          }
        })
        .catch(() =>
          this.setState(
            { error: { label: Localize("ERROR.UNEXPECTED") } },
            () => scrollToTop()
          )
        )
    );

  _getCurrentUserParams = () => {
    const { user } = this.props;
    let newUser = { ...user };
    return newUser;
  };

  _getHolderData = userParam =>
    userParam.firstName &&
    userParam.lastName &&
    userParam.dob &&
    // userParam.birthProvince &&
    userParam.birthCountry &&
    // userParam.birthCity &&
    userParam.gender && {
      firstName: userParam.firstName,
      lastName: userParam.lastName,
      dob: userParam.dob,
      phone: userParam.phone,
      email: userParam.email,
      birthProvince: userParam.birthProvince,
      birthProvinceCode: userParam.birthProvinceCode,
      birthCountry: userParam.birthCountry,
      birthCountryCode: userParam.birthCountryCode,
      birthCity: userParam.birthCity,
      birthCityCode: userParam.birthCityCode,
      gender: userParam.gender
    };

  _onChangeSelect = item => {
    this.setState({
      userParam: item === "BUY_CARD" ? this._getCurrentUserParams() : {},
      editable: item !== "BUY_CARD"
    });
    this.setState({ editing: item !== "BUY_CARD" });
    this.setState({ selected: item });
  };

  _setValuesInForm = ({ values, errors, completed }) => {
    this.setState({
      formValues: { values, errors, completed },
      userParam: values
    });
  };

  // _getImageBackground = (membCode, lite = false) => {
  //   return lite
  //     ? _.get(side, `${membCode}_lite`) || side.alt
  //     : _.get(side, membCode) || side.alt;
  // };

  componentWillMount() {
    // document.body.style = "";
  }

  render() {
    const {
      step,
      loading,
      error,
      userParam,
      selected,
      editing,
      code,
      product,
      redeemParams,
      membershipCode
    } = this.state;
    const { user, token, goTo } = this.props;

    if (!user || !token) {
      return <Loading />;
    } else if (loading) {
      return <Loading />;
    } else if (step === 0 || error) {
      return (
        <div className="system-error animation fadein-up fast">
          <div className="system-page">
            <div className="system-box animation fadein fast">
              <div className="logo">
                <JLogo />
              </div>
              {loading ? (
                <Loading fixed={false} />
              ) : error ? (
                <>
                  <div className="message-head">
                    {error.title || Localize("ERROR.TITLE", [error.status])}
                  </div>
                  {
                    <div className="message-error animation fadein fast">
                      {error.label || Localize("ERROR.UNEXPECTED")}
                    </div>
                  }
                  {error.code && (
                    <div className="code-error animation fadein fast">
                      {error.code}
                    </div>
                  )}
                  <div className="action">
                    <Button
                      primary={true}
                      onClick={() => goTo("/")}
                      size={"S"}
                      label={Localize(`ERROR.GET_BACK_HOME`)}
                    />
                    <Button
                      onClick={() => refreshPage()}
                      style={{ marginTop: 20 }}
                      size={"S"}
                      label={Localize(`ERROR.TRY_AGAIN`)}
                    />
                  </div>
                </>
              ) : (
                <>
                  {/* COMMENT THIS TO HIDE REDEEM ALERT */}
                  <div className="message-head">
                    {Localize("REDEEM_GIFT.TITLE", user)}
                  </div>
                  <div className="message-error animation fadein fast">
                    {Localize(
                      "REDEEM_GIFT.DESCRIPTION",
                      { ...user, ...redeemParams },
                      true
                    )}
                  </div>
                  <div className="action">
                    <Button
                      onClick={() => this._redeemGiftProcess(redeemParams)}
                      size={"M"}
                      primary={true}
                      label={Localize(`REDEEM_GIFT.CONFIRM`)}
                    />
                    <Button
                      style={{ marginTop: 20 }}
                      onClick={() => goTo("/")}
                      size={"M"}
                      label={Localize(`ERROR.GET_BACK_HOME`)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <Container className={"j-buy-product"}>
          <Row>
            <Col size={12} className={"p-0 d-sm-none image-left"}>
              <div
                className="fixed-cont"
                style={{
                  backgroundImage: `url(${getImageBackground(product)})`
                }}
                // style={{ backgroundImage: `url(${side.alt})` }}
              />
            </Col>
            <Col
              size={{ md: 24, sm: 24, lg: 12 }}
              className={"pl-md-0 p-sm-0 px-xs-0 content-right"}
              style={{ marginTop: 80 }}
            >
              <div
                className={
                  "view-container p-md-5 p-sm-3 px-sm-0 px-xs-0 pt-sm-0 pt-xs-0"
                }
              >
                <VerifyData
                  codeName={"membership"}
                  token={token}
                  userInSession={user}
                  user={selected && userParam}
                  updating={false}
                  selected={selected}
                  editing={editing}
                  editable={selected === "BUY_CARD" ? false : true}
                  onClick={() => {
                    const holderData = _.isEqual(selected, "BUY_ON_BEHALF")
                      ? this._getHolderData(userParam)
                      : undefined;
                    !error &&
                      this._activateMembership({
                        headers: { Authorization: `Bearer ${token}` },
                        code,
                        holderData
                      });
                  }}
                  onChange={this._onChangeSelect}
                  onFormChange={this._setValuesInForm}
                  product={product || {}}
                  redeemGiftProcess={true}
                  showTerms={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = ({ auth, checkout }) => ({
  user: auth.user,
  token: auth.token
});

const mapDispatchToProps = { goTo };

export default connect(mapStateToProps, mapDispatchToProps)(RedeemGift);
