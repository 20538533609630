import { reducerFromMap } from "../libs/helpers/reducers";

import { SET_SIDEBAR_STATUS, SET_ERROR } from "../actions/app.actions";

const initialState = {
  sidebarCollapsed: false
};

const reducer = {
  [SET_SIDEBAR_STATUS]: (state, actions) => {
    return {
      ...state,
      sidebarCollapsed: actions.status
    };
  },
  [SET_ERROR]: (state, actions) => ({
    ...state,
    error: actions.error
  })
};

export default reducerFromMap(reducer, initialState);
