import { reducerFromMap } from "../libs/helpers/reducers";
import {
  LIST_COUNTRIES_LOADED,
  LIST_ERROR,
  LIST_LOADING,
  LIST_PROVINCES_LOADED
} from "../actions/utils.actions";

const initialState = {
  countries: [],
  provinces: []
};

const reducer = {
  [LIST_LOADING]: (state, actions) => ({
    ...state,
    loading: true
  }),
  [LIST_PROVINCES_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    provinces: actions.data
  }),
  [LIST_COUNTRIES_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    countries: actions.data
  }),
  [LIST_ERROR]: (state, actions) => ({
    ...state,
    loading: false
  })
};
export default reducerFromMap(reducer, initialState);
