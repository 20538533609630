import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _, { toUpper } from "lodash";
import moment from "moment";

import Config from "../../Config";

import "./VerifyData.scss";
import { Col, Row } from "../Grid";
import Localize, { getCurrentLocale } from "../../libs/localization";
import RadioButton from "../RadioButton/RadioButton";
import EditableCard from "../EditableCard/EditableCard";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import DatePicker from "../DatePicker/DatePicker";
import SelectBox from "../SelectBox/SelectBox";
import Form from "../Form/Form";
import { getAge } from "../../libs/utils";
import CheckMark from "../CheckMark/CheckMark";
import Loading from "../Loading/Loading";
import {
  getCountryList,
  getProvinceList,
  getMunicipalityListByProvince,
  isValidCF
} from "../../api/jservicesApi";

import * as sizes from "../../variantSizes.json";

const genderOptions = [
  { label: "F", value: "F" },
  { label: "M", value: "M" }
  // { label: "O", value: "O" },
];

const MIN_AGE_BUY_ON_BEHALF = 18;
const MIN_AGE_BUY = 16;

const _getRequiredFields = (
  process = "buy",
  productTag,
  birthCountryCode,
  groupedProducts = []
) => {
  let required = ["firstName", "lastName", "dob", "gender", "birthCountryCode"];
  if (
    !birthCountryCode ||
    (birthCountryCode && birthCountryCode.toUpperCase() === "IT")
  ) {
    required.push("birthCity");
    required.push("birthProvince");
    required.push("birthProvinceCode");
    if (productTag === "jcard") required.push("taxCode");
  }
  if (process === "coupon") {
    required.push("couponCode");
  }
  groupedProducts.map(gp => {
    if (_.size(gp.variants) > 0) {
      required.push(`variant-${gp.id}`);
    }
    return gp;
  });
  return required;
};

const VerifyData = ({
  process = "buy",
  token,
  codeName,
  userInSession = {},
  selected,
  user = {},
  onClick,
  onChange,
  editing,
  onFormChange,
  product,
  updating,
  redeemGiftProcess = false,
  upgradeProcess = false,
  showTerms = false
}) => {
  const [loading, setLoading] = useState({});
  const [flaggedTerm1, setFlaggedTerm1] = useState(false);
  const [flaggedTerm2, setFlaggedTerm2] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [options, setOptions] = useState([]);

  const [validCf, setValidCf] = useState(
    codeName === "jcard" && selected === "BUY_ON_BEHALF" ? false : true
  );
  const [validCfError, setValidCfError] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      setValidCf(
        codeName === "jcard" && selected === "BUY_ON_BEHALF" ? false : true
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const [internalEditing, setInternalEditing] = useState(editing);
  useEffect(() => {
    setInternalEditing(editing);
  }, [editing]);

  useEffect(() => {
    const newOptions = [];
    const disabled = typeof onChange !== "function";
    if (userInSession && userInSession.dob && product) {
      const age = getAge(userInSession.dob);
      if (
        age >= product.minHolderAge &&
        age <= (product.maxHolderAge || 255) &&
        age >= MIN_AGE_BUY
      ) {
        newOptions.push({
          id: "BUY_CARD",
          key: "BUY_CARD",
          value: "BUY_CARD",
          label:
            codeName === "membership"
              ? userInSession.firstName + " " + userInSession.lastName + ""
              : Localize("BUY_PRODUCT.BUY_CARD"),
          disabled
        });
      }
      if (age >= MIN_AGE_BUY_ON_BEHALF && product.minHolderAge <= MIN_AGE_BUY) {
        newOptions.push({
          id: "BUY_ON_BEHALF",
          key: "BUY_ON_BEHALF",
          value: "BUY_ON_BEHALF",
          label: `${Localize(
            codeName === "membership"
              ? "BUY_PRODUCT.BUY_ON_BEHALF_MEMBERSHIP"
              : "BUY_PRODUCT.BUY_ON_BEHALF"
          )} **`,
          subLabel:
            codeName === "membership" && !(redeemGiftProcess || upgradeProcess)
              ? `** ${
                  product.maxHolderAge < 100 && product.maxHolderAge > 0
                    ? Localize(
                        "BUY_PRODUCT.BUY_CARD_MEMBERSHIP_SUBLABEL_MAX_AGE",
                        {
                          maxHolderAge: product.maxHolderAge
                        }
                      ) + " "
                    : ""
                }${Localize("BUY_PRODUCT.BUY_CARD_MEMBERSHIP_SUBLABEL")}`
              : null,
          disabled
        });
      }
      setOptions(newOptions);
    }
  }, [userInSession, product, codeName, redeemGiftProcess, upgradeProcess]);

  useEffect(() => {
    if (!updating) {
      if (
        userInSession &&
        userInSession.dob &&
        product &&
        !_.isEmpty(options)
      ) {
        const age = getAge(userInSession.dob);
        if (
          age >= (product.minHolderAge || 0) &&
          age <= (product.maxHolderAge || 255) &&
          age >= MIN_AGE_BUY
        ) {
          onChange("BUY_CARD");
        } else if (
          age >= MIN_AGE_BUY_ON_BEHALF &&
          product.minHolderAge <= MIN_AGE_BUY
        ) {
          onChange("BUY_ON_BEHALF");
        }
      } else {
        onChange("BUY_ON_BEHALF");
      }
    }
  }, [options, userInSession, product, onChange]);

  if (_.isEmpty(product) || _.isEmpty(userInSession)) return <Loading />;
  return (
    <div className={"j-verify"}>
      <Form
        initialValues={(selected || "").indexOf("BUY_") >= 0 ? user : {}}
        onChange={onFormChange}
        required={_getRequiredFields(
          process,
          codeName,
          _.get(user, "birthCountryCode"),
          product.groupedProducts
        )}
      >
        {({ values, errors, completed, updateValue }) => (
          <>
            {process === "coupon" && !upgradeProcess && !redeemGiftProcess && (
              <>
                <Col size={24} className={"pl-md-0 p-sm-0 title-text"}>
                  {Localize("COUPON.TITLE")}
                </Col>
                <Col size={{ md: 12, xs: 24 }} className={"pl-md-0 p-sm-0"}>
                  <TextInput
                    placeholder="-"
                    name="couponCode"
                    required={true}
                    label={Localize("COUPON.COUPON_CODE")}
                    value={values.couponCode || errors.couponCode}
                    onBlur={value => null}
                    error={!!errors.couponCode}
                    errorLabel=""
                    onChange={value => {
                      let wasTimes = value.length === 6;
                      value = toUpper(value).replace(/([^A-Z0-9])/g, "");
                      if (value.length >= 10) {
                        value = value.substr(0, 10);
                      }
                      if (value.length > 5 || wasTimes) {
                        value = `${value.substr(0, 5)}-${value.substr(5)}`;
                      }
                      updateValue({
                        key: "couponCode",
                        value,
                        regex: /[A-Z0-9]{5}-[A-Z0-9]{5}/
                      });
                    }}
                  />
                  <div className="sub-label mt-2">
                    {Localize("COUPON.INSTRUCTIONS")}
                  </div>
                </Col>
                <Col size={24} className={"pl-md-0 p-sm-0 my-5"}>
                  <div className={"separator"} />
                </Col>
              </>
            )}
            <Col size={24} className={"pl-md-0 p-sm-0 title-text"}>
              {Localize("BUY_PRODUCT.OWNER")}
            </Col>
            {!upgradeProcess && (
              <>
                <Col size={24} className={"pl-md-0 p-sm-0"}>
                  <RadioButton
                    emptyMsg={Localize("BUY_PRODUCT.MIN_MAX_AGE_NOT_OK")}
                    selected={selected}
                    options={options}
                    onChange={item => {
                      if (typeof onChange === "function") {
                        setInternalEditing(item === "BUY_CARD" ? false : true);
                        onChange(item);
                      }
                    }}
                  />
                </Col>
                <Col size={24} className={"pl-md-0 p-sm-0 my-3"}>
                  <div className={"separator"} />
                </Col>
              </>
            )}
            {!_.isEmpty(options) && (
              <>
                <Col size={24} className={"pl-md-0 p-sm-0"}>
                  {!upgradeProcess && (
                    <div className={"text-label mb-4"}>
                      {selected === "BUY_ON_BEHALF"
                        ? Localize("BUY_PRODUCT.OWNER_INFO")
                        : Localize("BUY_PRODUCT.YOUR_INFO")}
                    </div>
                  )}
                  <EditableCard
                    editing={internalEditing}
                    hideUI={true}
                    editFunction={() => {
                      setInternalEditing(true);
                    }}
                    cancelFunction={() => {
                      setInternalEditing(false);
                    }}
                  >
                    <Row>
                      <Col size={{ md: 12, xs: 12 }} className={"p-sm-0 pl-0"}>
                        <TextInput
                          placeholder="-"
                          name="firstName"
                          required={true}
                          label={Localize("COMMON.FIRSTNAME")}
                          value={values.firstName}
                          disabled={!internalEditing}
                          regex={
                            /([^A-Za-z \-'])|(-{2,})|(-')|('-)|( {2,})|('{2,})/g
                          }
                          onBlur={value =>
                            updateValue({
                              key: "firstName",
                              value: value.replace(
                                /^[^A-Za-z]|[^A-Za-z']$/g,
                                ""
                              )
                            })
                          }
                          onChange={value =>
                            updateValue({
                              key: "firstName",
                              value
                            })
                          }
                        />
                      </Col>
                      <Col size={{ md: 12, xs: 12 }} className={"p-sm-0 pl-0"}>
                        <TextInput
                          placeholder="-"
                          name="lastName"
                          required={true}
                          label={Localize("COMMON.LASTNAME")}
                          value={values.lastName}
                          disabled={!internalEditing}
                          regex={
                            /([^A-Za-z \-'])|(-{2,})|(-')|('-)|( {2,})|('{2,})/g
                          }
                          onBlur={value =>
                            updateValue({
                              key: "lastName",
                              value: value.replace(
                                /^[^A-Za-z]|[^A-Za-z']$/g,
                                ""
                              )
                            })
                          }
                          onChange={value =>
                            updateValue({ key: "lastName", value })
                          }
                        />
                      </Col>
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pl-0 p-sm-0 pt-3 pt-sm-3"}
                      >
                        <TextInput
                          placeholder="-"
                          name="email"
                          error={!!errors.email}
                          errorLabel={Localize("FORMS.ERRORS.EMAIL")}
                          label={Localize("COMMON.EMAIL")}
                          value={values.email || errors.email}
                          disabled={!internalEditing}
                          onChange={value =>
                            updateValue({
                              key: "email",
                              value,
                              regex: Config.regex.email
                            })
                          }
                        />
                      </Col>
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pt-3 pt-sm-3 p-sm-0 pl-0"}
                      >
                        <TextInput
                          placeholder="-"
                          name="phone"
                          label={Localize("COMMON.PHONE")}
                          value={values.phone}
                          type={!internalEditing ? "string" : "phone"}
                          disabled={!internalEditing}
                          onChange={value =>
                            updateValue({ key: "phone", value })
                          }
                        />
                      </Col>
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pt-3 pt-sm-3 p-sm-0 pl-0"}
                      >
                        <DatePicker
                          name={"dob"}
                          required={true}
                          value={
                            values.dob ? moment(values.dob).toDate() : null
                          }
                          label={Localize("COMMON.BIRTH_DATE")}
                          disabled={!internalEditing}
                          onChange={value => {
                            updateValue({
                              key: "dob",
                              value: moment(value).format("YYYY-MM-DD")
                            });
                            updateValue({
                              key: "birthProvince",
                              value: null
                            });
                            updateValue({
                              key: "birthProvinceCode",
                              value: null
                            });
                            updateValue({ key: "birthCity", value: null });
                            updateValue({
                              key: "birthCityCode",
                              value: null
                            });
                            setProvinceList([]);
                            setMunicipalityList([]);
                          }}
                          minDate={
                            selected === "BUY_ON_BEHALF" &&
                            moment()
                              .subtract(
                                (product.maxHolderAge || MIN_AGE_BUY) >=
                                  MIN_AGE_BUY
                                  ? MIN_AGE_BUY
                                  : product.maxHolderAge || MIN_AGE_BUY,
                                "YEARS"
                              )
                              .subtract(1, "YEARS")
                              .add(1, "DAYS")
                              .toDate()
                          }
                          maxDate={
                            selected === "BUY_ON_BEHALF" &&
                            moment()
                              .subtract(product.minHolderAge || 0, "YEARS")
                              .subtract(1, "DAYS")
                              .toDate()
                          }
                        />
                      </Col>
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pl-0 p-sm-0 pt-3 pt-sm-3"}
                      >
                        {/* {!internalEditing ? (
                      <TextInput
                        placeholder="-"
                        name="birthCountry"
                        required={true}
                        label={Localize("COMMON.NATIONALITY")}
                        value={values.birthCountry}
                        disabled={true}
                        onChange={(value) => {
                          updateValue({ key: "birthCountry", value });
                          updateValue({ key: "birthProvince", value: null });
                          updateValue({ key: "birthCity", value: null });
                        }}
                      />
                    ) : ( */}
                        <SelectBox
                          name="country"
                          required={true}
                          options={countryList}
                          autocomplete={true}
                          disabled={!internalEditing}
                          label={Localize("COMMON.NATIONALITY")}
                          value={values.birthCountry || values.birthCountryName}
                          fullObjectReturn={true}
                          filterList={false}
                          onChange={({ value, obj }) => {
                            updateValue({
                              key: "birthCountryName",
                              value: value
                            });
                            updateValue({
                              key: "birthCountry",
                              value: obj.nameIt
                            });
                            updateValue({
                              key: "birthCountryCode",
                              value: obj.iso2Code
                            });
                            updateValue({
                              key: "birthProvince",
                              value: null
                            });
                            updateValue({
                              key: "birthProvinceCode",
                              value: null
                            });
                            updateValue({ key: "birthCity", value: null });
                            updateValue({
                              key: "birthCityCode",
                              value: null
                            });
                            updateValue({ key: "taxCode", value: null });
                            setProvinceList([]);
                            setMunicipalityList([]);
                          }}
                          loading={loading["country"]}
                          onAutocompleteChange={async value => {
                            if (value && value.length >= 2) {
                              setLoading({ ...loading, country: true });
                              const res = await getCountryList(
                                token,
                                (value || "").toUpperCase()
                              );
                              setLoading({ ...loading, country: false });
                              setCountryList(res);
                            }
                            updateValue({
                              key: "birthProvince",
                              value: null
                            });
                            updateValue({
                              key: "birthProvinceCode",
                              value: null
                            });
                            updateValue({ key: "birthCity", value: null });
                            updateValue({
                              key: "birthCityCode",
                              value: null
                            });
                          }}
                        />
                        {/* )} */}
                      </Col>
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pl-0 p-sm-0 pt-3 pt-sm-3"}
                      >
                        {!internalEditing ||
                        (values.birthCountryCode &&
                          // values.birthCountry.toUpperCase() !== "ITALIA" &&
                          // values.birthCountry.toUpperCase() !== "ITALY" &&
                          values.birthCountryCode.toUpperCase() !== "IT") ? (
                          <TextInput
                            placeholder="-"
                            name="birthProvince"
                            label={Localize("COMMON.BIRTH_PROVINCE")}
                            value={values.birthProvince}
                            disabled={!values.birthCountry || !internalEditing}
                            onChange={value => {
                              updateValue({
                                key: "birthProvince",
                                value: value && value.toUpperCase()
                              });
                              updateValue({
                                key: "birthProvinceCode",
                                value: null
                              });
                              updateValue({ key: "birthCity", value: null });
                              updateValue({
                                key: "birthCityCode",
                                value: null
                              });
                            }}
                          />
                        ) : (
                          <SelectBox
                            name="subAdministrativeArea"
                            required={true}
                            options={provinceList}
                            autocomplete={true}
                            label={Localize("COMMON.BIRTH_PROVINCE")}
                            disabled={!values.birthCountry}
                            value={values.birthProvince}
                            fullObjectReturn={true}
                            onChange={({ obj }) => {
                              updateValue({
                                key: "birthProvince",
                                value: obj.name
                              });
                              updateValue({
                                key: "birthProvinceCode",
                                value: obj.acronym
                              });
                              updateValue({
                                key: "provinceCode",
                                value: obj.acronym
                              });
                              updateValue({
                                key: "provinceId",
                                value: obj.id
                              });
                              updateValue({
                                key: "state",
                                value: obj.region
                              });
                              updateValue({ key: "birthCity", value: null });
                              updateValue({
                                key: "birthCityCode",
                                value: null
                              });
                              setMunicipalityList([]);
                            }}
                            loading={loading["province"]}
                            onAutocompleteChange={async value => {
                              if (value && value.length > 2) {
                                setLoading({ ...loading, province: true });
                                try {
                                  const res = await getProvinceList(
                                    token,
                                    (value || "").toUpperCase(),
                                    values.dob
                                  );
                                  setLoading({ ...loading, province: false });
                                  setProvinceList(res);
                                } catch (e) {
                                  setLoading({ ...loading, province: false });
                                  setProvinceList([]);
                                }
                              }
                              updateValue({ key: "birthCity", value: null });
                              updateValue({
                                key: "birthCityCode",
                                value: null
                              });
                            }}
                          />
                        )}
                      </Col>
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pl-0 p-sm-0 pt-3 pt-sm-3"}
                      >
                        {!internalEditing ||
                        (values.birthCountryCode &&
                          values.birthCountryCode.toUpperCase() !== "IT") ? (
                          <TextInput
                            placeholder="-"
                            name="birthCity"
                            // disabled={!values.birthProvince || !values.birthCountry || !internalEditing}
                            label={Localize("COMMON.BIRTH_PLACE")}
                            value={values.birthCity}
                            disabled={!internalEditing}
                            onChange={value => {
                              updateValue({
                                key: "birthCity",
                                value: value && value.toUpperCase()
                              });
                              updateValue({
                                key: "birthCityCode",
                                value: null
                              });
                            }}
                          />
                        ) : (
                          <SelectBox
                            name="birthCity"
                            required={true}
                            options={municipalityList}
                            autocomplete={true}
                            filterList={true}
                            disabled={
                              !values.birthCountry || !values.birthProvince
                            }
                            label={Localize("COMMON.BIRTH_PLACE")}
                            value={values.birthCity}
                            fullObjectReturn={true}
                            onChange={({ obj }) => {
                              updateValue({
                                key: "birthCity",
                                value: obj.name
                              });
                              updateValue({
                                key: "birthCityCode",
                                value: obj.nationalCode
                              });
                            }}
                            loading={loading["municipality"]}
                            onAutocompleteChange={async value => {
                              if (value) {
                                setLoading({ ...loading, municipality: true });
                                try {
                                  const res = await getMunicipalityListByProvince(
                                    token,
                                    values.provinceId,
                                    values.dob
                                  );
                                  setLoading({
                                    ...loading,
                                    municipality: false
                                  });
                                  setMunicipalityList(res);
                                } catch (e) {
                                  setLoading({
                                    ...loading,
                                    municipality: false
                                  });
                                  setMunicipalityList([]);
                                }
                              }
                            }}
                          />
                        )}
                      </Col>
                      <Col
                        size={{ md: 12, xs: 12 }}
                        className={"pl-0 p-sm-0 pt-3 pt-sm-3"}
                      >
                        <SelectBox
                          name={"gender"}
                          required={true}
                          placeholder={Localize("COMMON.SELECT_ITEM")}
                          value={_.get(
                            _.find(
                              genderOptions,
                              { value: values.gender },
                              null
                            ),
                            "value"
                          )}
                          label={Localize("COMMON.GENDER")}
                          disabled={!internalEditing}
                          onChange={value => {
                            updateValue({ key: "gender", value });
                          }}
                          options={genderOptions}
                        />
                      </Col>
                      {codeName === "jcard" && (
                        <Col
                          size={{ md: 12, xs: 12 }}
                          className={"pt-3 pt-sm-3 p-sm-0 pl-0"}
                        >
                          <TextInput
                            placeholder="-"
                            name="taxCode"
                            required={
                              codeName === "jcard" &&
                              values.birthCountryCode === "IT"
                            }
                            label={Localize("COMMON.CF")}
                            value={values.taxCode}
                            error={!validCf}
                            errorLabel={validCfError}
                            disabled={!internalEditing}
                            onChange={value => {
                              if (values.birthCountryCode !== "IT") {
                                setValidCf(true);
                              }
                              updateValue({
                                key: "taxCode",
                                value: value.toUpperCase()
                              });
                            }}
                            onBlur={async () => {
                              if (
                                values.lastName &&
                                values.firstName &&
                                values.gender &&
                                values.dob &&
                                values.birthCity &&
                                values.taxCode &&
                                values.birthCountryCode === "IT"
                              ) {
                                const data = {
                                  surName: _.upperCase(values.lastName),
                                  name: _.upperCase(values.firstName),
                                  gender: values.gender,
                                  birthDate: moment(values.dob).format(
                                    "DD/MM/YYYY"
                                  ),
                                  birthCountry: _.upperCase(values.birthCity),
                                  birthCountryCode: _.upperCase(
                                    values.birthCountryCode
                                  ),
                                  fiscalCode: values.taxCode
                                };
                                const res = await isValidCF(token, data);
                                if (res) {
                                  // updateValue({ key: "taxCode", value: null });
                                  setValidCf(false);
                                  setValidCfError(res);
                                } else {
                                  setValidCf(true);
                                  setValidCfError(null);
                                }
                              } else {
                                setValidCf(true);
                                setValidCfError(null);
                              }
                            }}
                          />
                        </Col>
                      )}
                      <Col
                        size={{ md: 12, xs: 24 }}
                        className={"pt-3 pt-sm-3 p-sm-0 pl-0 px-sm-0 px-xs-0"}
                      >
                        {selected === "BUY_CARD" && !upgradeProcess && (
                          <a
                            className={
                              "mt-md-3 mt-sm-0 col-md-auto col-sm-24 p-0"
                            }
                            href={`${
                              Config.authBaseurl
                            }/${getCurrentLocale()}/${
                              Config.apis.oauth.addUserInfo
                            }?client_id=${
                              Config.oauthClientId
                            }&fields=taxcode&redirect_uri=${
                              window.location.href
                            }`}
                          >
                            <Button
                              style={{ width: "100%" }}
                              size={"L"}
                              label={Localize(`BUY_PRODUCT.EDIT_EXT`)}
                            />
                          </a>
                        )}
                      </Col>
                    </Row>
                  </EditableCard>
                </Col>
                <Col size={24} className={"p-0 my-3"}>
                  <div className={"separator"} />
                </Col>
                {showTerms && (
                  <Col size={24} className={"px-0 p-sm-0 mt-5 pl-xs-3 pl-sm-3"}>
                    <CheckMark
                      id={"CHECKOUT_TERM_MEMBERSHIP"}
                      label={Localize(
                        "CHECKOUT.CHECKOUT_TERMS.MEMBERSHIP.1",
                        [],
                        true
                      )}
                      value={flaggedTerm1}
                      onChange={value => setFlaggedTerm1(value)}
                      required={true}
                    />

                    <CheckMark
                      id={"CHECKOUT_TERM_ONLY_MEMBERSHIP"}
                      label={Localize(
                        "CHECKOUT.CHECKOUT_TERMS.MEMBERSHIP.2",
                        [],
                        true
                      )}
                      value={flaggedTerm2}
                      onChange={value => setFlaggedTerm2(value)}
                      required={true}
                    />
                  </Col>
                )}
                {codeName === "membership" &&
                  !redeemGiftProcess &&
                  !product.specialOf &&
                  process !== "coupon" && (
                    <>
                      <Col
                        size={24}
                        className={"px-0 p-sm-0 mt-5 pl-xs-3 pl-sm-3"}
                      >
                        <CheckMark
                          label={Localize(`BUY_PRODUCT.AUTORENEWAL`)}
                          value={values.autorenewal}
                          onChange={value =>
                            updateValue({ key: "autorenewal", value })
                          }
                        />
                      </Col>
                      <Col
                        size={24}
                        className={
                          "px-0 p-sm-0 mt-3 mb-5 sub-label pl-xs-3 pl-sm-3"
                        }
                      >
                        {Localize(`BUY_PRODUCT.AUTORENEWAL_INFO`)}
                      </Col>
                      <Col size={24} className={"p-0 my-3"}>
                        <div className={"separator"} />
                      </Col>
                    </>
                  )}
                {_.some(
                  _.get(product, "groupedProducts"),
                  gp =>
                    (gp.variants && gp.variants.length) ||
                    (gp.customizations && gp.customizations.length > 0) ||
                    gp.customizable,
                  false
                ) && (
                  <Row>
                    <Col
                      size={24}
                      className={"pl-md-0 p-sm-0 title-text-membership my-4"}
                    >
                      {Localize("BUY_PRODUCT.CUSTOM")}
                    </Col>
                    {_.map(_.get(product, "groupedProducts"), (gp, i) => (
                      <React.Fragment key={i}>
                        {gp.variants && gp.variants.length > 0 && (
                          <>
                            <Col
                              size={{ sm: 24, md: 12 }}
                              className={"pl-md-0 p-sm-0 my-4"}
                            >
                              <SelectBox
                                name={`variant-${gp.id}`}
                                required={true}
                                placeholder={"-"}
                                value={values[`variant-${gp.id}`]}
                                label={gp.name || Localize("BUY_PRODUCT.SIZE")}
                                onChange={value => {
                                  updateValue({
                                    key: `variant-${gp.id}`,
                                    value
                                  });
                                }}
                                options={_.map(gp.variants, (v, j) => ({
                                  label:
                                    gp.sizeType &&
                                    v.variantSize &&
                                    sizes.default
                                      ? _.get(
                                          _.find(
                                            _.get(
                                              _.find(
                                                sizes.default,
                                                s => s.value === gp.sizeType
                                              ),
                                              "variants"
                                            ),
                                            s => s.value === v.variantSize
                                          ),
                                          `name.${
                                            getCurrentLocale() === "it"
                                              ? "it"
                                              : "en"
                                          }`,
                                          v.variantSize
                                        )
                                      : v.name,
                                  value: v.id,
                                  disabled: !v.stockQty || v.stockQty === 0
                                }))}
                              />
                            </Col>
                          </>
                        )}
                        {_.map(gp.customizations, (c, k) => {
                          return (
                            <Col
                              key={k}
                              size={{ sm: 24, md: 12 }}
                              className={"pl-md-0 p-sm-0 my-4"}
                            >
                              <TextInput
                                placeholder={c.ruleLabel || "-"}
                                required={false}
                                name={`customization-${gp.id}-${c.id}`}
                                maxlength={
                                  (c.schema && c.schema.maxLength) || "5"
                                }
                                minlength={
                                  (c.schema && c.schema.minLength) || "0"
                                }
                                value={values[`customization-${gp.id}-${c.id}`]}
                                label={
                                  c.schema &&
                                  c.schema.type &&
                                  c.schema.type.toLowerCase() === "string"
                                    ? Localize(
                                        "BUY_PRODUCT.CUSTOMIZATION_TEXT",
                                        [c.schema.maxLength]
                                      )
                                    : Localize("BUY_PRODUCT.CUSTOMIZATION")
                                }
                                onChange={value =>
                                  updateValue({
                                    key: `customization-${gp.id}-${c.id}`,
                                    value:
                                      c.schema &&
                                      c.schema.type &&
                                      c.schema.type.toLowerCase() === "string"
                                        ? value.replace(/[^A-Za-z0-9]/g, "")
                                        : value
                                  })
                                }
                              />
                            </Col>
                          );
                        })}
                      </React.Fragment>
                    ))}
                    <Col size={24} className={"pl-md-0 p-sm-0 my-3"}>
                      <div className={"separator"} />
                    </Col>
                  </Row>
                )}

                {codeName === "membership" && selected === "BUY_ON_BEHALF" && (
                  <Row>
                    <Col
                      size={24}
                      className={"pl-md-0 p-sm-0 title-text-membership"}
                    >
                      {Localize("BUY_PRODUCT.DATA_MANAGER")}
                    </Col>
                    <Col size={24} className={"pl-md-0 p-sm-0"}>
                      <Row>
                        <Col
                          size={{ sm: 24, md: 12 }}
                          className={"pl-md-0 p-sm-0 pt-4 pt-sm-3"}
                        >
                          <TextInput
                            name="email_gestore"
                            error={!!errors.email_gestore}
                            errorLabel={Localize("FORMS.ERRORS.EMAIL")}
                            value={userInSession.email}
                            label={Localize("COMMON.EMAIL")}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          size={{ sm: 24, md: 12 }}
                          className={"pt-4 pt-sm-3 px-sm-0"}
                        >
                          <TextInput
                            name="phone_gestore"
                            type="number"
                            value={userInSession.phone}
                            label={Localize("COMMON.PHONE")}
                            prefix="00"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col size={24} className={"p-0 my-3"}>
                      <div className={"separator"} />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col
                    size={24}
                    className={"pl-md-0 p-sm-0 button-bottom pt-sm-0 pt-xs-0"}
                  >
                    {codeName === "jcard" && (
                      <Col size={{ md: 8, xs: 24 }} className="p-0">
                        <Button
                          size={"XL"}
                          style={{ width: "100%" }}
                          label={Localize("BUY_PRODUCT.CONTINUE")}
                          disabled={!completed}
                          primary={true}
                          onClick={async () => {
                            if (
                              values.lastName &&
                              values.firstName &&
                              values.gender &&
                              values.dob &&
                              values.birthCity &&
                              values.taxCode &&
                              values.birthCountryCode === "IT" &&
                              selected === "BUY_ON_BEHALF"
                            ) {
                              const data = {
                                surName: _.upperCase(values.lastName),
                                name: _.upperCase(values.firstName),
                                gender: values.gender,
                                birthDate: moment(values.dob).format(
                                  "DD/MM/YYYY"
                                ),
                                birthCountry: _.upperCase(values.birthCity),
                                birthCountryCode: "IT",
                                fiscalCode: values.taxCode
                              };
                              const res = await isValidCF(token, data);
                              if (res) {
                                setValidCf(false);
                                setValidCfError(res);
                              } else {
                                setValidCf(true);
                                setValidCfError(null);
                                onClick();
                              }
                            } else {
                              onClick();
                            }
                          }}
                        />
                      </Col>
                    )}
                    {codeName === "membership" && !redeemGiftProcess && (
                      <Button
                        size={"XL"}
                        disabled={!completed}
                        label={
                          updating
                            ? Localize("BUY_PRODUCT.CONTINUE_SAVE_TO_CART")
                            : Localize("BUY_PRODUCT.CONTINUE_ADD_TO_CART")
                        }
                        primary={true}
                        onClick={onClick}
                      />
                    )}
                    {codeName === "membership" && redeemGiftProcess && (
                      <Button
                        size={"XL"}
                        disabled={
                          !(
                            completed &&
                            (showTerms ? flaggedTerm1 && flaggedTerm2 : true)
                          )
                        }
                        label={Localize("COMMON.CONFIRM")}
                        primary={true}
                        onClick={onClick}
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

VerifyData.propTypes = {
  codeName: PropTypes.string,
  selected: PropTypes.string,
  // user = {},
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // editing,
  // editable,
  onFormChange: PropTypes.func.isRequired,
  onFormCustomizationChange: PropTypes.func
  // shirt_values = [],
  // customizationValues = {}
};

export default VerifyData;
