import React, { Component } from "react";

import { goTo } from "../../actions/app.actions";
import { connect } from "react-redux";
import { Col, Container, Row } from "../../components/Grid";
import AddressSelection from "../../components/AddressSelection/AddressSelection";
import Config from "../../Config";
import _, { get, isObject, set } from "lodash";
import {
  paymentMethodsAction,
  paymentMethodsCustomerAction,
  createBillingAddressAction,
  createBillingAddressSilentAction,
  getCartContentAction,
  redeemVoucherAction,
  paymentMethodDeleteAction
} from "../../actions/cart.actions";
import { checkoutAction } from "../../actions/checkout.actions";
import {
  getCountriesAction,
  getProvincesAction
} from "../../actions/utils.actions";
import TextInput from "../../components/TextInput/TextInput";
import Localize, { getCurrentLocale } from "../../libs/localization";
import CheckMark from "../../components/CheckMark/CheckMark";
import CartBoxComponent from "../../components/CartBox/CartBox";

import "./checkout.styles.scss";
import Form from "../../components/Form/Form";
import SelectBox from "../../components/SelectBox/SelectBox";

import { ReactComponent as LongArrowLeft } from "../../assets/icons/long-arrow-left.svg";
import Button from "../../components/Button/Button";
import PaymentCardBox from "../../components/PaymentCardBox/PaymentCardBox";
import RadioButton from "../../components/RadioButton/RadioButton";
import { getCountryList } from "../../api/jservicesApi";

import paypal from "./../../assets/icons/paypal.svg";
import visa from "./../../assets/icons/visa.svg";
import mastercard from "./../../assets/icons/mastercard.svg";
import LabelText from "../../components/LabelText/LabelText";
import Loading from "../../components/Loading/Loading";
import { PageView } from "../../libs/helpers/gtm";
import { checkoutApi } from "../../api/checkout.api";
import { headersBuilder } from "../../libs/helpers/callApi";

import { ErrorPage } from "../../pages";
import { scrollToTop } from "../../libs/utils";

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      editing: false,
      formValues: {},
      countryList: [],
      checkoutLoading: false
    };
  }

  componentDidMount() {
    const {
      paymentMethodsAction,
      getCartContentAction,
      user,
      cart,
      goTo
    } = this.props;

    if (!user.dob || !user.taxCode) {
      const redirectUrl = `${Config.authBaseurl}/${getCurrentLocale()}/${
        Config.apis.oauth.addUserInfo
      }?client_id=${Config.oauthClientId}&fields=taxcode&redirect_uri=${
        window.location.href
      }`;
      PageView(redirectUrl);
      window.location = redirectUrl;
    } else {
      this.setState({ formValues: { ...user }, selected: null }, () => {
        PageView();
        scrollToTop();
        // const { search } = this.props.location;
        // if (search) {
        //   const params = new URLSearchParams(search);
        //   // address ID from AIC undefined select preferred option
        //   const selectedAddress = params.get("address_id")
        //     ? _.find(user.billingAddresses, ["addressId", params.get("address_id")])
        //     : _.find(user.billingAddresses, ["isPreferred", true], null);

        //   this.setState({ selected: _.get(selectedAddress, "id") }, () =>
        //     // createBillingAddressAction({
        //     //   data: selectedAddress,
        //     // })
        //     {
        //       if (selectedAddress) {
        //         this._callBillingAddress(selectedAddress);
        //       }
        //       scrollToTop();
        //     }
        //   );
        // } else if (cart && cart.billingAddress) {
        //   this._rebuildBillingAddress(cart.billingAddress);
        // }
      });

      // getCountriesAction();
      // getProvincesAction();
      paymentMethodsAction();
      // paymentMethodsCustomerAction();
      if (!cart) {
        getCartContentAction();
      } else if (
        cart &&
        (_.size(cart.items) === 0 || !this._canIContinue(cart.items))
      ) {
        goTo("/cart");
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { cart, goTo, user, location } = this.props;
    if (cart && !prevProps.cart) {
      if (_.size(cart.items) === 0 || !this._canIContinue(cart.items)) {
        goTo("/cart");
      } else {
        const { search } = this.props.location;
        if (search) {
          const params = new URLSearchParams(search);
          // address ID from AIC undefined select preferred option
          const selectedAddress = params.get("address_id")
            ? _.find(user.billingAddresses, [
                "addressId",
                params.get("address_id")
              ])
            : _.find(user.billingAddresses, ["isPreferred", true], null);

          this.setState({ selected: _.get(selectedAddress, "id") }, () =>
            // createBillingAddressAction({
            //   data: selectedAddress,
            // })
            {
              if (selectedAddress) {
                this._callBillingAddress(selectedAddress);
              }
              scrollToTop();
            }
          );
        } else if (cart && cart.billingAddress) {
          this._rebuildBillingAddress(cart.billingAddress);
        }
      }
    }

    // const { search } = location;

    // const URLparams = new URLSearchParams(search);

    // const address_id = URLparams.get("address_id");

    // if (address_id && user && !_.isEqual(user, prevProps.user)) {
    //   const addressOptions = _.filter(user.billingAddresses, (address) => {
    //     address.id = address.addressId;
    //     return address;
    //   });
    //   /*eslint eqeqeq:0*/
    //   const selectedAddress = _.find(addressOptions, (a) => a.id == address_id);
    //   this.setState(
    //     { selected: _.get(selectedAddress, "id") },
    //     () => selectedAddress && this._callBillingAddress(selectedAddress)
    //   );
    // }
    // if (!_.isEqual(prevProps.paymentMethods, paymentMethods)) {
    //   paymentMethodsCustomerAction();
    // }
  }

  _rebuildBillingAddress = billingAddress => {
    const { formValues } = this.state;
    this.setState({
      formValues: {
        ...formValues,
        invoiceWithVatNumber: billingAddress.invoiceWithVatNumber,
        vatNumber: billingAddress.vatNumber,
        language: billingAddress.language,
        receiverCode: billingAddress.receiverCode,
        emailPec: billingAddress.emailPec
      }
    });
    if (
      // billingAddress.addressId &&
      billingAddress.invoiceWithVatNumber
    ) {
      this.setState({ enableVat: true });
    }
  };

  locale = getCurrentLocale();

  _canIContinue = items => {
    let completed = true;
    _.map(items, (el, i) => {
      if (
        (!el.product.virtual ||
          _.some(el.product.groupedProducts, gp => !gp.virtual)) &&
        !el.shippingAddress
      ) {
        completed = false;
      }
    });
    return completed;
  };

  _addressEnrichmentCallback = () => {
    window.location = `${
      Config.authBaseurl
    }/${getCurrentLocale()}/address-choice?address_type=billing&client_id=${
      Config.oauthClientId
    }&redirect_uri=${Config.appBaseurl}/checkout`;
  };

  _setValuesInForm = ({ values, errors, completed }) => {
    this.setState({
      formValues: values
    });
  };

  _callBillingAddress = (address, silent = true) => {
    const {
      createBillingAddressAction,
      createBillingAddressSilentAction,
      cart
    } = this.props;
    const { formValues } = this.state;

    const data = {
      ...(address || cart.billingAddress),
      invoiceWithVatNumber: formValues.invoiceWithVatNumber,
      vatNumber: formValues.vatNumber,
      language: formValues.language,
      receiverCode: formValues.receiverCode,
      emailPec: formValues.emailPec
    };

    // ensuring data is filled
    data.firstName = data.firstName || formValues.firstName;
    data.lastName = data.lastName || formValues.lastName;
    data.taxCode = data.taxCode || formValues.taxCode;
    data.birthCountry = data.birthCountry || formValues.birthCountry;
    data.birthCountryCode =
      data.birthCountryCode || formValues.birthCountryCode;
    data.country = data.country || formValues.birthCountry;
    data.countryCode = data.countryCode || formValues.birthCountryCode;
    data.state = data.countryCode === "IT" ? data.state : "EE";

    if (silent) {
      createBillingAddressSilentAction({ data });
    } else {
      createBillingAddressAction({ data });
    }
  };

  _callBillingAddressDebounced = _.debounce(this._callBillingAddress, 1000);
  _callBillingAddressDebouncedShort = _.debounce(this._callBillingAddress, 500);

  _getRequiredFields = items => {
    const { formValues, enableVat } = this.state;

    let required = ["firstName", "lastName", "taxCode"];

    if (enableVat && formValues) {
      required.push(...["language", "vatNumber"]);
      if (formValues.language === "IT") {
        required.push(...["receiverCode", "emailPec"]);
      }
    }

    if (items) {
      if (this._termsJCard(items)) {
        required.push(
          ...[
            "CHECKOUT_TERM_JCARD_1",
            "CHECKOUT_TERM_JCARD_2",
            "CHECKOUT_TERM_JCARD_3"
          ]
        );
      }
      if (this._termsMembership(items)) {
        required.push("CHECKOUT_TERM_MEMBERSHIP");
      }
      if (this._termsOnlyMembership(items)) {
        required.push("CHECKOUT_TERM_ONLY_MEMBERSHIP");
      }
    }

    return required;
  };

  _termsJCard = items =>
    _.some(items, i => _.some(i.product.tags, t => t.code === "jcard"));

  _termsMembership = items =>
    _.some(items, i => _.some(i.product.tags, t => t.code === "membership"));

  _termsOnlyMembership = items =>
    _.some(items, i => _.some(i.product.tags, t => t.code === "membership")) &&
    !_.some(items, i => _.some(i.product.tags, t => t.code === "jcard"));

  render() {
    const {
      countries,
      provinces,
      goTo,
      user,
      token,
      cart,
      paymentMethods,
      paymentMethodDeleteAction,
      redeemVoucherAction,
      cartLoading
    } = this.props;

    const {
      checkoutLoading,
      paymentMethod,
      formValues,
      countryList,
      enableVat,
      countryLoading,
      apiError
    } = this.state;

    return (
      <Container className={"j-checkout"}>
        {cartLoading && <Loading />}
        {checkoutLoading && <Loading />}
        <Form
          onChange={this._setValuesInForm}
          initialValues={formValues}
          required={this._getRequiredFields(cart && cart.items)}
          // required={["nationality", "vatNumber", "sdi", "pec"]}
        >
          {({ values, errors, completed, updateValue }) => (
            <Row>
              <Col
                size={{ xs: 24, sm: 24, md: 24, lg: 12 }}
                offset={{ lg: 0, xl: 4 }}
              >
                <div className={"px-0"}>
                  <div
                    className={"goback-text d-sm-none my-4"}
                    onClick={() => goTo(`/cart`)}
                  >
                    <LongArrowLeft />
                    {Localize(`CHECKOUT.GO_BACK`)}
                  </div>

                  <div className={"title-text my-5"}>
                    {Localize(`CHECKOUT.CHECKOUT`)}
                  </div>
                </div>

                <Row className={"flex-sm-column-reverse"}>
                  <Col size={24} className={"pl-0 pb-5 mt-4"}>
                    <AddressSelection
                      name="billingAddress"
                      type={"BILLING"}
                      required={true}
                      allowTemp={false}
                      countries={countries}
                      provinces={provinces}
                      addressOptions={
                        _.filter(user.billingAddresses, address => {
                          address.id = address.addressId;
                          return true;
                        }) || []
                      }
                      selected={
                        cart && cart.billingAddress
                          ? _.get(cart.billingAddress, "addressId", null)
                          : null
                      }
                      onSelectAddress={address =>
                        this._callBillingAddress(address)
                      }
                      onClickAddressEnrichment={this._addressEnrichmentCallback}
                    />
                  </Col>
                </Row>

                {_.get(cart, "billingAddress") && (
                  <Row>
                    <Col size={12} className={"py-2 px-0"}>
                      <TextInput
                        label={Localize("COMMON.FIRSTNAME")}
                        placeholder="-"
                        name="firstName"
                        required={true}
                        value={_.get(cart, "billingAddress.firstName")}
                        disabled={true}
                      />
                    </Col>
                    <Col size={12} className={"py-2 px-0 pl-md-3"}>
                      <TextInput
                        placeholder="-"
                        name="lastName"
                        required={true}
                        label={Localize("COMMON.LASTNAME")}
                        value={_.get(cart, "billingAddress.lastName")}
                        disabled={true}
                        regex={
                          /([^A-Za-z \-'])|(-{2,})|(-')|('-)|( {2,})|('{2,})/g
                        }
                      />
                    </Col>
                    <Col size={{ xs: 24, md: 12 }} className={"py-2 px-0"}>
                      <TextInput
                        placeholder="-"
                        label="E-MAIL"
                        name="email"
                        value={values.email}
                        disabled={true}
                      />
                    </Col>
                    <Col
                      size={{ xs: 24, md: 12 }}
                      className={"py-2 px-0 pl-sm-0 pl-md-3"}
                    >
                      <TextInput
                        placeholder="-"
                        name="phone"
                        label={Localize("COMMON.PHONE")}
                        type="number"
                        value={_.get(cart, "billingAddress.phone")}
                        // value={values.phone}
                        disabled={true}
                      />
                    </Col>
                    <Col size={{ xs: 24, md: 12 }} className={"py-2 px-0"}>
                      <TextInput
                        placeholder="-"
                        name="birthCountry"
                        required={true}
                        label={Localize("COMMON.BIRTH_PLACE")}
                        value={_.get(cart, "billingAddress.birthCountry")}
                        disabled={true}
                      />
                    </Col>
                    {_.get(cart, "billingAddress.countryCode") === "IT" && (
                      <Col size={12} className={"py-2 px-0 pl-md-3"}>
                        <TextInput
                          placeholder="-"
                          name="taxCode"
                          required={true}
                          label={Localize("COMMON.CF")}
                          value={_.get(cart, "billingAddress.taxCode")}
                          disabled={true}
                        />
                      </Col>
                    )}
                  </Row>
                )}
                {/* <Col size={{ xs: 24, md: 12 }} className={"py-2 px-0 pl-sm-0 pl-md-3"}>
                    <a
                      className={"mt-md-3 mt-sm-0 col-md-auto col-sm-24 p-0"}
                      href={`${Config.authBaseurl}/${Config.apis.oauth.addUserInfo}?client_id=${Config.oauthClientId}&fields=taxcode&redirect_uri=${window.location.href}`}
                    >
                      <Button size={"L"} label={Localize(`BUY_PRODUCT.EDIT_EXT`)} />
                    </a>
                  </Col> */}

                {_.get(cart, "grandTotal") > 0 && (
                  <>
                    <Row className="my-4 px-0">
                      <Col size={"auto"} className="px-0">
                        <CheckMark
                          id={"ENABLE_VAT"}
                          disabled={!_.get(cart, "billingAddress.id", false)}
                          tooltip={
                            !_.get(cart, "billingAddress.id", false) &&
                            Localize("CHECKOUT.SELECT_BILLING_BEFORE")
                          }
                          label={Localize("CHECKOUT.ENABLE_VAT")}
                          value={enableVat}
                          onChange={value =>
                            this.setState({ enableVat: value }, () => {
                              if (!value) {
                                updateValue({
                                  key: "invoiceWithVatNumber",
                                  value: false
                                });
                                updateValue({
                                  key: "language",
                                  value: null
                                });
                                updateValue({
                                  key: "vatNumber",
                                  value: null
                                });
                                updateValue({
                                  key: "receiverCode",
                                  value: null
                                });
                                updateValue({
                                  key: "emailPec",
                                  value: null
                                });
                              } else {
                                updateValue({
                                  key: "invoiceWithVatNumber",
                                  value: true
                                });
                              }
                              this._callBillingAddress(null, false);
                            })
                          }
                        />
                      </Col>
                    </Row>

                    {enableVat && (
                      <Row className={"flex-sm-column-reverse"}>
                        <Col size={24} className={"px-0"}>
                          <Row>
                            <Col
                              size={{ sm: 24, md: 12, lg: 12 }}
                              className={"py-2 px-0"}
                            >
                              <SelectBox
                                name="country"
                                required={true}
                                options={countryList}
                                autocomplete={true}
                                label={Localize("ADDRESS.NATIONALITY")}
                                value={values.language}
                                fullObjectReturn={true}
                                disabled={!enableVat}
                                onChange={({ value }) => {
                                  updateValue({
                                    key: "language",
                                    value
                                  });
                                  updateValue({
                                    key: "vatNumber",
                                    value: null
                                  });
                                  updateValue({
                                    key: "receiverCode",
                                    value: null
                                  });
                                  updateValue({
                                    key: "emailPec",
                                    value: null
                                  });
                                  this._callBillingAddress(null, false);
                                }}
                                loading={countryLoading}
                                onAutocompleteChange={async value => {
                                  if (value && value.length >= 2) {
                                    this.setState(
                                      { countryLoading: true },
                                      async () => {
                                        const countryList = await getCountryList(
                                          token,
                                          (value || "").toUpperCase()
                                        );
                                        this.setState({
                                          countryList,
                                          countryLoading: false
                                        });
                                      }
                                    );
                                  }
                                }}
                              />
                            </Col>
                            <Col
                              size={{ sm: 24, md: 12, lg: 12 }}
                              className={"py-2 px-0 pl-sm-0 pl-md-3"}
                            >
                              <TextInput
                                name="vatNumber"
                                required={true}
                                label={Localize("CHECKOUT.PIVA")}
                                error={errors.vatNumber || false}
                                value={values.vatNumber || errors.vatNumber}
                                disabled={
                                  !enableVat ||
                                  !values.language ||
                                  _.isEmpty(values.language)
                                }
                                placeholder={
                                  !enableVat ||
                                  !values.language ||
                                  _.isEmpty(values.language)
                                    ? Localize("COMMON.ENTER_ISO")
                                    : ""
                                }
                                onChange={value => {
                                  const regex =
                                    values.language === "IT"
                                      ? Config.regex.piva_iso.IT.regex
                                      : Config.regex.piva;
                                  updateValue({
                                    key: "vatNumber",
                                    value,
                                    regex
                                  });
                                  if (
                                    !!value &&
                                    !_.isEmpty(value) &&
                                    RegExp(regex).test(value) &&
                                    values.language === "IT"
                                  ) {
                                    this._callBillingAddress(null, false);
                                  } else
                                    this._callBillingAddressDebouncedShort();
                                }}
                                onBlur={() =>
                                  values.language !== "IT" &&
                                  this._callBillingAddress()
                                }
                              />
                            </Col>
                            {values.language === "IT" && (
                              <>
                                <Col
                                  size={{ sm: 24, md: 12, lg: 12 }}
                                  className={"py-2 px-0"}
                                >
                                  <TextInput
                                    name="receiverCode"
                                    required={
                                      values.language === "IT" ? true : false
                                    }
                                    label={Localize("CHECKOUT.SDI")}
                                    error={errors.receiverCode || false}
                                    value={
                                      values.receiverCode || errors.receiverCode
                                    }
                                    disabled={
                                      !enableVat || values.language !== "IT"
                                    }
                                    placeholder={
                                      !enableVat ||
                                      !values.language ||
                                      _.isEmpty(values.language)
                                        ? Localize("COMMON.ENTER_ISO")
                                        : ""
                                    }
                                    maxlength={7}
                                    onChange={value => {
                                      updateValue({
                                        key: "receiverCode",
                                        value,
                                        regex: Config.regex.sdi
                                      });
                                      if (
                                        !!value &&
                                        !_.isEmpty(value) &&
                                        RegExp(Config.regex.sdi).test(value)
                                      ) {
                                        this._callBillingAddress(null, false);
                                      } else
                                        this._callBillingAddressDebounced();
                                    }}
                                  />
                                </Col>
                                <Col
                                  size={{ sm: 24, md: 12, lg: 12 }}
                                  className={"py-2 px-0 pl-sm-0 pl-md-3"}
                                >
                                  <TextInput
                                    name="emailPec"
                                    required={
                                      values.language === "IT" ? true : false
                                    }
                                    label={Localize("CHECKOUT.PEC")}
                                    error={errors.emailPec || false}
                                    value={values.emailPec || errors.emailPec}
                                    disabled={
                                      !enableVat || values.language !== "IT"
                                    }
                                    placeholder={
                                      !enableVat ||
                                      !values.language ||
                                      _.isEmpty(values.language)
                                        ? Localize("COMMON.ENTER_ISO")
                                        : ""
                                    }
                                    onChange={value => {
                                      updateValue({
                                        key: "emailPec",
                                        value,
                                        regex: Config.regex.email
                                      });
                                      // if (!errors.emailPec && values.emailPec) {
                                      if (
                                        !!value &&
                                        !_.isEmpty(value) &&
                                        RegExp(Config.regex.email).test(value)
                                      ) {
                                        this._callBillingAddress(null, false);
                                      } else
                                        this._callBillingAddressDebounced();
                                      // }
                                    }}
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}

                    <Col size={{ xl: 20 }} className={"my-3 px-0"}>
                      <span style={{ color: "var(--red-alert)" }}>*</span>
                      {Localize("COMMON.REQUIRED_INFO")}
                    </Col>

                    <Col size={24} className={"my-4 pl-sm-0"}>
                      <div className={"mt-5 ml-md-3 separator"} />
                    </Col>

                    <Container className={"j-payment-box"}>
                      <Row>
                        <Col size={24} className={"mb-5 pl-sm-0 px-0"}>
                          <span className={"title-label"}>
                            {Localize("PAYMENT_CARD.TITLE")}
                          </span>
                        </Col>

                        <Col
                          size={24}
                          className={"my-4 pl-sm-0 d-sm-none px-0"}
                        >
                          <div className={"input-code"}>
                            <TextInput
                              label={Localize("PAYMENT_CARD.CODE")}
                              placeholder={Localize(
                                "PAYMENT_CARD.CODE_PLACEHOLDER"
                              )}
                              name={"code"}
                              value={values.code}
                              maxlength={30}
                              onChange={value =>
                                value.length <= 30 &&
                                updateValue({
                                  key: "code",
                                  value
                                })
                              }
                            />
                          </div>
                          <Button
                            // style={{ width: "100%" }}
                            size={"M"}
                            label={Localize(`BUY_PRODUCT.CODE_REDEEM`)}
                            onClick={() =>
                              values.code
                                ? redeemVoucherAction({ code: values.code })
                                : null
                            }
                          />
                        </Col>

                        <Col size={24} className={"mt-5 mb-3 pl-sm-0 px-0"}>
                          <LabelText
                            label={Localize("CHECKOUT.METHOD")}
                            required={true}
                          />
                        </Col>

                        <Col size={24} className={"mb-3 mt-3 pl-sm-0 px-0"}>
                          <RadioButton
                            selected={values.paymentOption}
                            name="paymentMethod"
                            type="CARD"
                            options={[
                              ..._.get(
                                paymentMethods,
                                "storedPaymentMethods",
                                []
                              ).map(p => ({
                                id: p.id,
                                key: p.id,
                                label: `${_.upperCase(
                                  p.brand
                                )} - **** **** **** ${p.lastFour}}`,
                                type: p.type,
                                onDelete: () =>
                                  paymentMethodDeleteAction({ id: p.id }),
                                component: (
                                  <div
                                    className="select-card"
                                    style={{ marginTop: 0 }}
                                  >
                                    {/* <div
                                  style={{
                                    display: "table",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "table-cell",
                                      verticalAlign: "middle",
                                    }}
                                  > */}
                                    <img
                                      className="card"
                                      alt={`${p.brand} icon`}
                                      style={{
                                        height: 30,
                                        marginBottom: -5,
                                        marginTop: -5
                                      }}
                                      src={
                                        p.brand === "visa"
                                          ? visa
                                          : p.brand === "mc"
                                          ? mastercard
                                          : p.type === "paypal"
                                          ? paypal
                                          : null
                                      }
                                    />
                                    {/* </div> */}
                                    <span
                                      className="saved-payment-detail"
                                      style={{
                                        paddingLeft: 5
                                        // display: "table-cell",
                                        // verticalAlign: "middle",
                                      }}
                                    >
                                      {p.type === "scheme" ? (
                                        <>
                                          {p.holderName} **** **** ****{" "}
                                          {p.lastFour} - {p.expiryMonth}/
                                          {p.expiryYear}
                                        </>
                                      ) : p.type === "paypal" ? (
                                        p.shopperEmail
                                      ) : (
                                        "??????"
                                      )}
                                    </span>
                                    {/* </div> */}
                                  </div>
                                ),
                                // value: JSON.stringify(p),
                                value: p.id
                              })),
                              {
                                id: "paypal",
                                key: "paypal",
                                label: Localize("CHECKOUT.PAYWITH_PAYPAL"),
                                component: (
                                  <div className="select-card">
                                    <img
                                      className="card"
                                      src={paypal}
                                      alt="paypal"
                                    />
                                  </div>
                                ),
                                value: "paypal"
                              },
                              {
                                id: "card",
                                key: "card",
                                label: Localize("CHECKOUT.PAYWITH_CARD"),
                                component: (
                                  <div className="select-card">
                                    <img
                                      className="card"
                                      src={mastercard}
                                      alt="mastercard"
                                    />
                                    <img
                                      className="card"
                                      src={visa}
                                      alt="visa"
                                    />
                                    {/* <img className="card" src={americanExpress} /> */}
                                  </div>
                                ),
                                value: "card"
                              }
                            ]}
                            onChange={(item, obj) => {
                              updateValue({
                                key: "paymentOption",
                                value: item
                              });
                              if (item === "paypal") {
                                this.setState({
                                  paymentMethod: {
                                    paymentMethod: { type: "paypal" }
                                  }
                                });
                              } else if (item === "card" || !item) {
                                this.setState({
                                  paymentMethod: null
                                });
                              } else if (item && isObject(obj)) {
                                try {
                                  const {
                                    id: storedPaymentMethodId,
                                    type
                                  } = obj;
                                  this.setState({
                                    paymentMethod: {
                                      paymentMethod: {
                                        type,
                                        storedPaymentMethodId
                                      },
                                      captureDelayHours: 0
                                      // recurringProcessingModel: "CardOnFile"
                                    }
                                  });
                                } catch (e) {
                                  this.setState({
                                    paymentMethod: null
                                  });
                                }
                              }
                            }}
                          />
                        </Col>

                        {paymentMethods &&
                        values.paymentOption /*&& values.paymentOption === "card"*/ && (
                            <Col size={24} className={"pl-sm-0 px-0"}>
                              <PaymentCardBox
                                type={values.paymentOption}
                                paymentMethods={paymentMethods}
                                locale={this.locale}
                                onValid={({ data, component, checkout }) =>
                                  this.setState({
                                    paymentMethod: data || null,
                                    recurringProcessingModel: data
                                      ? "CardOnFile"
                                      : null,
                                    checkout
                                  })
                                }
                              />
                            </Col>
                          )}
                        {paymentMethods &&
                          values.paymentOption &&
                          get(paymentMethod, "paymentMethod.type") ===
                            "scheme" &&
                          get(
                            paymentMethod,
                            "paymentMethod.storedPaymentMethodId",
                            false
                          ) && (
                            <>
                              <Col size={24} className={"pl-sm-0 px-0"}>
                                <LabelText
                                  label={
                                    "Per questioni di sicurezza, è necessario inserire il CVC della carta scelta"
                                  }
                                  required={true}
                                />
                              </Col>
                              <Col
                                size={{ lg: 6, md: 12, sm: 24 }}
                                className={"pl-sm-0 px-0 mb-5"}
                              >
                                <TextInput
                                  autocomplete={false}
                                  name="cvc"
                                  type="search"
                                  placeholder={"CVC"}
                                  value={get(
                                    paymentMethod,
                                    "paymentMethod.cvc",
                                    ""
                                  )}
                                  disabled={checkoutLoading}
                                  onChange={value => {
                                    let { paymentMethod } = this.state;
                                    set(
                                      paymentMethod,
                                      "paymentMethod.cvc",
                                      value
                                    );
                                    this.setState({ paymentMethod });
                                  }}
                                />
                              </Col>
                            </>
                          )}
                      </Row>
                    </Container>
                  </>
                )}

                <Row>
                  <Col size={24} className={"pl-sm-0 px-0"}>
                    {cart && cart.items && this._termsMembership(cart.items) && (
                      <CheckMark
                        id={"CHECKOUT_TERM_MEMBERSHIP"}
                        label={Localize(
                          "CHECKOUT.CHECKOUT_TERMS.MEMBERSHIP.1",
                          [],
                          true
                        )}
                        value={values.CHECKOUT_TERM_MEMBERSHIP}
                        onChange={value =>
                          updateValue({
                            key: "CHECKOUT_TERM_MEMBERSHIP",
                            value
                          })
                        }
                        required={true}
                      />
                    )}

                    {cart &&
                      cart.items &&
                      this._termsOnlyMembership(cart.items) && (
                        <CheckMark
                          id={"CHECKOUT_TERM_ONLY_MEMBERSHIP"}
                          label={Localize(
                            "CHECKOUT.CHECKOUT_TERMS.MEMBERSHIP.2",
                            [],
                            true
                          )}
                          value={values.CHECKOUT_TERM_ONLY_MEMBERSHIP}
                          onChange={value =>
                            updateValue({
                              key: "CHECKOUT_TERM_ONLY_MEMBERSHIP",
                              value
                            })
                          }
                          required={true}
                        />
                      )}

                    {cart && cart.items && this._termsJCard(cart.items) && (
                      <>
                        <CheckMark
                          id={"CHECKOUT_TERM_JCARD_1"}
                          label={Localize(
                            "CHECKOUT.CHECKOUT_TERMS.JCARD.1",
                            [],
                            true
                          )}
                          value={values.CHECKOUT_TERM_JCARD_1}
                          onChange={value =>
                            updateValue({
                              key: "CHECKOUT_TERM_JCARD_1",
                              value
                            })
                          }
                          required={true}
                        />
                        <CheckMark
                          id={"CHECKOUT_TERM_JCARD_2"}
                          label={Localize(
                            "CHECKOUT.CHECKOUT_TERMS.JCARD.2",
                            [],
                            true
                          )}
                          value={values.CHECKOUT_TERM_JCARD_2}
                          onChange={value =>
                            updateValue({
                              key: "CHECKOUT_TERM_JCARD_2",
                              value
                            })
                          }
                          required={true}
                        />
                        <CheckMark
                          id={"CHECKOUT_TERM_JCARD_3"}
                          label={Localize(
                            "CHECKOUT.CHECKOUT_TERMS.JCARD.3",
                            [],
                            true
                          )}
                          value={values.CHECKOUT_TERM_JCARD_3}
                          onChange={value =>
                            updateValue({
                              key: "CHECKOUT_TERM_JCARD_3",
                              value
                            })
                          }
                          required={true}
                        />
                      </>
                    )}
                  </Col>
                  <Col size={{ xl: 20 }} className={"my-3 px-0"}>
                    <span style={{ color: "var(--red-alert)" }}>*</span>
                    {Localize("COMMON.REQUIRED_INFO")}
                  </Col>
                </Row>
              </Col>

              <Col
                size={{ xs: 24, sm: 24, md: 24, lg: 12, xl: 6 }}
                className={"my-sm-4 my-xs-4"}
              >
                <div className={"sticky-cart"}>
                  <CartBoxComponent
                    cartInfo={cart || {}}
                    disabled={
                      !completed ||
                      cartLoading ||
                      checkoutLoading ||
                      !cart ||
                      (_.get(cart, "grandTotal") > 0 &&
                        (!_.get(cart, "billingAddress.addressId") ||
                          !_.get(cart, "billingAddress.taxCode") ||
                          !paymentMethod ||
                          !values.paymentOption ||
                          (get(paymentMethod, "paymentMethod.type") ===
                            "scheme" &&
                            get(
                              paymentMethod,
                              "paymentMethod.storedPaymentMethodId",
                              false
                            ) &&
                            !get(paymentMethod, "paymentMethod.cvc", false))))
                    }
                    // disabled={
                    //   !paymentMethod ||
                    //   !completed ||
                    //   !_.get(cart, "billingAddress.addressId") ||
                    //   cartLoading ||
                    //   checkoutLoading ||
                    //   !cart ||
                    //   (get(paymentMethod, "paymentMethod.type") === "card" &&
                    //     !get(
                    //       paymentMethod,
                    //       "paymentMethod.storedPaymentMethodId",
                    //       false
                    //     ) &&
                    //     !get(paymentMethod, "paymentMethod.cvc", false))
                    // }
                    onCheckout={async () => {
                      if (!cartLoading && !checkoutLoading) {
                        // if (paymentMethod) {
                        this.setState(
                          { checkoutLoading: true, apiError: null },
                          async () => {
                            try {
                              const res = await checkoutApi({
                                data: {
                                  ...paymentMethod,
                                  // ...values,
                                  storePaymentMethod: true
                                },
                                headers: {
                                  ...headersBuilder(),
                                  Authorization: `Bearer ${token}`
                                }
                              });
                              const { action, orderId } = res || {};
                              if (window.checkout && action) {
                                window.checkout
                                  .createFromAction(action)
                                  .mount("#my-container");
                              } else if (orderId) {
                                goTo(`thankyou?orderId=${orderId}`);
                              } else {
                                goTo(`thankyou`);
                              }
                            } catch (e) {
                              this.setState({
                                apiError: e,
                                checkoutLoading: false
                              });
                            }
                          }
                        );
                        // }
                      }
                    }}
                    tooltip={
                      !cartLoading &&
                      !checkoutLoading &&
                      Localize("CHECKOUT.TOOLTIP_COMPLETE")
                    }
                  />
                </div>
              </Col>
              <Col size={2} className={"d-md-none"} />
            </Row>
          )}
        </Form>
        <div id="my-container" />
        {apiError && (
          <ErrorPage
            error={apiError}
            dismiss={() => this.setState({ apiError: null })}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ cart, checkout, auth, utils }) => ({
  cart: cart.data,
  cartLoading: cart.loading,
  // checkoutLoading: checkout.loading,
  loadingCustomerPay: cart.loadingCustomerPay,
  paymentMethods: cart.paymentMethods,
  paymentMethodsCust: cart.customerPaymentMethods,
  user: auth.user,
  token: auth.token,
  countries: _.sortBy(utils.countries, "label"),
  provinces: _.sortBy(utils.provinces, "label")
});

const mapDispatchToProps = {
  getCartContentAction,
  createBillingAddressAction,
  createBillingAddressSilentAction,
  goTo,
  getCountriesAction,
  getProvincesAction,
  redeemVoucherAction,
  paymentMethodsAction,
  paymentMethodDeleteAction,
  paymentMethodsCustomerAction,
  checkoutAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
