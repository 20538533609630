import React, { Suspense } from "react";
import { useImage } from "react-image";
import PropTypes from "prop-types";

export default function Image(props) {
  const { srcList, alt, style = null, className, ...moreProps } = props;
  const { src } = useImage({
    srcList
  });
  return (
    srcList && (
      <Suspense>
        <img
          src={src}
          alt={alt}
          style={style}
          className={`lazy-img ${className}`}
          {...moreProps}
        />
      </Suspense>
    )
  );
}

Image.propTypes = {
  srcList: PropTypes.arrayOf(PropTypes.string),
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.string | PropTypes.number,
    height: PropTypes.string | PropTypes.number,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundPosition: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundSize: PropTypes.string | PropTypes.number,
    border: PropTypes.string,
    borderRadius: PropTypes.string | PropTypes.number,
    boxShadow: PropTypes.string,
    color: PropTypes.string,
    cursor: PropTypes.string,
    display: PropTypes.string,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.string | PropTypes.number,
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.string,
    letterSpacing: PropTypes.string,
    lineHeight: PropTypes.string | PropTypes.number,
    margin: PropTypes.string | PropTypes.number,
    maxHeight: PropTypes.string | PropTypes.number,
    maxWidth: PropTypes.string | PropTypes.number,
    minHeight: PropTypes.string | PropTypes.number,
    minWidth: PropTypes.string | PropTypes.number,
    opacity: PropTypes.string | PropTypes.number,
    outline: PropTypes.string,
    overflow: PropTypes.string,
    padding: PropTypes.string | PropTypes.number,
    position: PropTypes.string,
    top: PropTypes.string | PropTypes.number,
    right: PropTypes.string | PropTypes.number,
    bottom: PropTypes.string | PropTypes.number,
    left: PropTypes.string | PropTypes.number,
    textAlign: PropTypes.string,
    textDecoration: PropTypes.string,
    textShadow: PropTypes.string,
    transform: PropTypes.string,
    verticalAlign: PropTypes.string,
    visibility: PropTypes.string,
    zIndex: PropTypes.string
  })
};
