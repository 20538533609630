import React from "react";
import DatePicker from "react-date-picker";

import "./DatePicker.scss";
import LabelText from "../LabelText/LabelText";

export default ({
  value,
  onChange,
  label,
  required = false,
  disabled = false,
  type = "default",
  maxDate = null,
  minDate = null
}) => {
  return (
    <div className={"j-date"}>
      <LabelText label={label} required={required} />
      {type === "default" && (
        <DatePicker
          clearIcon={null}
          onChange={e =>
            (typeof onChange === "function" && onChange(e)) || null
          }
          required={required}
          value={value}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
        />
      )}
    </div>
  );
};
