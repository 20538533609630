//HERO
import home_hero from "./home-hero.jpg";
import { ReactComponent as home_hero_claim } from "./home-hero-claim.svg";
import { ReactComponent as home_hero_claim_christmas } from "./christmas/home-hero-claim-christmas.svg";
import alt_hero from "./alt-hero.jpg";
import jcard_hero from "./jcard-hero.jpg";
import j1897_hero from "./j1897-hero.jpg";
import bw_hero from "./bw-hero.jpg";
import bw_lite_hero from "./bw-lite-hero.jpg";
import bwd_hero from "./bwd-hero.jpg";
import y1216_hero from "./y1216-hero.jpg";
import j311_hero from "./j311-hero.jpg";
import j02_hero from "./j02-hero.jpg";

//SIDE
import alt_side from "./alt-side.jpg";
import jcard_side from "./jcard-side.jpg";
import j1897_side from "./j1897-side.jpg"; // NORMAL
// import j1897_side_christmas from "./christmas/j1897-side-christmas.jpg"; // CHRISTMAS
// import j1897_side_black_friday from "./black-friday/j1897-side-black-friday.jpg"; // BLACK FRIDAY
import bw_side from "./bw-side.jpg"; // NORMAL
import bw_side_christmas from "./christmas/bw-side-christmas.jpg"; // CHRISTMAS
import bw_side_black_friday from "./black-friday/bw-side-black-friday.jpg"; // BLACK FRIDAY
import bw_int_side from "./bw-int-side.jpg"; // NORMAL
import bw_int_side_christmas from "./christmas/bw-int-side-christmas.jpg"; // BLACK FRIDAY
// import bw_int_side_black_friday from "./black-friday/bw-int-side-black-friday.jpg"; // BLACK FRIDAY
import bw_lite_side from "./bw-lite-side.jpg"; // NORMAL
import bw_lite_side_christmas from "./christmas/bw-lite-side-christmas.jpg"; // BLACK FRIDAY
import bw_lite_side_black_friday from "./black-friday/bw-lite-side-black-friday.jpg"; // BLACK FRIDAY
import bwd_side from "./bwd-side.jpg"; // NORMAL
import bwd_side_christmas from "./christmas/bwd-side-christmas.jpg"; // CHRISTMAS
import bwd_side_black_friday from "./black-friday/bwd-side-black-friday.jpg"; // BLACK FRIDAY
import bw_stadium_side from "./bw-stadium-side.jpg"; // NORMAL
import y1216_side from "./y1216-side.jpg"; // NORMAL
import y1216_side_christmas from "./christmas/y1216-side-christmas.jpg"; // CHRISTMAS
import y1216_side_black_friday from "./black-friday/y1216-side-black-friday.jpg"; // BLACK FRIDAY
import j311_side from "./j311-side.jpg"; // NORMAL
import j311_side_christmas from "./christmas/j311-side-christmas.jpg"; // CHRISTMAS
import j311_side_black_friday from "./black-friday/j311-side-black-friday.jpg"; // BLACK FRIDAY
import j311_young_side from "./j311-young-side.jpg"; // NORMAL
import j02_side from "./j02-side.jpg"; // NORMAL
import j02_side_christmas from "./christmas/j02-side-christmas.jpg"; // CHRISTMAS
import j02_side_black_friday from "./black-friday/j02-side-black-friday.jpg"; // BLACK FRIDAY

//JCARD

//OTHER
import j_group from "./Group7.png";
import j_test from "./test_juventus.PNG";
import img_brochure from "./img-brochure.jpg";
import memb_ph from "./memb_ph.jpg";
import jcard_ph from "./jcard_ph.jpg";

import cardGallery1 from "./gallery-services-1.jpg";
import cardGallery2 from "./gallery-services-2.jpg";
import cardGallery3 from "./gallery-services-3.jpg";
import cardGallery4 from "./gallery-services-4.jpg";

import checkoutGallery1 from "./checkout-gallery-1.jpg";
import checkoutGallery2 from "./checkout-gallery-2.jpg";
import checkoutGallery3 from "./checkout-gallery-3.jpg";

import couponGallery1 from "./coupon-gallery-1.jpeg";
import couponGallery2 from "./coupon-gallery-2.jpeg";
import couponGallery3 from "./coupon-gallery-3.jpeg";

const hero_img = {
  default: home_hero,
  alt: alt_hero,
  claim: home_hero_claim,
  jcard: jcard_hero,
  j1897: j1897_hero,
  j1987: j1897_hero,
  bw: bw_hero,
  bw_lite: bw_lite_hero,
  bwd: bwd_hero,
  y1216: y1216_hero,
  j311: j311_hero,
  j02: j02_hero,
  christmas: {
    claim: home_hero_claim_christmas
  }
};

const side = {
  alt: alt_side,
  jcard: jcard_side,
  j1897: j1897_side,
  j1987: j1897_side,
  bw: bw_side,
  bw_lite: bw_lite_side,
  bw_int: bw_int_side,
  bwd: bwd_side,
  bw_stadium: bw_stadium_side,
  y1216: y1216_side,
  j311: j311_side,
  j311_young: j311_young_side,
  j02: j02_side,
  christmas: {
    j1897: j1897_side,
    bw: bw_side_christmas,
    bw_lite: bw_lite_side_christmas,
    bw_int: bw_int_side_christmas,
    bw_stadium: bw_stadium_side,
    // bw_lite: bw_lite_side_christmas,
    bwd: bwd_side_christmas,
    y1216: y1216_side_christmas,
    j311: j311_side,
    j311_young: j311_young_side,
    j02: j02_side
  },
  blackFriday: {
    j1897: j1897_side,
    bw: bw_side_black_friday,
    bw_lite: bw_lite_side_black_friday,
    bw_stadium: bw_stadium_side,
    // bw_int: bw_int_side_black_friday,
    bwd: bwd_side_black_friday,
    y1216: y1216_side_black_friday,
    j311: j311_side,
    j311_young: j311_young_side,
    j02: j02_side
  }
};

export {
  hero_img,
  side,
  j_group,
  j_test,
  img_brochure,
  memb_ph,
  jcard_ph,
  cardGallery1,
  cardGallery2,
  cardGallery3,
  cardGallery4,
  checkoutGallery1,
  checkoutGallery2,
  checkoutGallery3,
  couponGallery1,
  couponGallery2,
  couponGallery3
};
