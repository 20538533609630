import Config from "../Config";
import ErrorMessage from "../libs/helpers/Error";

export const checkGift = async ({ headers, code, giftCode }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.me}/memberships/${code}/gift?giftCode=${giftCode}`,
    {
      method: "GET",
      headers: { ...headers, "Content-Type": "application/json" }
    }
  );

  const response = await res.json();

  return { response, ok: res.ok };
};

export const getMyMembershipsApi = async ({ headers, membership_code }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.oauth.me
    }/membershipsExtended?includeAdditionalCustomerDetails=true&includeAdditionalOrderDetails=true&includeAdditionalProductDetails=true&statuses[]=active&statuses[]=ready${
      membership_code ? `&membershipCode=${membership_code}` : ""
    }`,
    {
      method: "GET",
      headers: { ...headers, "Content-Type": "application/json" }
    }
  );

  const response = await res.json();

  return { response, ok: res.ok };
};

const validateManageAction = (action, config) => {
  const VALID_ACTIONS_CONFIG = [
    {
      action: "renewalShipment",
      statuses: ["active", "ready"],
      required: ["shippingAddress"]
    },
    {
      action: "renewalVariantSize",
      statuses: ["active", "ready"],
      required: ["preferredVariantSize"]
    }
  ];
  if (!VALID_ACTIONS_CONFIG.find(c => c.action === action)) {
    throw new Error("manage.invalid_action");
  }
  const { required } =
    VALID_ACTIONS_CONFIG.find(c => c.action === action) || {};
  if (required.some(r => !config[r])) {
    throw new Error("manage.invalid_confg");
  }
};

export const setMyMembershipsConfigurationApi = async ({
  headers,
  membership_code,
  action,
  configuration
}) => {
  const config = { ...configuration };
  validateManageAction(action, config);
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.me}/memberships/${membership_code}/manage`,
    {
      method: "PATCH",
      headers: { ...headers, "Content-Type": "application/json" },
      body: JSON.stringify({ config, action })
    }
  );

  const response = await res.json();

  if (res.ok) {
    return { response, ok: res.ok };
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};
