import callApi from "../libs/helpers/callApi";
import {
  addItemsToCartApi,
  paymentMethodsApi,
  paymentMethodDeleteApi,
  paymentMethodsCustomerApi,
  createBillingAddressApi,
  deleteItemToCartApi,
  getCartContentApi,
  updateItemToCartApi,
  redeemVoucherApi
} from "../api/cart.api";
import {
  getMyMembershipsApi,
  setMyMembershipsConfigurationApi
} from "../api/membership.api";
import { setErrorAction } from "./app.actions";

export const MY_MEMBERSHIP_LOADING = "MY_MEMBERSHIP_LOADING";
export const MY_MEMBERSHIP_LOADED = "MY_MEMBERSHIP_LOADED";
export const MY_MEMBERSHIP_ERROR = "MY_MEMBERSHIP_ERROR";

export const getMyMembershipsAction = callApi({
  apiCall: p => getMyMembershipsApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  successAction: MY_MEMBERSHIP_LOADED
});

export const setMyMembershipsConfigurationAction = callApi({
  apiCall: p => setMyMembershipsConfigurationApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  processResult: (result, dispatch, getState, p) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch(getMyMembershipsAction(p));
    }
  },
  processError: (error, dispatch, errorAction, getState, params) => {
    console.error("processError", error);
    let { id, name, status, message, stack, date, code } = error;
    dispatch(
      setErrorAction({
        id,
        name,
        status,
        message,
        stack,
        date,
        code
        // closeToHome: true,
      })
    );
  }
});

export const getCartContentAction = callApi({
  apiCall: p => getCartContentApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  successAction: MY_MEMBERSHIP_LOADED
});

export const MY_MEMBERSHIP_ADDED = "MY_MEMBERSHIP_ADDED";

export const addItemsToCartAction = callApi({
  apiCall: p => addItemsToCartApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  successAction: MY_MEMBERSHIP_ADDED
});

export const createBillingAddressAction = callApi({
  apiCall: p => createBillingAddressApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  successAction: MY_MEMBERSHIP_LOADED
});

export const createBillingAddressSilentAction = callApi({
  apiCall: p => createBillingAddressApi(p),
  secured: true,
  errorAction: MY_MEMBERSHIP_ERROR,
  successAction: MY_MEMBERSHIP_LOADED
});

export const updateItemToCartAction = callApi({
  apiCall: p => updateItemToCartApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  successAction: MY_MEMBERSHIP_ADDED
});

export const deleteItemToCartAction = callApi({
  apiCall: p => deleteItemToCartApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch(getCartContentAction());
    }
  }
});

export const REDEEM_CODE_LOADING = "REDEEM_CODE_LOADING";
export const REDEEM_CODE_LOADED = "REDEEM_CODE_LOADED";
export const REDEEM_CODE_ERROR = "REDEEM_CODE_ERROR";

export const redeemVoucherAction = callApi({
  apiCall: p => redeemVoucherApi(p),
  secured: true,
  startAction: MY_MEMBERSHIP_LOADING,
  errorAction: MY_MEMBERSHIP_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: MY_MEMBERSHIP_LOADED, data: result });
    }
  }
});

export const PAYMENT_METHODS_LOADING = "PAYMENT_METHODS_LOADING";
export const PAYMENT_METHODS_LOADED = "PAYMENT_METHODS_LOADED";
export const PAYMENT_METHODS_ERROR = "PAYMENT_METHODS_ERROR";

export const paymentMethodsAction = callApi({
  apiCall: p => paymentMethodsApi(p),
  secured: true,
  startAction: PAYMENT_METHODS_LOADING,
  errorAction: PAYMENT_METHODS_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: PAYMENT_METHODS_LOADED, data: result });
    }
  }
});

export const paymentMethodDeleteAction = callApi({
  apiCall: p => paymentMethodDeleteApi(p),
  secured: true,
  startAction: PAYMENT_METHODS_LOADING,
  errorAction: PAYMENT_METHODS_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch(paymentMethodsAction());
    }
  }
});

export const PAYMENT_METHODS_CUSTOMER_LOADING =
  "PAYMENT_METHODS_CUSTOMER_LOADING";
export const PAYMENT_METHODS_CUSTOMER_LOADED =
  "PAYMENT_METHODS_CUSTOMER_LOADED";
export const PAYMENT_METHODS_CUSTOMER_ERROR = "PAYMENT_METHODS_CUSTOMER_ERROR";

export const paymentMethodsCustomerAction = callApi({
  apiCall: p => paymentMethodsCustomerApi(p),
  secured: true,
  startAction: PAYMENT_METHODS_CUSTOMER_LOADING,
  errorAction: PAYMENT_METHODS_CUSTOMER_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: PAYMENT_METHODS_CUSTOMER_LOADED, data: result });
    }
  }
});

// export const getVoucherAction = callApi({
//   apiCall: (p) => paymentMethodsApi(p),
//   secured: true,
//   startAction: PAYMENT_METHODS_LOADING,
//   errorAction: PAYMENT_METHODS_ERROR,
//   processResult: (result, dispatch, getState, params) => {
//     if (result.error) {
//       console.error(result.error);
//     } else {
//       dispatch({ type: PAYMENT_METHODS_LOADED, data: result });
//     }
//   },
// });
