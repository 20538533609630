import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import "./AddressSelection.scss";
import { Row, Col } from "../Grid";
import RadioButton from "../RadioButton/RadioButton";
import Button from "../Button/Button";
import Localize from "../../libs/localization";
import { CreateNewAddress } from "../CreateNewAddress/CreateNewAddress";
import LabelText from "../LabelText/LabelText";

const AddressSelection = ({
  addressOptions = [],
  allowTemp = true,
  type,
  onSelectAddress,
  selected,
  onClickAddressEnrichment,
  countries,
  provinces,
  name,
  required = false,
  initialData = {}
}) => {
  const [selOption, setSelOption] = useState(selected);
  useEffect(() => setSelOption(selected), [selected]);

  let options = (addressOptions || []).map(a => ({
    id: a.id,
    label: (
      <div className={`j-addressSelection${a.restricted ? " restricted" : ""}`}>
        <div className="name">
          <b>
            {a.firstName} {a.lastName}
          </b>
        </div>
        <div className="street">
          {a.street}
          <br />
          {a.postalCode} {a.city}
          {/* {a.state && ` (${a.state})`} */}
          <br />
          {!_.isEmpty(a.subAdministrativeArea) &&
            `${a.subAdministrativeArea} - `}
          {!_.isEmpty(a.country) ? a.country : a.countryCode}
        </div>
        {/* <div className="contact">{a.phone}</div> */}
      </div>
    ),
    disabled: a.restricted
    // label: a.address1 + ", " + a.country + ", (" + a.province + ")"
  }));
  if (options.length === 0) {
    options.push({
      id: null,
      label: Localize("CART.SELECTION.NO_SAVED_ADDRESSES"),
      disabled: true
    });
  }
  if (allowTemp) {
    options.push({
      id: "temp_address",
      label: Localize("CART.SELECTION.TEMP_ADDRESS")
    });
  }
  return (
    <Row className={"j-addressSelection"}>
      <Col size={24} className={"mb-4 px-md-0 px-sm-0 px-xs-0"}>
        <LabelText
          label={Localize(`CART.SELECTION.SELECT_ADDRESS_${type}`)}
          required={required}
        />
        {_.some(addressOptions, { restricted: true }) && (
          <p className="alert-restricted">
            {Localize("CART.SELECTION.RESTRICTION")}
          </p>
        )}
      </Col>
      <>
        <Col size={24} className={"px-md-0 px-sm-0 px-xs-0"}>
          <RadioButton
            options={options}
            emptyMsg={Localize("CART.SELECTION.NO_SAVED_ADDRESSES")}
            type={"ADDRESS"}
            selected={selected}
            name={name}
            onChange={val => {
              setSelOption(val);
              if (
                val !== "temp_address" &&
                typeof onSelectAddress === "function"
              ) {
                onSelectAddress(_.find(addressOptions, { id: val }));
              }
            }}
          />
        </Col>
        {allowTemp &&
          (selOption === "temp_address" || selected === "temp_address") && (
            <>
              <Col size={{ xs: 24, sm: 24, md: 12, lg: 8 }} className={"pl-0"}>
                <CreateNewAddress
                  countries={countries}
                  provinces={provinces}
                  initialData={initialData}
                  onClickAddressEnrichment={() =>
                    onClickAddressEnrichment(name)
                  }
                  onChange={(address, completed) => {
                    completed &&
                      typeof onSelectAddress === "function" &&
                      onSelectAddress(address);
                  }}
                />
              </Col>
            </>
          )}
      </>
      {selOption !== "temp_address" && selected !== "temp_address" && (
        <Col
          size={{ xs: 24, sm: 24, md: 12, lg: 12 }}
          className={"px-md-0 px-sm-0 px-xs-0 pb-sm-4 pb-xs-4"}
        >
          <Button
            style={{ width: "100%" }}
            label={Localize(`CART.SELECTION.ADD_ADDRESS`)}
            size="M"
            onClick={e =>
              (typeof onClickAddressEnrichment === "function" &&
                onClickAddressEnrichment(name)) ||
              null
            }
          />
        </Col>
      )}
    </Row>
  );
};

AddressSelection.propTypes = {
  type: PropTypes.oneOf(["SHIPPING", "BILLING"])
};

export default AddressSelection;
