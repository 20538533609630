import Config from "../Config";

export const redeemGiftApi = async ({ headers, code, query }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.me}/memberships/${code}/redeem-gift${query}`,
    {
      method: "POST",
      headers: { ...headers, "Content-Type": "application/json" }
    }
  );

  const response = await res.json();

  return { response, ok: res.ok };
};

export const activateGiftApi = async ({ headers, code, holderData }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.me}/memberships/${code}/activate`,
    {
      method: "POST",
      headers: { ...headers, "Content-Type": "application/json" },
      body: holderData ? JSON.stringify({ holderData }) : null
    }
  );

  if (res.ok) {
    return { ok: res.ok };
  } else {
    const response = await res.json();
    return { response, ok: res.ok };
  }
};

export const reuploadDocuments = async ({ headers, id, hash, formData }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.me}/orders/items/${id}/reupload-documents?hash=${hash}`,
    {
      method: "POST",
      headers: { ...headers },
      body: formData
    }
  );

  if (res.ok) {
    return { ok: res.ok };
  } else {
    const response = await res.json();
    return { response, ok: res.ok };
  }
};

export const getMembershipByCode = async ({ headers, code }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.me}/memberships/${code}`,
    {
      method: "GET",
      headers: { ...headers, "Content-Type": "application/json" }
    }
  );

  const response = await res.json();

  return { response, ok: res.ok };
};
