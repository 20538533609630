import { get, has, isEmpty, isEqual } from "lodash";
import React, { useEffect, useState } from "react";

let values = {};
let errors = {};
let completed = true;

export default ({ children, onChange, required = [], initialValues = {} }) => {
  const [previousInitialValues, setPreviousInitialValues] = useState(
    initialValues
  );
  useEffect(() => {
    if (previousInitialValues !== initialValues) {
      setPreviousInitialValues(initialValues);
    }
  }, [initialValues, previousInitialValues]);

  const updateValue = ({
    key,
    value,
    error = false,
    regex,
    values: newValues
  }) => {
    completed = true;
    if (key) {
      if (regex && !RegExp(regex).test(value)) {
        error = true;
      }
      if (!error) {
        delete errors[key];
        values[key] = value;
      } else {
        delete values[key];
        errors[key] = value;
      }
    } else if (!isEmpty(newValues)) {
      values = newValues;
      Object.keys(values).map(key => {
        if (regex && !RegExp(regex).test(values[key])) {
          error = true;
        }
        if (!error) {
          delete errors[key];
        } else {
          delete values[key];
          errors[key] = values[key];
        }
        return key;
      });
    }

    required.map(req => {
      if (!Object.keys(values).includes(req)) {
        completed = false;
      }
      return req;
    });

    typeof onChange === "function" && onChange({ values, errors, completed });
  };

  if (!initialValues || Object.keys(initialValues).length === 0) {
    values = {};
  } else if (!values || Object.keys(values).length === 0) {
    values = initialValues;
  } else if (!isEqual(initialValues, previousInitialValues)) {
    // setPreviousInitialValues(initialValues);
    values = initialValues;
    updateValue({ values });
  } else {
    values = initialValues;
  }

  completed = true;

  required.map(req => {
    if (
      !Object.keys(values).includes(req) ||
      !values[req] ||
      /^\s*$/.test(values[req])
    ) {
      completed = false;
    }
    return req;
  });

  return (
    <form>
      {typeof children === "function"
        ? children({ values, errors, completed, updateValue })
        : children}
    </form>
  );
};
