import React, { Component, createRef } from "react";

import "./PaymentCardBox.scss";
import Config from "../../Config";
import { get, isEqual } from "lodash";

export default class PaymentCardBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rendered: true,
      cardComp: null,
      error: false
    };
    this.configureAdyen = this.configureAdyen.bind(this);
  }

  componentDidMount() {
    const { paymentMethods, onValid, onChange, locale } = this.props;
    this.setState({ loading: true }, () => {
      const AdyenCheckout = window.AdyenCheckout;
      if (!AdyenCheckout) {
        this.setState({ loading: false, error: true });
      } else if (paymentMethods) {
        this.configureAdyen(
          paymentMethods.paymentMethods,
          onValid,
          onChange,
          locale
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { paymentMethods, onValid, onChange, locale, type } = this.props;
    if (
      paymentMethods &&
      paymentMethods.paymentMethods &&
      (!isEqual(
        paymentMethods.paymentMethods,
        get(prevProps.paymentMethods, "paymentMethods")
      ) ||
        !isEqual(type, prevProps.type))
    ) {
      this.setState({ rendered: false }, () => {
        this.configureAdyen(
          paymentMethods.paymentMethods,
          onValid,
          onChange,
          locale
        );
      });
    }
  }

  configureAdyen(paymentMethods, onValid, onChange, locale = "en") {
    this.setState({ rendered: true }, () => {
      const configuration = {
        locale,
        paymentMethodsResponse: paymentMethods,
        originKey: Config.adyenOriginKey,
        environment: Config.adyenEnvironment,
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: false,
        type: "card",
        brands: ["mc", "visa"],
        styles: {
          error: {},
          validated: {
            color: "green"
          },
          placeholder: {
            color: "#d8d8d8"
          }
        },
        ariaLabels: {
          lang: locale
        },
        // Events
        onChange: (state, component) => {
          typeof onChange === "function" &&
            onChange({
              isValid: state.isValid, // True or false. Specifies if all the information that the shopper provided is valid.
              data: state.data, // Provides the data that you need to pass in the `/payments` call.
              component // Provides the active component instance that called this event.
            });
        },
        onValid: (state, component) => {
          typeof onValid === "function" &&
            onValid({
              data: state.data, // Provides the data that you need to pass in the `/payments` call.
              component // Provides the active component instance that called this event.
            });
        },
        onConfigSuccess: () => this.setState({ loading: false })
      };

      window.checkout = null;

      const AdyenCheckout = window.AdyenCheckout;

      const checkout = new AdyenCheckout(configuration);

      window.checkout = checkout;

      this.setState({ cardComp: createRef() }, () => {
        const { cardComp } = this.state;
        window.checkout.create("card").mount(cardComp.current);
      });
    });
  }

  render() {
    const { cardComp, rendered } = this.state;
    const { type } = this.props;
    return (
      <>
        {/* {loading && Localize("COMMON.PLEASE_WAIT")} */}
        {rendered && (
          <div
            id="customCard-container"
            className="mb-5"
            ref={cardComp}
            style={type === "card" ? { display: "block" } : { display: "none" }}
          ></div>
        )}
      </>
    );
  }
}

// const PaymentCardBoxA = ({
//   paymentMethods,
//   onChange,
//   onValid,
//   locale = "en",
//   type,
// }) => {
//   const [rendered, setRendered] = useState(false);
//   const [checkoutIst, setCheckoutIst] = useState(null);
//   let cardComp = useRef();
//   useEffect(() => setRendered(false), []);
//   useEffect(() => {
//     setRendered(true);
//     if (window.checkout && cardComp)
//       window.checkout.create("card").mount(cardComp.current);
//   }, [cardComp, paymentMethods]);
//   const [loading, setLoading] = useState(true);

//   const AdyenCheckout = window.AdyenCheckout;

//   if (!AdyenCheckout) {
//     return <p>ERROR</p>;
//   }

//   if (!type) {
//     return null;
//   }

//   const configuration = {
//     locale,
//     paymentMethodsResponse: paymentMethods && paymentMethods.paymentMethods,
//     originKey: Config.adyenOriginKey,
//     environment: Config.adyenEnvironment,
//     hasHolderName: true,
//     holderNameRequired: true,
//     billingAddressRequired: false,
//     // type: "card",
//     // brands: ["mc", "visa"],
//     styles: {
//       error: {},
//       validated: {
//         color: "green",
//       },
//       placeholder: {
//         color: "#d8d8d8",
//       },
//     },
//     ariaLabels: {
//       lang: locale,
//     },
//     // Events
//     onChange: (state, component) => {
//       typeof onChange === "function" &&
//         onChange({
//           isValid: state.isValid, // True or false. Specifies if all the information that the shopper provided is valid.
//           data: state.data, // Provides the data that you need to pass in the `/payments` call.
//           component, // Provides the active component instance that called this event.
//         });
//     },
//     onValid: (state, component) => {
//       typeof onValid === "function" &&
//         onValid({
//           data: state.data, // Provides the data that you need to pass in the `/payments` call.
//           component, // Provides the active component instance that called this event.
//           checkout: checkoutIst,
//         });
//     },
//     onConfigSuccess: () => setLoading(false),
//   };

//   const checkout = new AdyenCheckout(configuration);

//   window.checkout = checkout;

//   return (
//     <>
//       {loading && Localize("COMMON.PLEASE_WAIT")}
//       {type === "card" && <div id="customCard-container" ref={cardComp}></div>}
//     </>
//   );
// };

// PaymentCardBoxA.propTypes = {
//   paymentMethods: PropTypes.object.isRequired,
// };

// PaymentCardBoxA.defaultProps = {};

// export default PaymentCardBoxA;
