import { reducerFromMap } from "../libs/helpers/reducers";
import {
  CART_ERROR,
  CART_LOADED,
  CART_ADDED,
  CART_LOADING,
  PAYMENT_METHODS_ERROR,
  PAYMENT_METHODS_LOADED,
  PAYMENT_METHODS_LOADING,
  PAYMENT_METHODS_CUSTOMER_ERROR,
  PAYMENT_METHODS_CUSTOMER_LOADED,
  PAYMENT_METHODS_CUSTOMER_LOADING
} from "../actions/cart.actions";

const initialState = {
  cart: {}
};

const reducer = {
  [CART_LOADING]: (state, actions) => ({
    ...state,
    added: false,
    loading: true
  }),
  [CART_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    added: false,
    error: null,
    data: actions.data
  }),
  [CART_ADDED]: (state, actions) => ({
    ...state,
    added: true,
    loading: false,
    error: null,
    data: actions.data
  }),
  [CART_ERROR]: (state, actions) => ({
    ...state,
    error: actions.error,
    loading: false
  }),
  // [REDEEM_CODE_ERROR]: (state, actions) => ({
  //   ...state,
  //   error: actions.error,
  //   loading: false,
  // }),
  [PAYMENT_METHODS_LOADING]: (state, actions) => ({
    ...state,
    loading: true
  }),
  [PAYMENT_METHODS_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    paymentMethods: actions.data
  }),
  [PAYMENT_METHODS_ERROR]: (state, actions) => ({
    ...state,
    loading: false,
    error: true
  }),
  [PAYMENT_METHODS_CUSTOMER_LOADING]: (state, actions) => ({
    ...state,
    loadingCustomerPay: true
  }),
  [PAYMENT_METHODS_CUSTOMER_LOADED]: (state, actions) => ({
    ...state,
    loadingCustomerPay: false,
    customerPaymentMethods: actions.data
  }),
  [PAYMENT_METHODS_CUSTOMER_ERROR]: (state, actions) => ({
    ...state,
    loadingCustomerPay: false
  })
};
export default reducerFromMap(reducer, initialState);
