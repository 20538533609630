// import { Layout, Button } from "antd";
import React, { useState, useEffect } from "react";

import Localize from "../../libs/localization";
import { toHex } from "../../libs/utils";
import { ReactComponent as JLogo } from "../../assets/icons/j_logo.svg";

import "./system.style.scss";
import Button from "../../components/Button/Button";

const ErrorPage = ({
  error,
  dismiss,
  closeToHome = false,
  isInfo = false,
  forceLogin,
  goTo
}) => {
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    document.body.style = "overflow: hidden";
    setSeeMore(false);
    return () => (document.body.style = "");
  }, []);

  const { id, message, code } = error || {};

  return (
    <div className="system-error animation fadein-up fast">
      <div className="system-page">
        <div className="system-box animation fadein fast">
          <div className="logo">
            <JLogo />
          </div>
          {!isInfo && (
            <div className="message-head">
              {(!message || !code) && (
                <>
                  {Localize("ERROR.UNEXPECTED")}
                  <br />
                </>
              )}
              {!isInfo && (message || id) && code && message}
            </div>
          )}
          {isInfo && <div className="message-head info">{code}</div>}
          {isInfo && code && (
            <div className="info code-error animation fadein fast">
              {message}
            </div>
          )}
          {!seeMore && typeof dismiss === "function" && (
            <div className="action">
              <Button
                hideHover={true}
                onClick={() => {
                  dismiss();
                  if (closeToHome && typeof goTo === "function") goTo("/");
                }}
                size={"M"}
                label={
                  closeToHome
                    ? Localize(`ERROR.GET_BACK_HOME`)
                    : Localize(`ERROR.CLOSE`)
                }
              />
            </div>
          )}
          {!isInfo && (
            <>
              {!seeMore && (
                <Button
                  hideHover={true}
                  noBorder={true}
                  onClick={() => setSeeMore(true)}
                  size={"S"}
                  label={Localize(`ERROR.WHAT_NOW`)}
                  style={{ marginTop: 10 }}
                />
              )}
              {seeMore && (
                <>
                  {!isInfo && id && <div className="code-error">ID: {id}</div>}
                  {!id && !code && (
                    <div className="code-error">
                      <small>{toHex(message)}</small>
                    </div>
                  )}
                  {!isInfo && (
                    <div style={{ marginTop: 10 }}>
                      {Localize("ERROR.WHAT_NOW_DESC")}
                    </div>
                  )}
                  {/* {code && (
                <div className="message error-box">
                  <code className="error-message">
                    id: {id}
                    <br />
                    {code}
                  </code>
                </div>
              )} */}
                  <div className="action">
                    {typeof dismiss === "function" && (
                      // <Button primary={true} onClick={dismiss} size={"S"} label={Localize(`ERROR.BACK_HOME`)} />
                      <Button
                        primary={true}
                        onClick={() => {
                          window.location.href = Localize(
                            `ERROR.CONTACT_US_LINK`
                          );
                        }}
                        size={"M"}
                        label={Localize(`ERROR.CONTACT_US`)}
                      />
                    )}
                    {typeof dismiss === "function" && (
                      <Button
                        hideHover={true}
                        onClick={() => {
                          dismiss();
                          if (closeToHome && typeof goTo === "function")
                            goTo("/");
                        }}
                        size={"M"}
                        style={{ marginTop: 10 }}
                        label={
                          closeToHome
                            ? Localize(`ERROR.GET_BACK_HOME`)
                            : Localize(`ERROR.CLOSE`)
                        }
                      />
                    )}
                    {typeof forceLogin === "function" && (
                      <Button
                        onClick={forceLogin}
                        noBorder
                        // textUnderline
                        style={{ marginTop: 20 }}
                        size={"S"}
                        label={Localize(`ERROR.LOGIN_AGAIN`)}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

// const mapStateToProps = ({ app }) => ({
//   errorRedux: app.error,
// });

// export default connect(mapStateToProps, {})(ErrorPage);
