import React from "react";
import _ from "lodash";
import getUuidByString from "uuid-by-string";
import IdleTimer from "react-idle-timer";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";

import routes from "./routes";

import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setOnLocalStorage
} from "./libs/helpers/localstorage";

import history from "./store/history";
import Router from "./libs/router";

import "./App.scss";
import Loading from "./components/Loading/Loading";
import ErrorBoundary from "./libs/ErrorBoundary";
import { authorizeAction } from "./actions/auth.actions";
import { ssoLoginApi } from "./api/auth.api";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    // this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidMount() {
    const { authorizeAction } = this.props;

    if (!_.has(getFromLocalStorage(), "app-run-id")) {
      const uuid = `${new Date().getTime()}-${getUuidByString(
        [
          navigator.platform,
          navigator.appVersion || navigator.userAgent,
          new Date().getTime()
        ].join(" ")
      )}`;
      setOnLocalStorage({ "app-run-id": uuid });
    }
    setOnLocalStorage({
      "app-launch-count":
        _.get(getFromLocalStorage(), "app-launch-count", 0) + 1
    });

    ssoLoginApi()
      .then(res => res.json())
      .then(res => {
        const { data } = res;
        if (!res.error && data && data.authorization_code) {
          authorizeAction({
            code: data.authorization_code
          });
        } else {
          removeFromLocalStorage("user");
          removeFromLocalStorage("token");
          removeFromLocalStorage(null, "user_buffer");
          if (window.header) {
            window.header.applyAnonymousSetup({
              loginUrl: `/login`
            });
          }
          throw new Error();
        }
      })
      .catch(e => {
        console.warn("sso", e);
      });
  }

  componentDidUpdate() {
    if (!_.has(getFromLocalStorage(), "app-run-id")) {
      const uuid = `${new Date().getTime()}-${getUuidByString(
        [
          navigator.platform,
          navigator.appVersion || navigator.userAgent,
          new Date().getTime()
        ].join(" ")
      )}`;
      setOnLocalStorage({ "app-run-id": uuid });
    }
  }

  componentDidCatch(error, info) {
    this.setState({ caughtError: true });
    console.log("[ERROR Catched]", error, info);
  }

  render() {
    const { authorized, user, loading } = this.props;
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          // onAction={this.onAction}
          debounce={250}
          timeout={90000}
        />
        {loading && !user && <Loading />}
        {/* {(user && (
          <h3>
            Hi {user.firstName} {user.lastName}!
          </h3>
        )) || <h3>Please wait... </h3>} */}
        {/*<code>{(user && JSON.stringify(user)) || ""}</code>*/}
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <Router routes={routes} authorized={authorized} />
          </ErrorBoundary>
        </ConnectedRouter>
      </>
    );
  }

  _onActive(e) {
    // console.log("user is active", e);
    // console.log("time remaining", this.idleTimer.getRemainingTime());
    // userInfo();
    // getCuboidListAction();
  }

  _onIdle(e) {
    //   console.log('user is idle', e);
    // console.log("last active", this.idleTimer.getLastActiveTime());
  }
}

const mapStateToProps = ({ auth }) => ({
  /* Sets the authorized flag on the routes object if an access_token is available */
  // authorized: false
  authorized: !!auth.user && !!auth.token,
  loading: auth.loading,
  user: auth.user
});

export default connect(mapStateToProps, { authorizeAction })(App);
