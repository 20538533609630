import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./CreateNewAddress.scss";
import Form from "../Form/Form";
import { Row, Col } from "../Grid";
import Localize from "../../libs/localization";
import TextInput from "../TextInput/TextInput";
import SelectBox from "../SelectBox/SelectBox";
import {
  getCountryList,
  getProvinceList,
  getMunicipalityListByProvince
} from "../../api/jservicesApi";
import Button from "../Button/Button";

export const CreateNewAddress = ({
  countries,
  provinces,
  initialData = {},
  onChange,
  onClickAddressEnrichment
}) => {
  const token = useSelector(({ auth }) => auth.token);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(initialData ? false : true);
  const [currentCountry, setCurrentCountry] = useState("IT");
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [tempAddress, setTempAddress] = useState(initialData);

  return (
    <div className={"j-create-address"}>
      <Form
        initialValues={tempAddress}
        onChange={({ values, errors, completed }) => {
          setTempAddress({ ...values, addressId: "temp_address" });
        }}
        required={[
          ...[
            "firstName",
            "lastName",
            "phone",
            "street",
            "city",
            "country",
            "countryCode",
            "postalCode"
          ],
          ...(currentCountry === "IT" ? ["state"] : [])
        ]}
      >
        {({ values, errors, completed, updateValue }) => (
          <>
            {initialData && !edit ? (
              <Row className="address-data">
                <Col className="pl-0">
                  <div className="name">
                    <b>
                      {initialData.firstName} {initialData.lastName}
                    </b>
                  </div>
                  <div className="street">
                    {initialData.street} - {initialData.postalCode}{" "}
                    {initialData.city}
                    {initialData.state ? ` (${initialData.state})` : ""}
                    {" - "}
                    {initialData.country}
                  </div>
                  {/* <div className="contact">00{initialData.phone}</div> */}
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col size={12} className={"my-4"}>
                    <TextInput
                      name="firstName"
                      required={true}
                      label={Localize("ADDRESS.FIRST_NAME")}
                      value={values.firstName}
                      onChange={value => {
                        setTouched(true);
                        updateValue({ key: "firstName", value });
                      }}
                    />
                  </Col>
                  <Col size={12} className={"my-4"}>
                    <TextInput
                      name="lastName"
                      required={true}
                      label={Localize("ADDRESS.LAST_NAME")}
                      value={values.lastName}
                      onChange={value => {
                        setTouched(true);
                        updateValue({ key: "lastName", value });
                      }}
                    />
                  </Col>
                  <Col size={{ lg: 12, sm: 24, xs: 24 }} className={"my-4"}>
                    <TextInput
                      placeholder="-"
                      name="phone"
                      required={true}
                      label={Localize("ADDRESS.PHONE")}
                      value={values.phone}
                      error={!!errors.phone}
                      type={"phone"}
                      onChange={value => {
                        setTouched(true);
                        if (value === "") {
                          value = null;
                        }
                        updateValue({
                          key: "phone",
                          value,
                          regex: /[A-Za-z0-9]{4,}/
                        });
                      }}
                    />
                  </Col>
                  <Col size={24} className={"my-4"}>
                    <TextInput
                      name="street"
                      required={true}
                      label={Localize("ADDRESS.STREET")}
                      value={values.street}
                      onChange={value => {
                        setTouched(true);
                        updateValue({ key: "street", value });
                      }}
                    />
                  </Col>
                  {/* <Col size={24} className={"my-4"}>
                  <TextInput
                    name="info"
                    label={Localize("ADDRESS.INFO")}
                    value={values.info}
                    onChange={(value) => {
                      setTouched(true);
                      updateValue({ key: "info", value });
                    }}
                  />
                </Col> */}
                  <Col size={24} className={"my-4"}>
                    <SelectBox
                      name="country"
                      required={true}
                      options={countryList}
                      autocomplete={true}
                      label={Localize("ADDRESS.NATIONALITY")}
                      value={values.country}
                      fullObjectReturn={true}
                      loading={loading}
                      onChange={({ obj }) => {
                        setTouched(true);
                        updateValue({
                          key: "country",
                          value: obj.nameIt
                        });
                        updateValue({
                          key: "countryCode",
                          value: obj.iso2Code
                        });
                        setCurrentCountry(obj.iso2Code);
                        updateValue({
                          key: "state",
                          value: null
                        });
                        updateValue({
                          key: "subAdministrativeArea",
                          value: null
                        });
                        updateValue({ key: "city", value: null });
                        updateValue({
                          key: "cityCode",
                          value: null
                        });
                        updateValue({ key: "zip", value: null });
                        updateValue({ key: "postalCode", value: null });
                      }}
                      onAutocompleteChange={async value => {
                        if (value && value.length >= 2) {
                          setLoading(true);
                          const res = await getCountryList(
                            token,
                            (value || "").toUpperCase()
                          );
                          setLoading(false);
                          setCountryList(res);
                        }
                      }}
                    />
                  </Col>
                  {values.countryCode === "IT" ? (
                    <Col size={24} className={"my-4"}>
                      <SelectBox
                        name="state"
                        required={true}
                        options={provinceList}
                        autocomplete={true}
                        label={Localize("ADDRESS.PROVINCE")}
                        value={values.state}
                        fullObjectReturn={true}
                        loading={loading}
                        onChange={({ obj }) => {
                          setTouched(true);
                          updateValue({
                            key: "state",
                            value: obj.value || obj.name
                          });
                          updateValue({
                            key: "subAdministrativeArea",
                            value: obj.region
                          });
                          updateValue({ key: "provinceId", value: obj.id });
                        }}
                        onAutocompleteChange={async value => {
                          if (value && value.length > 2) {
                            setLoading(true);
                            const res = await getProvinceList(
                              token,
                              (value || "").toUpperCase()
                            );
                            setLoading(false);
                            setProvinceList(res);
                          }
                        }}
                      />
                    </Col>
                  ) : (
                    <Col size={24} className={"my-4"}>
                      <TextInput
                        name="state"
                        required={false}
                        label={Localize("ADDRESS.PROVINCE")}
                        disabled={!values.country}
                        value={values.state}
                        onChange={value => {
                          setTouched(true);
                          updateValue({ key: "state", value });
                          updateValue({ key: "subAdministrativeArea", value });
                        }}
                      />
                    </Col>
                  )}
                  {values.countryCode === "IT" && values.state ? (
                    <Col size={{ lg: 18, sm: 24, xs: 24 }} className={"my-4"}>
                      <SelectBox
                        name="city"
                        required={true}
                        options={municipalityList}
                        autocomplete={true}
                        filterList={true}
                        label={Localize("ADDRESS.CITY")}
                        value={values.city}
                        fullObjectReturn={true}
                        loading={loading}
                        onChange={({ obj }) => {
                          setTouched(true);
                          updateValue({ key: "city", value: obj.name });
                          updateValue({
                            key: "cityCode",
                            value: obj.nationalCode
                          });
                        }}
                        onAutocompleteChange={async value => {
                          if (value) {
                            setLoading(true);
                            const res = await getMunicipalityListByProvince(
                              token,
                              values.provinceId
                            );
                            setLoading(false);
                            setMunicipalityList(res);
                          }
                        }}
                      />
                    </Col>
                  ) : (
                    <Col size={{ lg: 18, sm: 24, xs: 24 }} className={"my-4"}>
                      <TextInput
                        name="city"
                        required={true}
                        label={Localize("ADDRESS.CITY")}
                        disabled={
                          currentCountry === "IT" ? !values.state : false
                        }
                        value={values.city}
                        onChange={value => {
                          setTouched(true);
                          updateValue({ key: "city", value });
                        }}
                      />
                    </Col>
                  )}

                  <Col size={{ lg: 6, sm: 12, xs: 12 }} className={"my-4"}>
                    <TextInput
                      name="zip"
                      required={true}
                      disabled={!values.city}
                      label={Localize("ADDRESS.CAP")}
                      value={values.zip || values.postalCode}
                      onChange={value => {
                        if ((value || "").length < 10) {
                          setTouched(true);
                          updateValue({ key: "zip", value });
                          updateValue({ key: "postalCode", value });
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col size={24} />
            </Row>
            <Row>
              <Col
                size={{ lg: 12, md: 12, sm: 24, xs: 24 }}
                className={"my-4 p-0 pr-lg-2 pr-md-2"}
              >
                <Button
                  style={{ width: "100%" }}
                  size="M"
                  label={Localize(`CART.SELECTION.ADD_ADDRESS`)}
                  onClick={e =>
                    (typeof onClickAddressEnrichment === "function" &&
                      onClickAddressEnrichment()) ||
                    null
                  }
                />
              </Col>
              {initialData && !edit ? (
                <Col
                  size={{ lg: 12, md: 12, sm: 24, xs: 24 }}
                  className={"my-4 p-0 pl-lg-2 pl-md-2"}
                >
                  {/* {touched ? "TRUE" : "FALSE"} */}
                  <Button
                    style={{ width: "100%" }}
                    primary={true}
                    size="M"
                    label={Localize(`CART.SELECTION.CHANGE_TEMP_ADDRESS`)}
                    disabled={false}
                    onClick={e => {
                      setEdit(true);
                    }}
                  />
                </Col>
              ) : (
                <Col
                  size={{ lg: 12, md: 12, sm: 24, xs: 24 }}
                  className={"my-4 p-0 pl-lg-2 pl-md-2"}
                >
                  {/* {touched ? "TRUE" : "FALSE"} */}
                  <Button
                    style={{ width: "100%" }}
                    primary={true}
                    size="M"
                    label={Localize(`CART.SELECTION.CONFIRM_ADDRESS`)}
                    disabled={!completed}
                    // disabled={!touched || !completed}
                    onClick={e => {
                      if (typeof onChange === "function" && touched) {
                        onChange(values, completed);
                      }
                      setEdit(false);
                      setTouched(false);
                    }}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};
