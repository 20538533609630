import callApi from "../libs/helpers/callApi";
import { redeemGiftApi } from "../api/customer.api";

export const CUSTOMER_LOADING = "CUSTOMER_LOADING";
export const CUSTOMER_LOADED = "CUSTOMER_LOADED";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";

export const redeemGiftAction = callApi({
  apiCall: p => redeemGiftApi(p),
  secured: true,
  startAction: CUSTOMER_LOADING,
  errorAction: CUSTOMER_ERROR,
  successAction: CUSTOMER_LOADED
});
