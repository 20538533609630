import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";

const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<MainApp />, rootElement);
// } else {
render(<MainApp />, rootElement);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
