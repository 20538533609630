import React, { Component } from "react";
import { connect } from "react-redux";
import { goTo } from "../../actions/app.actions";

import icons from "../../assets/icons";

import "./CartFab.scss";
import { getCartContentAction } from "../../actions/cart.actions";
import { isEmpty, isEqual } from "lodash";
import Snackbar from "../Snackbar/Snackbar";
import Button from "../Button/Button";
import Localize from "../../libs/localization";

class CartFab extends Component {
  state = {
    showSnackbar: false,
    snackbarMessage: undefined
  };

  componentDidMount() {
    const { getCartContentAction, authorized, token } = this.props;
    if (authorized || token) getCartContentAction();
  }

  componentDidUpdate(prevProps) {
    const {
      getCartContentAction,
      token,
      user,
      itemsQty,
      showSnackbarOnNotEmptyCart = true,
      showSnackbarOnItemChange = true,
      cartNotEmptyMessage = name =>
        name
          ? Localize("CART.RESUME_WHERE_YOU_LEFT_NAME", { name })
          : Localize("CART.RESUME_WHERE_YOU_LEFT"),
      cartChangedMessage = () => Localize("CART.MODIFIED_CART")
    } = this.props;
    if (!isEqual(prevProps.token, token) && token) {
      getCartContentAction();
    }
    if (
      !prevProps.itemsQty &&
      itemsQty &&
      showSnackbarOnNotEmptyCart &&
      itemsQty > 0 &&
      !isEmpty(user)
    ) {
      setTimeout(
        () =>
          this.setState({
            showSnackbar: true,
            snackbarMessage: cartNotEmptyMessage(user && user.firstName)
          }),
        1000
      );
    } else if (
      !!prevProps.itemsQty &&
      !isEqual(prevProps.itemsQty, itemsQty) &&
      showSnackbarOnItemChange
    ) {
      this.setState({ showSnackbar: false }, () => {
        this.setState({
          showSnackbar: true,
          snackbarMessage: cartChangedMessage()
        });
      });
    }
  }

  handleClose = () => {
    this.setState({ showSnackbar: false });
  };

  render() {
    const { items = [], goTo, light = false, user } = this.props;
    const { showSnackbar = false, snackbarMessage } = this.state;
    return (
      <>
        <div
          className={`j-cart-fab${light ? " light" : ""}`}
          onClick={() => goTo("/cart")}
        >
          <div className="show-on-hover">{Localize("CART.CART")}</div>
          <div className="cart-icon">
            <icons.cartFab style={{ height: "1.5em" }} />
          </div>
          {items.length > 0 && (
            <div className="cart-icon">
              <div className="items">{items.length}</div>
            </div>
          )}
        </div>
        <Snackbar
          open={showSnackbar}
          handleClose={this.handleClose}
          message={
            <>
              <icons.cartFab style={{ height: "1em" }} />
              <p>{snackbarMessage}</p>
              <Button
                label={Localize("CART.OPEN_CART")}
                size="S"
                primary={false}
                noBorder={true}
                hideHover={true}
                textUnderlineHover={true}
                role="link"
                style={{
                  borderLeft: "1px solid rgba(0,0,0,0.1)",
                  marginLeft: 10,
                  paddingLeft: 10,
                  paddingRight: 10
                }}
                onClick={() => goTo("/cart")}
              />
            </>
          }
          autoHideDuration={10000}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, cart }) => ({
  token: auth.token,
  items: cart.data ? cart.data.items : [],
  itemsQty: cart.data ? cart.data.itemsQty : undefined,
  user: auth.user
});

const mapDispatchToProps = {
  getCartContentAction,
  goTo
};

export default connect(mapStateToProps, mapDispatchToProps)(CartFab);
