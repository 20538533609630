import moment from "moment";

import Config from "../Config";
import { getCurrentLocale } from "../libs/localization";

const { apiBaseurl, apis } = Config;

export const getCountryList = async (token, search) => {
  const res = await fetch(
    `${apiBaseurl}/${apis.jservices.country}?name=${search}`,
    // `${apiBaseurl}/${apis.jservices.country}?name=${search}&language=${
    //   getCurrentLocale() === "it" ? "it" : "en"
    // }`,
    { method: "GET", headers: { Authorization: `Bearer ${token}` } }
  );
  if (res.ok) {
    const { data } = await res.json();
    return (data || []).map(c => ({
      id: c.idRec,
      label: getCurrentLocale() === "it" ? c.nameIt : c.nameEn,
      value: c.iso2Code,
      ...c
    }));
  } else {
    return [];
  }
};

export const getProvinceList = async (
  token,
  search,
  validityDate = moment().format("YYYY-MM-DD")
) => {
  const res = await fetch(
    `${apiBaseurl}/${apis.jservices.province}?name=${search}&validityDate=${validityDate}`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  if (res.ok) {
    const { data } = await res.json();
    return (data || []).map(p => ({
      id: p.idRec,
      label: p.name,
      value: p.acronym,
      ...p
    }));
  }
  return [];
};

export const getMunicipalityList = async (
  token,
  search,
  validityDate = moment().format("YYYY-MM-DD")
) => {
  const res = await fetch(
    `${apiBaseurl}/${apis.jservices.municipality}?name=${search}&validityDate=${validityDate}`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  if (res.ok) {
    const { data } = await res.json();
    return (data || []).map(m => ({
      id: m.idRec,
      label: m.transliteratedName,
      value: m.nationalCode,
      ...m
    }));
  }
  return [];
};

export const getMunicipalityListByProvince = async (
  token,
  idProvince,
  validityDate = moment().format("YYYY-MM-DD")
) => {
  const res = await fetch(
    `${apiBaseurl}/${apis.jservices.municipality}/${idProvince}?validityDate=${validityDate}`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` }
    }
  );
  if (res.ok) {
    const { data } = await res.json();
    return (data || []).map(m => ({
      id: m.idRec,
      label: m.transliteratedName,
      value: m.nationalCode,
      ...m
    }));
  }
  return [];
};

export const isValidCF = async (
  token,
  {
    surName,
    name,
    gender,
    birthDate,
    birthCountry,
    birthCountryCode,
    fiscalCode
  }
) => {
  //surName=ROSSI&name=MARCO&gender=M&birthDate=02/05/1970&birthCountry=Torino&birthCountryCode=IT&fiscalCode=RSSMRC70E02L219Z%0A'

  const res = await fetch(
    `${apiBaseurl}/${apis.jservices.cfvalidate}?surName=${surName}&name=${name}&gender=${gender}&birthDate=${birthDate}&birthCountry=${birthCountry}&birthCountryCode=${birthCountryCode}&fiscalCode=${fiscalCode}`,
    { method: "GET", headers: { Authorization: `Bearer ${token}` } }
  );
  const data = await res.json();
  if (res.ok && data.code === 0) {
    return null;
  } else {
    const { status } = res;
    const { message, errorData } = data;
    let messageInt = errorData && errorData.message;
    return status !== 500 ? messageInt || message : "error";
  }
};
