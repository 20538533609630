import { isEqual } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { goTo } from "../../actions/app.actions";
import { authorizeAction } from "../../actions/auth.actions";
import { ssoLoginApi } from "../../api/auth.api";
import Loading from "../../components/Loading/Loading";
import Config from "../../Config";
import { PageView } from "../../libs/helpers/gtm";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setOnLocalStorage
} from "../../libs/helpers/localstorage";

class LoginPage extends Component {
  componentDidMount() {
    const { location, authorizeAction } = this.props;

    const { search } = location;
    const URLparams = new URLSearchParams(search);
    const redirectUri = URLparams.get("redirectUri");
    setOnLocalStorage({ redirectUri });

    ssoLoginApi()
      .then(res => res.json())
      .then(res => {
        const { data } = res;
        if (!res.error && data && data.authorization_code) {
          authorizeAction({
            code: data.authorization_code
          });
        } else {
          removeFromLocalStorage("user");
          removeFromLocalStorage("token");
          removeFromLocalStorage(null, "user_buffer");
          if (window.header) {
            window.header.applyAnonymousSetup({
              loginUrl: `/login`
            });
          }
          throw new Error();
        }
      })
      .catch(e => {
        const loginUrl = `${Config.apiBaseurl}/${Config.apis.oauth.login}?redirect_uri=${Config.appBaseurl}/authorize`;
        PageView(loginUrl);
        window.location = loginUrl;
      });
  }

  componentDidUpdate(prevProps) {
    const { user, goTo } = this.props;
    if (user && !isEqual(prevProps.user, user)) {
      const redirectUri = getFromLocalStorage("redirectUri");
      if (redirectUri) {
        goTo(redirectUri);
      } else {
        goTo("/");
      }
    }
  }

  render() {
    return <Loading coverBack={true} />;
  }
}

export default connect(({ auth }) => ({ user: auth.user }), {
  authorizeAction,
  goTo
})(LoginPage);
