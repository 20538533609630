import Config from "../Config";
import {
  removeFromLocalStorage,
  setOnLocalStorage
} from "./helpers/localstorage";
import { getCurrentLocale } from "./localization";

export const setLoggedUser = (user, token) => {
  const locale = getCurrentLocale() || "en";
  if (window.header) {
    try {
      window.header.applyLoggedSetup({
        avatarUrl: `${Config.apiBaseurl}/${Config.apis.oauth.profileImage}?token=${token}`,
        profileUrl: `${Config.authBaseurl}/${locale}/${
          Config.apis.oauth.dashboard
        }?redirect_uri=${encodeURIComponent(window.location.href)}`,
        logoutUrl: "/logout/do"
      });
      removeFromLocalStorage(null, "user_buffer");
    } catch (e) {
      console.error(e);
    }
  } else {
    setOnLocalStorage({ user, token }, "user_buffer");
  }
};
