import { reducerFromMap } from "../libs/helpers/reducers";

import {
  AUTHORIZE_DONE,
  USERINFO_LOADED,
  USERINFO_LOADING
} from "../actions/auth.actions";
import { setLoggedUser } from "../libs/jcomFunctions";

const reducer = {
  [AUTHORIZE_DONE]: (state, actions) => {
    setLoggedUser(actions.data.user, actions.data.token);
    return {
      ...state,
      loading: false,
      user: actions.data.user,
      token: actions.data.token
    };
  },
  [USERINFO_LOADING]: (state, actions) => ({
    ...state,
    loading: true
    // user: null,
  }),
  [USERINFO_LOADED]: (state, actions) => {
    setLoggedUser(actions.data, state.token);
    return {
      ...state,
      loading: false,
      user: actions.data
    };
  }
};

export default reducerFromMap(reducer);
