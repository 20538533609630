import React, { useState } from "react";

import { ReactComponent as Triangle } from "../../assets/icons/triangle.svg";

import _ from "lodash";
import "./SelectBox.scss";
import LabelText from "../LabelText/LabelText";
import TextInput from "../TextInput/TextInput";
import { useEffect } from "react";
import Localize from "../../libs/localization";
import Loading from "../Loading/Loading";

const SelectRow = ({
  label,
  value,
  obj,
  onClick,
  active = false,
  disabled = false,
  fullObjectReturn = false
}) => (
  <div
    className={`select-option${(active && " active") || ""}${(disabled &&
      " disabled") ||
      ""}`}
    onClick={() =>
      typeof onClick === "function" && !disabled
        ? fullObjectReturn
          ? onClick({ label, value, obj })
          : onClick({ label, value })
        : null
    }
  >
    {label}
  </div>
);

const SelectBox = ({
  label,
  labelProps,
  name,
  required = false,
  placeholder,
  options = [],
  onChange,
  onBlur,
  onAutocompleteChange,
  disabled = false,
  autocomplete = false,
  fullObjectReturn = false,
  filterList = false,
  value = null,
  loading = false
}) => {
  const [open, setOpen] = useState(false);
  const [textValue, changeTextValue] = useState(value);
  useEffect(() => {
    if (textValue && !value) {
      changeTextValue("");
    } else {
      changeTextValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const filteredOptions =
    textValue && textValue !== "" && autocomplete && filterList
      ? options.filter(
          o =>
            _.startsWith(_.toUpper(o.label), _.toUpper(textValue)) ||
            _.isEqual(_.toUpper(o.label), _.toUpper(textValue))
        )
      : options;
  const current = options.find(o => o.value === value);
  return (
    <div className={"select-group"}>
      {!disabled ? (
        <>
          <LabelText label={label} {...labelProps} required={required} />
          <div
            className={`select${(open && " opened") || ""}${(autocomplete &&
              " autocomplete") ||
              ""}`}
          >
            <span
              className="select-input"
              onClick={
                autocomplete ? () => setOpen(true) : () => setOpen(!open)
              }
              // onFocus={() => setOpen(true)}
              // onBlurCapture={alert}
            >
              {autocomplete ? (
                <input
                  autoComplete="new-password"
                  placeholder={placeholder}
                  disabled={disabled}
                  value={textValue}
                  onChange={({ currentTarget }) => {
                    changeTextValue(currentTarget.value);
                    if (typeof onAutocompleteChange === "function") {
                      onAutocompleteChange(currentTarget.value);
                    }
                  }}
                  // onBlur={typeof onBlur === "function" && onBlur}
                />
              ) : current ? (
                current.label
              ) : (
                placeholder
              )}
              {!autocomplete && (
                <span className="select-icon">
                  <Triangle />
                </span>
              )}
              {autocomplete && loading && (
                <span className="loading-icon">
                  <Loading fixed={false} fadeIn={false} />
                </span>
              )}
            </span>
            <div
              className="select-body"
              onBlurCapture={() => {
                if (!current || textValue !== current.label) {
                  changeTextValue("");
                }
              }}
            >
              {_.isEmpty(filteredOptions) &&
                textValue &&
                textValue !== "" &&
                !loading && (
                  <SelectRow
                    disabled={true}
                    label={Localize("COMMON.NO_DATA")}
                  />
                )}
              {filteredOptions.map((o, i) => (
                <SelectRow
                  {...o}
                  obj={o}
                  key={i}
                  fullObjectReturn={fullObjectReturn}
                  onClick={el => {
                    changeTextValue(el.label);
                    // onChange({ value: el.value, key: name });
                    onChange(
                      fullObjectReturn
                        ? { value: el.value, key: name, obj: el.obj }
                        : el.value
                    );
                    setOpen(!open);
                    typeof onBlur === "function" && onBlur();
                  }}
                  disabled={o.disabled || false}
                  active={value && value === o.value}
                />
              ))}
              {loading && (
                <SelectRow disabled={true} label={Localize("COMMON.LOADING")} />
              )}
            </div>
          </div>
        </>
      ) : (
        <TextInput
          name={name}
          required={required}
          label={label}
          value={(current && current.label) || textValue || "-"}
          disabled={true}
          onChange={() => null}
        />
      )}
    </div>
  );
};

export default SelectBox;
