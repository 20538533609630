import React from "react";
import _ from "lodash";

import PropTypes from "prop-types";
import "./CartBox.scss";
import { Col, Container, Row } from "../Grid";
import { currencyDecimal, isMobileDevice } from "../../libs/utils";
import Localize from "../../libs/localization";
import Button from "../Button/Button";

const _getTotalValue = items => {
  let total = 0;
  if (!items) return currencyDecimal(total);
  // items.forEach((item) => (total = total + item.product.price));
  total = (items || []).reduce((acc, item) => acc + item.rowTotal, 0);
  return currencyDecimal(total);
};

const CartBoxComponent = ({
  cartInfo = {},
  onCheckout,
  disabled = false,
  tooltip = null
}) => {
  const isOnMobile = isMobileDevice();
  const { voucher } = cartInfo;

  return (
    <Container className={"j-cart-box"}>
      {cartInfo && (
        <Row>
          <Col size={24}>
            <span className={"title-label d-sm-none"}>
              {Localize("CART.YOUR_CART")}
            </span>
            {/* <span className={"action-label"}>{Localize("CART.MODIFY")}</span> */}
          </Col>
          {cartInfo &&
            !isOnMobile &&
            cartInfo.items &&
            cartInfo.items.length > 0 &&
            cartInfo.items.map((el, i) => (
              <Col key={i} size={24} className={"card-label padding-top-18"}>
                <span>
                  {el.gift
                    ? Localize("COMMON.GIFT", [el.product.name])
                    : _.get(
                        el,
                        `configuration.products[${el.product.id}].renewalOf`
                      )
                    ? Localize("COMMON.RENEW", [el.product.name])
                    : el.product.name}
                </span>
                <span className={"float-right"}>
                  {currencyDecimal(el.rowTotal)}
                </span>
              </Col>
            ))}
          <Col size={24} className={"separator d-sm-none"} />
          {/* <Col size={24} className={"text-right normal-label d-sm-none"}>
            {Localize("CART.TOTAL")}
            <span className={"d-md-none"}> : </span>
            <span className={"price-span ml-3"}>{_getTotalValue(cartInfo.items)}</span>
          </Col> */}

          <Col
            size={{ xs: 12, sm: 12, md: 24 }}
            className={"text-right normal-label pt-md-3 no-wrap"}
          >
            <div>
              {Localize("CART.TOTAL")}
              <span className={"d-md-none"}> : </span>
              <span className={"price-span ml-md-3"}>
                {currencyDecimal(cartInfo.grandTotal || 0)}
              </span>
            </div>

            {voucher && (
              <div>
                {Localize(
                  "CART.VOUCHER",
                  voucher.product && voucher.product.name
                    ? [` ${voucher.product.name}`]
                    : [""]
                )}
                <span className={"d-md-none"}> : </span>
                <span className={"price-span ml-md-3"}>
                  {voucher.discountType === "percentage"
                    ? `-${voucher.amount}%`
                    : currencyDecimal(voucher.amount || 0)}
                </span>
              </div>
            )}
            <div>
              {Localize("CART.PRICE_SHIPPING")}
              <span className={"d-md-none"}> : </span>
              <span className={"price-span ml-md-3"}>
                {currencyDecimal(cartInfo.shippingCost || 0)}
              </span>
            </div>
          </Col>
          <Col size={24} className={"separator d-sm-none"} />

          <Col
            size={{ xs: 12, sm: 12, md: 24 }}
            className={"text-right price-label no-wrap px-0"}
          >
            <div className={"bottom-text"}>
              {Localize("CART.TOTAL_LABEL")}
              <span className={"price-total-span"}>
                {currencyDecimal(cartInfo.grandTotal || 0)}
              </span>
            </div>
          </Col>
          <Col size={24} className={"padding-mobile px-0"}>
            <Button
              size={"XL"}
              primary={true}
              style={{ width: "100%" }}
              disabled={disabled}
              label={Localize(
                isOnMobile ? "CART.CONFIRM_MOBILE" : "CART.CONFIRM"
              )}
              onClick={typeof onCheckout === "function" ? onCheckout : null}
            />
            {tooltip && disabled && (
              <span
                className={`tooltip bottom ${
                  isOnMobile ? "solid-bg mx-auto p-2 mt-3" : "mt-4"
                }`}
                style={{ width: isOnMobile ? "50vw" : "inherit" }}
              >
                {tooltip}
              </span>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

CartBoxComponent.propTypes = {
  cartInfo: PropTypes.object
};

CartBoxComponent.defaultProps = {};

export default CartBoxComponent;
