import callApi from "../libs/helpers/callApi";
import { paymentApi, checkoutApi } from "../api/checkout.api";

export const CHECKOUT_DOING = "CHECKOUT_DOING";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";
export const CHECKOUT_DONE = "CHECKOUT_DONE";

export const checkoutAction = callApi({
  apiCall: p => checkoutApi(p),
  secured: true,
  startAction: CHECKOUT_DOING,
  errorAction: CHECKOUT_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: CHECKOUT_DONE, data: result });
    }
  }
});

export const PAYMENT_STARTED = "PAYMENT_STARTED";
export const PAYMENT_DONE = "PAYMENT_DONE";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const paymentAction = callApi({
  apiCall: p => paymentApi(p),
  secured: false,
  // secured: true, // AUTH REMOVED TO PREVENT AIC REFRESH ERRORS
  startAction: PAYMENT_STARTED,
  processResult: (result, dispatch, getState, params) => {
    const { response, ok } = result;
    if (ok) {
      dispatch({ type: PAYMENT_DONE, data: response });
    } else {
      dispatch({ type: PAYMENT_ERROR, data: response });
    }
  }
});
