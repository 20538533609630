import { push } from "connected-react-router";

export const APP_START = "@@APP_START";
export const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const SET_SIDEBAR_STATUS = "SET_SIDEBAR_STATUS";
export const LOGOUT = "LOGOUT";
export const SET_ERROR = "SET_ERROR";

export const goTo = url => dispatch => {
  dispatch(push(url));
};

export const setStatusBarAction = status => {
  return {
    type: SET_SIDEBAR_STATUS,
    status
  };
};

export const setErrorAction = ({
  name,
  status,
  message,
  id,
  date,
  code,
  closeToHome = false,
  isInfo = false
}) => ({
  type: SET_ERROR,
  error: { name, status, message, id, date, code, closeToHome, isInfo }
});

export const resetErrorAction = () => ({
  type: SET_ERROR,
  error: null
});
