import React from "react";
import _ from "lodash";

import PropTypes from "prop-types";
import "./Cart.scss";
import { Col, Container, Row } from "../Grid";
import CartItem from "../CartItem/CartItem";
import Localize from "../../libs/localization";
import Button from "../Button/Button";
import { ReactComponent as CartIcon } from "../../assets/icons/cart_icon.svg";
import { currencyDecimal, isMobileDevice } from "../../libs/utils";

const _getTotalValue = items => {
  let total = 0;
  if (!items) return total;
  items.forEach(item => (total = total + item.rowTotal));
  return currencyDecimal(total);
};

const _canIContinue = items => {
  let completed = true;
  _.map(items, (el, i) => {
    if (
      (!el.product.virtual ||
        _.some(el.product.groupedProducts, gp => !gp.virtual)) &&
      !el.shippingAddress
    ) {
      completed = false;
    }
  });
  return completed;
};

const CartComponent = ({
  cartInfo,
  user,
  addressOptions = [],
  addressChange,
  addressEnrichment,
  updateItemToCart,
  deleteItemToCart,
  modifyItemInCart,
  setWaiverOfWithdraw,
  goToCheckout,
  continuePurchase,
  countries,
  provinces
}) => {
  const isOnMobile = isMobileDevice();

  return (
    <Container className={"j-cart"}>
      <Row className={"d-sm-none d-xs-none"}>
        {isOnMobile && <Col size={{ sm: 6 }}>{Localize(`CART.CART`)}</Col>}
      </Row>
      {cartInfo && cartInfo.items.length > 0 && !isOnMobile && (
        <Row className={"padding-top"}>
          <Col size={{ xl: 20 }} offset={{ xl: 4 }}>
            <Row>
              <Col size={12} className={"headerCartItems pl-0"}>
                {Localize(`CART.ITEM.TITLE`)}
              </Col>
              <Col size={12} className={"headerCartItems"}>
                {Localize(`CART.ITEM.PRICE`)}
              </Col>
            </Row>
          </Col>
          <Col
            size={{ xl: 20 }}
            offset={{ xl: 4 }}
            className={"separator my-4"}
          />
        </Row>
      )}
      {cartInfo &&
        cartInfo &&
        cartInfo.items.length > 0 &&
        _.orderBy(_.get(cartInfo, "items", []), "id").map((el, i) => (
          <CartItem
            key={i}
            id={el.id}
            item={el}
            user={user}
            updateItemToCart={updateItemToCart}
            deleteItemToCart={deleteItemToCart}
            modifyItemInCart={modifyItemInCart}
            countries={countries}
            provinces={provinces}
            addressOptions={addressOptions}
            onSelectAddress={addressChange}
            onClickAddressEnrichment={addressEnrichment}
            setWaiverOfWithdraw={setWaiverOfWithdraw}
          />
        ))}
      {cartInfo && cartInfo.items.length > 0 && (
        <>
          <Row
            className={
              isOnMobile
                ? "alignBottom total-box-mobile mt-xs-0 mt-sm-0"
                : "alignBottom mt-5 mt-md-1"
            }
          >
            <Col
              size={{ xs: 12, sm: 12, md: 12, lg: 13, xl: 15 }}
              className={isOnMobile ? "totalLabel" : "totalLabel text-right"}
            >
              {Localize(`CART.TOTAL`)}
            </Col>
            <Col
              size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }}
              className={
                isOnMobile
                  ? "col-lg-auto priceLabel text-right"
                  : "col-lg-auto priceLabel"
              }
            >
              {_getTotalValue(_.get(cartInfo, "items"))}
            </Col>
            <Col size={{ lg: "auto", md: 6, xs: 24, sm: 24 }}>
              <Button
                primary={true}
                label={Localize(`CART.CHECKOUT`)}
                disabled={cartInfo && !_canIContinue(cartInfo.items)}
                onClick={goToCheckout}
              />
              {cartInfo && !_canIContinue(cartInfo.items) && (
                <span
                  className={isOnMobile ? "tooltip bottom" : "tooltip"}
                  style={{
                    backgroundColor: "#fff",
                    ...(isOnMobile
                      ? {
                          position: "relative",
                          paddingBottom: 10,
                          display: "block"
                        }
                      : {})
                  }}
                >
                  {Localize("CART.ITEM.COMPLETE_ADDRESSES")}
                </span>
              )}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col size={{ xl: 20 }} offset={{ xl: 4 }}>
              <span style={{ color: "var(--red-alert)" }}>*</span>
              {Localize("COMMON.REQUIRED_INFO")}
            </Col>
          </Row>
        </>
      )}
      {cartInfo && cartInfo.items.length === 0 && (
        <Row className={"no-item-box"}>
          <Col size={24}>
            <div className={"no-item-label"}>{Localize(`CART.NO_ITEM`)}</div>
            <div className={"icon-box"}>
              <CartIcon />
            </div>
            <div className={"button-no-element"}>
              <Button
                primary={true}
                size={"XL"}
                onClick={continuePurchase}
                label={Localize(`CART.CONTINUE_PURCHASE`)}
              />
            </div>
          </Col>
          {isOnMobile && <div style={{ height: "125px" }} />}
        </Row>
      )}
    </Container>
  );
};

CartComponent.propTypes = {
  cartInfo: PropTypes.object,
  addressOptions: PropTypes.array,
  createBillingAddress: PropTypes.func,
  addressEnrichment: PropTypes.func,
  updateItemToCart: PropTypes.func,
  deleteItemToCart: PropTypes.func,
  goToCheckout: PropTypes.func
};

CartComponent.defaultProps = {};

export default CartComponent;
