import Config from "../Config";
import ErrorMessage from "../libs/helpers/Error";

/**
 * get the current cart content
 * @param params
 * @returns {Promise<*>}
 */
export const getCartContentApi = async ({ data, headers }) => {
  // const qs = [
  //   `relations[]=items.product.images`,
  //   `relations[]=items.product.upgradeTo`,
  //   `relations[]=items.product.upgradeTo.images`,
  //   `sort={"order.id": "DESC"}`
  // ];
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.cart.details}`,
    // `${Config.apiBaseurl}/${Config.apis.cart.details}?${qs.join("&")}`,
    {
      method: "GET",
      headers,
      body: JSON.stringify(data)
    }
  );
  try {
    const response = await res.json();

    if (res.ok) {
      return response;
    } else {
      const { statusText, status } = res;
      const { id, message, label, code } = response;
      throw new ErrorMessage(statusText, label || message, status, code, id);
    }
  } catch (e) {
    return null;
  }
};

/**
 * add a product in the cart
 * @param params where data is the current product
 */
export const addItemsToCartApi = async ({ data, headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.cart.items}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(data)
  });

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export const createBillingAddressApi = async ({ data, headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.cart.billingAddress}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export const updateItemToCartApi = async ({ data, headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.cart.items}/${id}`,
    {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export const deleteItemToCartApi = async ({ data, headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.cart.items}/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 * call paymentMethods wrap ADYEN
 * @param params
 * @returns {Promise<*>}
 */
export const paymentMethodsApi = async ({ data, headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.checkout.paymentMethods}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 * call paymentMethods wrap ADYEN
 * @param params
 * @returns {Promise<*>}
 */
export const paymentMethodDeleteApi = async ({ id, headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.checkout.paymentMethodsCustomer}/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      }
    }
  );

  if (res.ok) {
    return true;
  } else {
    const { statusText, status } = res;
    const response = await res.json();
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 * call paymentMethods for Customer
 * @param params
 * @returns {Promise<*>}
 */
export const paymentMethodsCustomerApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.checkout.paymentMethodsCustomer}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      }
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

export const redeemVoucherApi = async ({ code, headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.cart.voucher}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify({ code })
  });

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 * initialized renewal cart item
 */
export const generateRenewalItemApi = async ({ ms, headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.cart.details}/renewal?ms=${ms}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      }
    }
  );
  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};
