import { reducerFromMap } from "../libs/helpers/reducers";

import {
  CUSTOMER_LOADED,
  CUSTOMER_ERROR,
  CUSTOMER_LOADING
} from "../actions/customer.actions";

const reducer = {
  [CUSTOMER_LOADING]: (state, actions) => ({
    ...state,
    loading: true
  }),
  [CUSTOMER_LOADED]: (state, actions) => ({
    ...state,
    loading: false,
    error: null
    // user: null,
  }),
  [CUSTOMER_ERROR]: (state, actions) => ({
    ...state,
    loading: false,
    error: actions.data
  })
};

export default reducerFromMap(reducer);
