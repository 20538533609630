import Config from "../Config";
import { getFromLocalStorage } from "../libs/helpers/localstorage";
import ErrorMessage from "../libs/helpers/Error";

const localStorageLocale = getFromLocalStorage("lang");
let locale =
  (localStorageLocale && localStorageLocale.data) || Config.forceLocale || "en";
/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export const getCountriesApi = async params => {
  let { data, headers } = params;
  const res = await fetch(
    `${Config.authBaseurl}/fiscal-code/country?language=${locale}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};

/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export const getProvincesApi = async params => {
  let { data, headers } = params;
  const res = await fetch(
    `${Config.authBaseurl}/fiscal-code/province?language=${locale}`,
    {
      method: "GET",
      headers,
      body: JSON.stringify(data)
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { id, message, label, code } = response;
    throw new ErrorMessage(statusText, label || message, status, code, id);
  }
};
