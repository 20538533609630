import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import MetaTags from "../components/MetaTags/MetaTags";
import CartFab from "../components/CartFab/CartFab";
import {
  removeFromLocalStorage,
  getFromLocalStorage
} from "./helpers/localstorage";
import Loading from "../components/Loading/Loading";
import CouponFab from "../components/CouponFab/CouponFab";

export const type = {
  PUBLIC: "PUBLIC",
  SECURED: "SECURED",
  AUTHENTICATION: "AUTHENTICATION"
};

export const getFlatRouteList = routes => {
  const routeList = [];
  routes.map((route, i) => {
    if (route.path) {
      routeList.push(route);
    }
    if (route.children && route.children.length > 0) {
      route.children.map((child, j) => {
        child.parent_id = route.id;
        if (child.path) {
          routeList.push(child);
        }
        return child;
      });
    }
    return route;
  });
  return routeList;
};

const Router = ({ routes = null, authorized = false }) =>
  routes ? (
    <>
      <Switch>
        {routes.map((r, i) => (
          <Route
            key={i}
            path={[r.path, ...(r.additionalPaths || [])]}
            exact={r.exact || true}
            render={props => {
              const { location, match } = props;
              const { pathname, hash, search } = location;
              const nextRedirectUri = `${pathname || match.url}${hash ||
                ""}${search || ""}`;
              const ls = getFromLocalStorage();
              const redirectUri = ls && ls.redirectUri;
              if (r.redirectToExternal) {
                window.location = r.redirectToExternal;
                return <Loading />;
              } else if (r.redirectTo) {
                return (
                  <Redirect
                    to={`${r.redirectTo}${hash || ""}${search || ""}`}
                  />
                );
              } else if (!authorized && r.type === type.SECURED) {
                return (
                  <Redirect
                    to={`/login?redirectUri=${encodeURIComponent(
                      nextRedirectUri
                    )}`}
                  />
                );
              } else if (authorized && r.type === type.AUTHENTICATION) {
                removeFromLocalStorage("redirectUri");
                return <Redirect to={decodeURIComponent(redirectUri) || "/"} />;
              } else {
                if (!r.component) {
                  return <Loading />;
                }
                return (
                  <>
                    <MetaTags
                      title={`Juventus Membership${
                        r.title && typeof r.title === "string"
                          ? `-${r.title}`
                          : ""
                      }`}
                      noFollow={r.noFollow}
                    />
                    {r.cart && (
                      <CartFab
                        light={r.cart === "light"}
                        authorized={r.type === type.SECURED && authorized}
                      />
                    )}
                    {r.coupon && <CouponFab light={r.coupon === "light"} />}
                    <Suspense fallback={<Loading coverBack={true} />}>
                      <r.component {...props} />
                    </Suspense>
                  </>
                );
              }
            }}
          />
        ))}

        <Route key={-1} render={() => <Redirect to={"/"} />} />
      </Switch>
    </>
  ) : (
    <p>No routes</p>
  );

Router.propTypes = {
  prompt: PropTypes.shape({
    message: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
  })
};

export default withRouter(connect()(Router));
