import React, { useState, useEffect, createRef } from "react";
import "./FileDrop.scss";
import { Col, Row } from "../Grid";
import Button from "../Button/Button";
import LabelText from "../LabelText/LabelText";
import Localize from "../../libs/localization";

const MAX_FILE_SIZE = 1024 * 1024 * 4;

const FileDrop = props => {
  const [drag, setDrag] = useState(false);
  const [sizeAlert, setSizeAlert] = useState(false);
  const [filename, setFilename] = useState("");
  let dropRef = createRef();
  let fileRef = createRef();
  let dragCounter = 0;

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
  };

  const handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) setDrag(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handleDrop({ item: e.dataTransfer.files[0], type: props.type });
      const { size } = e.target.files[0];
      if (size <= MAX_FILE_SIZE) {
        setFilename(e.dataTransfer.files[0].name);
        e.dataTransfer.clearData();
        dragCounter = 0;
        setSizeAlert(false);
      } else {
        alert("File size " + size);
        setFilename(null);
        setSizeAlert(true);
      }
    }
  };

  const handleInput = e => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.target.files && e.target.files.length > 0) {
      const { size } = e.target.files[0];
      if (size <= MAX_FILE_SIZE) {
        setFilename(e.target.files[0].name);
        setSizeAlert(false);
        props.handleDrop({ item: e.target.files[0], type: props.type });
      } else {
        // alert("File size " + size);
        setSizeAlert(true);
        setFilename(null);
        fileRef.current.value = null;
      }
    }
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return function cleanup() {
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  });

  return (
    <>
      <div
        ref={dropRef}
        className={
          drag ? "filedrop drag" : filename ? "filedrop ready" : "filedrop"
        }
      >
        <Row className="p-2">
          <Col size={24}>{props.label}</Col>
          <Col size={24} className={"align-center"}>
            <LabelText
              className={"force-mobile-text"}
              label={Localize("COMMON.FILE_DROP_LABEL")}
            />
            {filename && !drag && <div className="icon file">{filename}</div>}
            <Button
              className={"file-drop-button mb-0"}
              onClick={() => document.getElementById(props.id).click()}
              label={Localize("COMMON.BROWSE")}
            />
            <input
              type={"file"}
              ref={fileRef}
              hidden={true}
              onChange={handleInput}
              id={props.id}
              accept="image/jpeg, image/png, application/pdf, application/msword"
            />
          </Col>
          {sizeAlert && (
            <Col size={24} className={"align-center file-alert"}>
              {Localize("COMMON.FILE_DROP_SIZE_PROBLEM", ["4Mb"])}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default FileDrop;
