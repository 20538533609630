import React, { useEffect, useState } from "react";

import "./CartItem.scss";
import { Col, Row } from "../Grid";
import CartItemDetail from "../CartItemDetail/CartItemDetail";
import Localize from "../../libs/localization";
import { memb_ph, jcard_ph } from "../../assets/images";

import { ReactComponent as Angle } from "../../assets/icons/angle.svg";
import PropTypes from "prop-types";
import {
  currencyDecimal,
  currencyMaxOneDecimal,
  isMobileDevice
} from "../../libs/utils";
import _, { isEmpty } from "lodash";
import AddressSelection from "../AddressSelection/AddressSelection";
import CheckMark from "../CheckMark/CheckMark";
import { ReactComponent as Coupon } from "./../../assets/icons/coupon.svg";

const CartItem = ({
  id,
  item = {},
  deleteItemToCart,
  modifyItemInCart,
  user,
  countries,
  provinces,
  addressOptions,
  onSelectAddress,
  onClickAddressEnrichment,
  setWaiverOfWithdraw
}) => {
  const isOnMobile = isMobileDevice();
  const [collapsed, setCollapsed] = useState(true);
  const [sel] = useState(null);
  const { product } = item;
  const codeName = _.get(product, "tags[0].code", null);
  const countryAvailable = _.get(
    item,
    "product.rules.countryRestrictions.available",
    []
  );
  const countryNotAvailable = _.get(
    item,
    "product.rules.countryRestrictions.not_available",
    []
  );

  const holderData =
    codeName !== "membership_upgrade"
      ? _.get(item, `configuration.products[${product.id}].holderData`) || user
      : null;

  return (
    <Row>
      <Col size={{ xl: 20 }} offset={{ xl: 4 }} className={"px-sm-0 px-xs-0"}>
        <div className={"j-cartItem"}>
          <Row className={"pt-4"}>
            <Col
              size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              className="px-0"
            >
              <Row>
                <Col
                  size={{ sm: 24, md: 6, lg: "auto", xl: "auto" }}
                  className={"pl-0 my-auto"}
                >
                  <img
                    className={"image-cart"}
                    alt={"logo"}
                    src={_.get(
                      codeName === "membership_upgrade"
                        ? _.get(product, "upgradeTo")
                        : product,
                      "images[0].url",
                      codeName === "jcard" ? jcard_ph : memb_ph
                    )}
                  />
                </Col>
                <Col
                  size={{ xs: 24, sm: 24, lg: "", xl: "" }}
                  className={"pl-sm-0 pr-sm-0 pl-xs-0 pr-xs-0 my-auto pl-md-0"}
                >
                  <Row className={""}>
                    <Col
                      size={24}
                      className={"sku-label px-0 pt-lg-0 pt-sm-3 pt-xs-3"}
                    >
                      {item.gift
                        ? Localize("COMMON.GIFT", [product.name])
                        : _.get(
                            item,
                            `configuration.products[${product.id}].renewalOf`
                          )
                        ? Localize("COMMON.RENEW", [product.name])
                        : product.name}
                    </Col>
                    <Col size={24} className={"px-0 pt-3"}>
                      <Row className={""}>
                        {!item.gift && (
                          <Col
                            size={{ xs: 24, sm: 24, lg: "auto", xl: "auto" }}
                            className={"ownerName p-0 pr-lg-2 my-auto"}
                          >
                            {holderData
                              ? `${holderData.firstName} ${holderData.lastName}`
                              : codeName === "membership_upgrade"
                              ? _.get(
                                  item,
                                  `configuration.products[${product.id}].membership.code`
                                )
                              : ""}
                          </Col>
                        )}
                        <Col
                          size={{ xs: 24, sm: 24, lg: "", xl: "" }}
                          className={
                            "ownerType pl-md-0 pr-0 pl-sm-0 pl-xs-0 my-auto"
                          }
                        >
                          ({product.tags[0].label})
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              className="my-lg-auto pb-md-5 pl-md-0 mt-sm-auto mb-sm-5"
            >
              <Row>
                <Col
                  size={{ xs: "", sm: "", md: "", lg: "" }}
                  className={
                    isOnMobile
                      ? "price-label text-right my-auto pr-sm-0 pr-xs-0"
                      : "price-label pl-0 my-auto"
                  }
                >
                  {item.price > 0 &&
                    (item.price !== item.rowTotal ? (
                      <>
                        <span className="old-price">
                          {currencyMaxOneDecimal(item.price)}
                        </span>
                        <span className="new-price">
                          {currencyDecimal(item.rowTotal)}
                        </span>
                      </>
                    ) : (
                      currencyDecimal(item.rowTotal)
                    ))}
                </Col>
                {!isOnMobile && (
                  <Col
                    size={{ xs: 6, sm: 6, md: "auto", lg: "auto" }}
                    className={"menuLabel my-auto my-0 my-md-4"}
                  >
                    <span
                      className={"pointer"}
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      {Localize(
                        `CART.ITEM.${collapsed ? "DETAIL_SHOW" : "DETAIL_HIDE"}`
                      )}
                      <span
                        className="icon"
                        style={{ transform: collapsed ? "rotate(180deg)" : "" }}
                      >
                        <Angle />
                      </span>
                    </span>
                    <span
                      className={"pointer"}
                      onClick={() =>
                        modifyItemInCart({
                          codeName,
                          product,
                          gift: item.gift,
                          coupon: !!item.coupon,
                          cartItem: item.id,
                          configuration: item.configuration,
                          buyFor: item.configuration.products[item.product.id]
                            .holderData
                            ? "BUY_ON_BEHALF"
                            : "BUY_CARD"
                        })
                      }
                    >
                      {Localize(`CART.ITEM.MODIFY`)}
                    </span>
                    <span className={"greyText"}> | </span>
                    <span
                      className={"greyText pointer"}
                      onClick={() => deleteItemToCart(item.id)}
                    >
                      {Localize(`CART.ITEM.DELETE`)}
                    </span>
                  </Col>
                )}
              </Row>
            </Col>
            {isOnMobile && (
              <>
                <Col
                  size={{ sm: 12, xs: 12 }}
                  className={"menuLabel pl-sm-0 pl-xs-0  mt-4"}
                >
                  <span
                    className={"pointer"}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    {Localize(`CART.ITEM.DETAIL`)}
                    <span className="icon">
                      <Angle />
                    </span>
                  </span>
                </Col>
                <Col
                  size={{ sm: 12, xs: 12 }}
                  className={"menuLabel align-items mt-4 pr-0"}
                >
                  <span
                    className={"pointer pr-3"}
                    onClick={() =>
                      modifyItemInCart({
                        codeName,
                        product,
                        gift: item.gift,
                        coupon: !!item.coupon,
                        cartItem: item.id,
                        configuration: item.configuration,
                        buyFor: item.configuration.products[item.product.id]
                          .holderData
                          ? "BUY_ON_BEHALF"
                          : "BUY_CARD"
                      })
                    }
                  >
                    {Localize(`CART.ITEM.MODIFY`)}
                  </span>
                  <span className={"greyText mr-3"}> | </span>
                  <span
                    className={"greyText pointer"}
                    onClick={() => deleteItemToCart(item.id)}
                  >
                    {Localize(`CART.ITEM.DELETE`)}
                  </span>
                </Col>
              </>
            )}
            {!isEmpty(countryAvailable) && (
              <Col size={24} className="coupon-cont">
                {Localize(
                  "CART.ALERT.RULES.COUNTRY_RESTRICTION.AVAILABLE",
                  [countryAvailable.join(", ")],
                  true
                )}
              </Col>
            )}
            {!isEmpty(countryNotAvailable) && (
              <Col size={24} className="coupon-cont">
                {Localize(
                  "CART.ALERT.RULES.COUNTRY_RESTRICTION.NOT_AVAILABLE",
                  [countryNotAvailable.join(", ")],
                  true
                )}
              </Col>
            )}
            {item.coupon && (
              <Col size={24} className="coupon-cont">
                <div className="coupon">
                  <div>
                    <Coupon />
                  </div>
                  <div>{Localize("COUPON.USED", [item.coupon], true)}</div>
                </div>
              </Col>
            )}
            <Col
              size={{ md: 20, sm: 24, xs: 24 }}
              className={"menuLabel alignBottom mt-3 px-0"}
            >
              {!collapsed && (
                <CartItemDetail
                  item={item}
                  itemDetail={item.detail}
                  user={holderData}
                />
              )}
            </Col>
          </Row>
        </div>
      </Col>
      <Col
        size={{ xl: 12, lg: 14, md: 15 }}
        offset={{ xl: 4 }}
        className={"pt-3 px-sm-0 px-xs-0"}
      >
        {(!item.product.virtual ||
          _.some(item.product.groupedProducts, gp => !gp.virtual) ||
          (product.upgradeTo &&
            (!_.get(item, "product.upgradeTo.virtual") ||
              _.some(
                _.get(item, "product.upgradeTo.groupedProducts"),
                gp => !gp.virtual
              )))) && (
          <AddressSelection
            allowTemp={false}
            required={true}
            type={"SHIPPING"}
            countries={countries}
            provinces={provinces}
            addressOptions={(addressOptions || []).map(a => ({
              ...a,
              restricted:
                a.restricted ||
                (countryNotAvailable || []).includes(a.countryCode)
            }))}
            onSelectAddress={val => onSelectAddress(val, item.id)}
            onClickAddressEnrichment={() => onClickAddressEnrichment(item.id)}
            initialData={
              item &&
              item.shippingAddress &&
              item.shippingAddress.id &&
              !item.shippingAddress.addressId &&
              item.shippingAddress
            }
            selected={
              sel || (item && item.shippingAddress)
                ? item.shippingAddress.addressId || "temp_address"
                : undefined
            }
            name={item.id}
          />
        )}
      </Col>
      {_.get(item.product, "tags[0].code", null) === "membership" &&
        !item.coupon && (
          <Col
            size={{ xl: 8, lg: 10, md: 9 }}
            className={"pt-0 px-sm-0 px-xs-0"}
          >
            <CheckMark
              id={id}
              label={Localize("CART.ITEM.WAIVER_OF_WITHDRAW", [], true)}
              tooltip={Localize("CART.ITEM.WAIVER_OF_WITHDRAW_TOOLTIP")}
              value={_.get(
                item,
                `configuration.products[${item.product.id}].waiverOfWithdraw`,
                false
              )}
              onChange={val => {
                setWaiverOfWithdraw(val, item.product.id, item.id);
              }}
            />
          </Col>
        )}
      <Col size={{ xl: 20 }} offset={{ xl: 4 }} className={"separator my-5"} />
    </Row>
  );
};

CartItem.propTypes = {
  updateItemToCart: PropTypes.func,
  deleteItemToCart: PropTypes.func
};

CartItem.defaultProps = {};

export default CartItem;
