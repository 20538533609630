import callApi from "../libs/helpers/callApi";
import { getCountriesApi, getProvincesApi } from "../api/utils.api";
import { getFromLocalStorage } from "../libs/helpers/localstorage";
import Config from "../Config";

export const LIST_LOADING = "LIST_LOADING";
export const LIST_PROVINCES_LOADED = "LIST_PROVINCES_LOADED";
export const LIST_COUNTRIES_LOADED = "LIST_COUNTRIES_LOADED";
export const LIST_ERROR = "LIST_ERROR";

const localStorageLocale = getFromLocalStorage("lang");
let locale =
  (localStorageLocale && localStorageLocale.data) || Config.forceLocale || "en";

export const getCountriesAction = callApi({
  apiCall: p => getCountriesApi(p),
  secured: true,
  startAction: LIST_LOADING,
  errorAction: LIST_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      let realResult = [];
      result.data.forEach(item => {
        realResult.push({
          idRec: item.idRec,
          value: item.iso2Code,
          label: locale === "en" ? item.nameEN : item.nameIT
        });
      });
      dispatch({ type: LIST_COUNTRIES_LOADED, data: realResult });
    }
  }
});

export const getProvincesAction = callApi({
  apiCall: p => getProvincesApi(p),
  secured: true,
  startAction: LIST_LOADING,
  errorAction: LIST_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      let realResult = [];
      result.data.forEach(item => {
        realResult.push({
          idRec: item.idRec,
          value: item.code,
          label: locale === "en" ? item.nameEN : item.nameIT
        });
      });
      dispatch({ type: LIST_PROVINCES_LOADED, data: realResult });
    }
  }
});
