import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Col, Container, Row } from "../Grid";
import Button from "../Button/Button";
import Localize, { getCurrentLocale } from "../../libs/localization";

import { ReactComponent as CircleCheck } from "../../assets/icons/circle-check.svg";
import { ReactComponent as Angle } from "../../assets/icons/angle.svg";
import { memb_ph } from "../../assets/images";
import {
  currencyMaxOneDecimal,
  curr,
  currencyMaxOneDecimalencyNoDecimal
} from "../../libs/utils";

import "./ProductCard.scss";
import Image from "../Image/Image";
import { Link } from "react-router-dom";

// const MIN_AGE = 16;

const ProductCard = ({
  id,
  itemCard = {},
  priceDiscount = null,
  soldOut = false,
  onClickBuy,
  buyLink,
  onClickGift,
  giftLink,
  couponLink,
  onClickDetail,
  detailLink,
  className
}) => {
  const isOnMobile = window.screen.width < 768;
  const classnameSoldout = soldOut ? "soldout" : "";
  const [hide, setHide] = useState(isOnMobile);

  const currLocale = getCurrentLocale();

  const defaultLocale = "en";

  const cms = itemCard && itemCard.cms;

  let benefits = null;

  if (cms && cms.json) {
    benefits =
      cms.json.benefits &&
      (cms.json.benefits[currLocale] || cms.json.benefits[defaultLocale]);
  }

  return (
    <div
      id={id}
      className={`j-productCard ${className && className} ${classnameSoldout &&
        classnameSoldout}`}
    >
      <Container className={`card-cont${hide ? " collapsed" : ""}`}>
        <Row className="align-middle">
          <Col
            size={{ xxl: 6, xl: 7, lg: 7, md: 7, sm: 24, xs: 24 }}
            className={"card-id"}
          >
            <Image
              widht="761px"
              height="762px"
              loading="lazy"
              alt={(itemCard && itemCard.name) || "logo"}
              srcList={[_.get(itemCard, "images[0].url", memb_ph)]}
              className={classnameSoldout}
            />
            {(itemCard || {}).price > 0 && (
              <div className={`priceText ${classnameSoldout}`}>
                <div className={"mt-2 mb-3"}>
                  <span className={priceDiscount ? "old-discount-price" : ""}>
                    {currencyMaxOneDecimal(itemCard.price)}
                  </span>
                  {priceDiscount && (
                    <span className={"discount-price pl-2"}>
                      {currencyMaxOneDecimal(priceDiscount)}
                    </span>
                  )}
                </div>
              </div>
            )}
          </Col>
          {hide && (
            <Col
              size={24}
              className={`more-details mt-5 mb-2 px-2 ${classnameSoldout}`}
            >
              <div className="separator" />
              <Button
                label={Localize(`PRODUCT_CARD.MORE_DETAILS`)}
                primary={false}
                transparent={true}
                noBorder={true}
                hideHover={true}
                icon={<Angle />}
                size={"L"}
                style={{ width: "100%" }}
                onClick={e => setHide(!hide)}
              />
            </Col>
          )}
          {!hide && (
            <>
              <Col
                size={{ xxl: 11, xl: 10, lg: 10, md: 17, sm: 24, xs: 24 }}
                className={"card-benefits"}
              >
                {Array.isArray(benefits) &&
                  benefits.map((el, i) => (
                    <div key={i} className={"align-middle benefits"}>
                      <span className={"CircleCheck"}>
                        <CircleCheck />
                      </span>
                      {el}
                    </div>
                  ))}
              </Col>
              {soldOut ? (
                <Col
                  size={{ xl: 7, lg: 7, md: 24, sm: 24, xs: 24 }}
                  className={"buttons"}
                >
                  <div className={"center-item px-4"} style={{ width: "100%" }}>
                    <Button
                      label={Localize(`PRODUCT_CARD.SOLD_OUT`)}
                      primary={true}
                      disabled={true}
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              ) : (itemCard || {}).price > 0 ? (
                <Col
                  size={{ xl: 7, lg: 7, md: 24, sm: 24, xs: 24 }}
                  className={"buttons mx-auto"}
                >
                  <Row>
                    <Col size={{ xl: 24, lg: 24, md: 10, sm: 24, xs: 24 }}>
                      <Link to={detailLink} onClick={onClickDetail}>
                        <Button
                          label={Localize(`PRODUCT_CARD.DETAIL`)}
                          transparent={true}
                          icon={true}
                          size={"L"}
                          style={{ width: "100%" }}
                          // disabled={userAge < MIN_AGE}
                          // hideHover={true}
                          className="mb-2 mt-2"
                        />
                      </Link>
                    </Col>
                    <Col size={{ xl: 24, lg: 24, md: 7, sm: 24, xs: 24 }}>
                      <Link to={giftLink} onClick={onClickGift}>
                        <Button
                          label={Localize(`PRODUCT_CARD.GIFT`)}
                          size={"L"}
                          style={{ width: "100%" }}
                          // disabled={userAge < MIN_AGE}
                          className="mb-2 mt-2"
                        />
                      </Link>
                    </Col>
                    <Col size={{ xl: 24, lg: 24, md: 7, sm: 24, xs: 24 }}>
                      <Link to={buyLink} onClick={onClickBuy}>
                        <Button
                          label={Localize(`PRODUCT_CARD.BUY`)}
                          primary={true}
                          size={"L"}
                          // disabled={userAge < MIN_AGE}
                          style={{ width: "100%" }}
                          onClick={e =>
                            (typeof onClickBuy === "function" &&
                              onClickBuy(e)) ||
                            null
                          }
                          className="mb-2 mt-2"
                        />
                      </Link>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col
                  size={{ xl: 7, lg: 7, md: 24, sm: 24, xs: 24 }}
                  className={"buttons mx-auto"}
                >
                  <Row>
                    <Col size={{ xl: 24, lg: 24, md: 10, sm: 24, xs: 24 }}>
                      <Link to={detailLink} onClick={onClickDetail}>
                        <Button
                          label={Localize(`PRODUCT_CARD.DETAIL`)}
                          transparent={true}
                          icon={true}
                          size={"L"}
                          style={{ width: "100%" }}
                          // disabled={userAge < MIN_AGE}
                          // hideHover={true}
                          className="mb-2 mt-2"
                        />
                      </Link>
                    </Col>
                    <Col size={{ xl: 24, lg: 24, md: 14, sm: 24, xs: 24 }}>
                      <Link to={couponLink}>
                        <Button
                          label={Localize(`COUPON.USE_COUPON`)}
                          primary={true}
                          size={"L"}
                          // disabled={userAge < MIN_AGE}
                          style={{ width: "100%" }}
                          // onClick={e =>
                          //   (typeof onClickBuy === "function" &&
                          //     onClickBuy(e)) ||
                          //   null
                          // }
                          className="mb-2 mt-2"
                        />
                      </Link>
                    </Col>
                  </Row>
                </Col>
              )}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

ProductCard.propTypes = {
  onClickBuy: PropTypes.func,
  onClickGift: PropTypes.func,
  onClickDetail: PropTypes.func.isRequired,
  itemCard: PropTypes.object // TODO: fare shape
};

export default ProductCard;
