// import { Layout, Button } from "antd";
import React, { Component } from "react";
import _ from "lodash";

import Localize from "../../libs/localization";
import { ReactComponent as JLogo } from "../../assets/icons/j_logo.svg";
import { side } from "../../assets/images";

import "./system.style.scss";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import { connect } from "react-redux";
import { goTo } from "../../actions/app.actions";
import { reuploadDocuments } from "../../api/customer.api";
import { refreshPage, getImageBackground } from "../../libs/utils";
import { Container, Row, Col } from "../../components/Grid";
import UploadDocument from "../../components/UploadDocument/UploadDocument";

import "./../buyProduct/buyProduct.styles.scss";
import { PageView } from "../../libs/helpers/gtm";

class ReloadDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploading: false,
      error: false,
      selected: "BUY_CARD",
      documents: []
    };
  }

  componentDidMount() {
    // document.body.style = "overflow: hidden";

    const { token, goTo } = this.props;
    const { search } = this.props.location;

    const URLparams = new URLSearchParams(search);
    const id = URLparams.get("id");
    const hash = URLparams.get("hash");

    if (!token || !id || !hash) {
      goTo("/");
    } else {
      PageView();
    }
  }

  _onUploadDocument = ({ item, type }) => {
    const { documents } = this.state;
    const front =
      type !== "FRONT" ? _.find(documents, { type: "FRONT" }) : { item, type };
    const back =
      type !== "BACK" ? _.find(documents, { type: "BACK" }) : { item, type };
    this.setState({
      documents: [{ ...front }, { ...back }]
    });
  };

  _onDocumentTypeChange = value => {
    this.setState({ documentType: value });
  };

  _reuploadDocsProcess = () =>
    this.setState({ uploading: true }, async () => {
      const { documents } = this.state;
      const { token } = this.props;
      const { search } = this.props.location;

      const URLparams = new URLSearchParams(search);
      const id = URLparams.get("id");
      const hash = URLparams.get("hash");

      const headers = { Authorization: `Bearer ${token}` };

      var formData = new FormData();
      _.forEach(documents, file => {
        formData.append(file.type, file.item);
      });

      reuploadDocuments({ headers, id, hash, formData })
        .then(res => {
          const { goTo } = this.props;
          if (res.ok) {
            this.setState(
              {
                loading: true,
                error: false,
                uploading: false
              },
              () => goTo("/thankyou")
            );
          } else {
            this.setState({
              loading: false,
              error: res.response,
              uploading: false
            });
          }
        })
        .catch(() =>
          this.setState({ error: { label: Localize("ERROR.UNEXPECTED") } })
        );
    });

  // _getImageBackground = (membCode, lite = false) => {
  //   return lite
  //     ? _.get(side, `${membCode}_lite`) || side.alt
  //     : _.get(side, membCode) || side.alt;
  // };

  render() {
    const {
      loading,
      error,
      documentType,
      uploading,
      product,
      documents
    } = this.state;
    const { user, token, goTo } = this.props;

    const membCode = _.get(product, "membershipCode.code", null);

    if (!user || !token) {
      return <Loading />;
    } else if (loading || uploading) {
      return <Loading />;
    } else if (error) {
      return (
        <div className="system-error animation fadein-up fast">
          <div className="system-page">
            <div className="system-box animation fadein fast">
              <div className="logo">
                <JLogo />
              </div>
              {loading ? (
                <Loading fixed={false} />
              ) : (
                <>
                  {error && (
                    <>
                      <div className="message-head">
                        {Localize("ERROR.TITLE", [error.code || error.status])}
                      </div>
                      {
                        <div className="message-error animation fadein fast">
                          {error.label || Localize("ERROR.UNEXPECTED")}
                        </div>
                      }
                      {/* {error.id && (
                      <div className="message error-box">
                        <code className="error-message">{error.id}</code>
                      </div>
                    )} */}
                      <div className="action">
                        <Button
                          primary={true}
                          onClick={() => goTo("/")}
                          size={"S"}
                          label={Localize(`ERROR.BACK_HOME`)}
                        />
                        <Button
                          onClick={() => refreshPage()}
                          style={{ marginTop: 20 }}
                          size={"S"}
                          label={Localize(`ERROR.TRY_AGAIN`)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Container className={"j-buy-product"}>
          <Row>
            <Col size={12} className={"p-0 d-sm-none image-left"}>
              <div
                className="fixed-cont"
                style={{
                  backgroundImage: `url(${getImageBackground(product)})`
                }}
                // style={{ backgroundImage: `url(${side.alt})` }}
              />
            </Col>
            <Col
              size={{ md: 24, sm: 24, lg: 12 }}
              className={"pl-md-0 p-sm-0 px-xs-0 content-right"}
              style={{ marginTop: 80 }}
            >
              <div
                className={
                  "view-container p-md-5 p-sm-3 px-sm-0 px-xs-0 pt-sm-0 pt-xs-0"
                }
              >
                {/* <VerifyData
                  codeName={"membership"}
                  token={token}
                  userInSession={user}
                  user={selected && userParam}
                  updating={false}
                  selected={selected}
                  editing={editing}
                  editable={selected === "BUY_CARD" ? false : true}
                  onClick={() => {
                    !error &&
                      this._activateMembership({
                        headers: { Authorization: `Bearer ${token}` },
                        code,
                        holderData:
                          selected === "BUY_ON_BEHALF" &&
                          this._getHolderData(userParam),
                      });
                  }}
                  onChange={this._onChangeSelect}
                  onFormChange={this._setValuesInForm}
                  product={product || {}}
                  redeemGiftProcess={true}
                  showTerms={true}
                /> */}
                <UploadDocument
                  disabled={
                    !documentType ||
                    !_.find(documents, d => d.type === "FRONT") ||
                    // !_.find(documents, d => d.type === "BACK") ||
                    loading ||
                    uploading
                  }
                  documentType={documentType}
                  cardName={"jcard"}
                  onDocumentUpload={this._onUploadDocument}
                  onClick={() => {
                    !error &&
                      // this._activateMembership({
                      //   headers: { Authorization: `Bearer ${token}` },
                      //   code,
                      //   holderData:
                      //     selected === "BUY_ON_BEHALF" &&
                      //     this._getHolderData(userParam),
                      // });
                      this._reuploadDocsProcess();
                  }}
                  onDocumentTypeChange={this._onDocumentTypeChange}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

const mapStateToProps = ({ auth, checkout }) => ({
  user: auth.user,
  token: auth.token
});

const mapDispatchToProps = { goTo };

export default connect(mapStateToProps, mapDispatchToProps)(ReloadDocuments);
