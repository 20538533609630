import callApi from "../libs/helpers/callApi";
import {
  getProductDetailApi,
  getProductsApi,
  getMembershipCodesApi,
  getProductSizesApi
} from "../api/products.api";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

export const getProductsListAction = callApi({
  apiCall: p => getProductsApi(p),
  secured: false,
  startAction: PRODUCTS_LOADING,
  errorAction: PRODUCTS_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: PRODUCTS_LOADED, data: result });
    }
  }
});

export const MEM_CODES_LOADING = "MEM_CODES_LOADING";
export const MEM_CODES_LOADED = "MEM_CODES_LOADED";
export const MEM_CODES_ERROR = "MEM_CODES_ERROR";

export const getMembershipCodesAction = callApi({
  apiCall: p => getMembershipCodesApi(p),
  secured: false,
  startAction: MEM_CODES_LOADING,
  errorAction: MEM_CODES_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: MEM_CODES_LOADED, data: result });
    }
  }
});

export const PRODUCT_DETAIL_LOADING = "PRODUCT_DETAIL_LOADING";
export const PRODUCT_DETAIL_LOADED = "PRODUCT_DETAIL_LOADED";

export const getProductDetailAction = callApi({
  apiCall: p => getProductDetailApi(p),
  secured: true,
  startAction: PRODUCT_DETAIL_LOADING,
  errorAction: PRODUCTS_ERROR,
  processResult: (result, dispatch, getState, params) => {
    if (result.error) {
      console.error(result.error);
    } else {
      dispatch({ type: PRODUCT_DETAIL_LOADED, data: result });
    }
  }
});

export const resetProductDetailAction = () => ({
  type: PRODUCT_DETAIL_LOADED,
  data: null
});

export const getProductSizesAction = () =>
  callApi({
    apiCall: p => getProductSizesApi(p),
    secured: true,
    startAction: "PRODUCTS_LOADING_SIZES",
    errorAction: "PRODUCTS_ERROR_SIZES",
    processResult: (result, dispatch, getState, params) => {
      if (result.error) {
        console.error(result.error);
      } else {
        dispatch({ type: "PRODUCTS_LOADED_SIZES", data: result });
      }
    }
  });
