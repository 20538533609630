import React from "react";
import PropTypes from "prop-types";

import "./UploadDocument.scss";
import { Col } from "../Grid";
import Localize from "../../libs/localization";
import SelectBox from "../SelectBox/SelectBox";
import LabelText from "../LabelText/LabelText";
import Button from "../Button/Button";
import FileDrop from "../FileDrop/FileDrop";

const UploadDocument = ({
  onClick,
  onDocumentTypeChange,
  onDocumentUpload,
  steps = [],
  documentType = null,
  disabled = true
}) => {
  return (
    <div className={"j-upload-document"}>
      <Col size={24} className={"title-text"}>
        {Localize("BUY_PRODUCT.UPLOAD_DOCUMENT")}
      </Col>
      <Col size={24} className={"label-text label-padding"}>
        {Localize("BUY_PRODUCT.UPLOAD_DOCUMENT_LABEL")}
      </Col>
      <Col size={24} className="mb-3">
        <SelectBox
          required={true}
          options={[
            { label: Localize("COMMON.PASSPORT"), value: "PASSPORT" },
            { label: Localize("COMMON.IDENTITY"), value: "IDENTITY" }
          ]}
          value={documentType}
          onChange={onDocumentTypeChange}
          placeholder={Localize("BUY_PRODUCT.DOCUMENT_TYPE_PLACEHOLDER")}
          label={Localize("BUY_PRODUCT.DOCUMENT_TYPE")}
        />
      </Col>
      <Col size={24}>
        <LabelText label={Localize("BUY_PRODUCT.DOCUMENT")} required={true} />
        <div>
          <div className={"label-text document-padding"}>
            {Localize("BUY_PRODUCT.FRONT")}
          </div>
          <FileDrop id="1" type={"FRONT"} handleDrop={onDocumentUpload} />
          <div className={"info-type-text"}>
            {Localize("BUY_PRODUCT.FORMAT")}
          </div>
        </div>
        <div>
          <div className={"label-text document-padding"}>
            {Localize("BUY_PRODUCT.BACK")}
          </div>
          <FileDrop id="2" type={"BACK"} handleDrop={onDocumentUpload} />
          <div className={"info-type-text"}>
            {Localize("BUY_PRODUCT.FORMAT")}
          </div>
        </div>
      </Col>
      <Col size={24} className={"email-text label-padding-email"}>
        {Localize("BUY_PRODUCT.EMAIL_CONFIRM")}
      </Col>
      <Col size={{ md: 8, xs: 24 }} className="button-bottom p-sm-0">
        <Button
          size={"XL"}
          style={{ width: "100%" }}
          label={Localize("BUY_PRODUCT.CONTINUE")}
          primary={true}
          onClick={onClick}
          disabled={disabled}
        />
      </Col>
    </div>
  );
};

UploadDocument.propTypes = {
  onClick: PropTypes.func,
  onDocumentUpload: PropTypes.func,
  steps: PropTypes.array
};

export default UploadDocument;
